import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { BookImportResult, ParsedBookBlock } from '@read4speed/models';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { BookContentBlockPreview } from './BookContentBlockPreview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 'auto',
      overflowY: 'scroll',
    },
    controls: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    pageNumber: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    button: {},
  })
);

interface BookContentPreviewProps {
  importResult?: BookImportResult;
}

const getPageBlocks = (
  data: BookImportResult,
  page: number
): ParsedBookBlock[] | null => {
  const blocks = data.parsedBook.pages[page]?.blocks;
  if (!blocks) return null;

  return blocks.map(block => block);
};

export const BookContentPreview: FC<BookContentPreviewProps> = ({
  importResult,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState<number>(1);

  const prevPage = useCallback(() => {
    if (page > 1) setPage(page - 1);
  }, [page]);

  const nextPage = useCallback(() => {
    if (page < (importResult?.parsedBook.pages.length || 0) - 1)
      setPage(page + 1);
  }, [page, importResult]);

  const pageBlocks = useMemo((): null | ParsedBookBlock[] => {
    return importResult ? getPageBlocks(importResult, page) : null;
  }, [importResult, page]);

  if (!importResult?.parsedBook) return null;

  return (
    <Box className={classes.root}>
      <Typography variant={'subtitle1'} align={'center'}>
        Content Preview
      </Typography>
      <Box className={classes.controls}>
        <IconButton
          color="primary"
          className={classes.button}
          onClick={prevPage}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant={'body2'} className={classes.pageNumber}>
          Page {page} of {importResult.parsedBook.pages.length}
        </Typography>
        <IconButton
          color="primary"
          className={classes.button}
          onClick={nextPage}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <Divider className={classes.divider} />

      {pageBlocks &&
        pageBlocks.map((b, i) => <BookContentBlockPreview key={i} block={b} />)}
    </Box>
  );
};
