import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, { textSourceURL?: string | null }>(
  theme =>
    createStyles({
      root: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px',
        maxWidth: '768px',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
          fontSize: '27px',
        },
      },
      sourceLink: {
        background: theme.palette.grey[100],
        width: '100%',
        borderRadius: '8px',
        paddingInline: '8px 16px',
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        marginTop: '40px',
        [theme.breakpoints.up('sm')]: {
          marginTop: '64px',
        },

        '&:hover': {
          background: theme.palette.grey[200],
          textDecoration: 'none',
        },

        '& > span': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },

        '& > svg': {
          marginRight: '4px',
        },
      },
      header: {
        padding: ({ textSourceURL }) =>
          `${textSourceURL ? '10px' : '40px'} 30px 32px`,
        margin: '0 -30px',
        [theme.breakpoints.up('sm')]: {
          padding: ({ textSourceURL }) =>
            `${textSourceURL ? '20px' : '64px'} 64px 32px`,
          margin: '0 -64px',
        },
        '& > div:first-of-type': {
          alignItems: 'flex-start',
        },
        '&.sticky': {
          padding: '0 30px',
          [theme.breakpoints.up('sm')]: {
            padding: '0 64px',
          },
          overflow: 'hidden',
          height: '49px',
          borderBottom: `1px solid ${theme.reader.borderColor}`,
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: theme.reader.background,
          '& > div:first-of-type': {
            height: '100%',
            alignItems: 'center',
          },
          '& h1': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            marginBottom: 0,
            fontSize: '16px',
            color: theme.reader.text,
            fontWeight: 'normal',
          },
          '& button': {
            padding: '12px',
            '& svg': {
              width: '20px',
              height: '20px',
            },
          },
        },
        '& h1': {
          width: '100%',
          margin: '0 0 16px 0',
          padding: '0 30px 0 0',
          [theme.breakpoints.up('sm')]: {
            padding: '0 64px 0 0',
          },
          fontFamily: 'Futura',
          fontSize: '28px',
          lineHeight: '38px',
          letterSpacing: '1px',
          color: theme.reader.text,
        },
        '& button': {
          paddingTop: '10px',
        },
      },
      subheader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '17px',
      },
      time: {
        fontSize: '17px',
        lineHeight: '20px',
        alignItems: 'center',
        fontFamily: 'Rubik, sans-serif',
        color: theme.reader.text,
        '& > span': {
          marginLeft: '8px',
        },
      },
      readerHolder: {
        overflowX: 'hidden',
        padding: '0 30px',
        flex: 'auto',
        overflowY: 'auto',
        backgroundColor: theme.reader.background,
        color: theme.reader.text,
        filter:
          'drop-shadow(0 2px 4px rgba(0,0,0,.06)) drop-shadow(0 4px 6px rgba(0,0,0,.1))',
        [theme.breakpoints.up('sm')]: {
          padding: '0 64px',
        },
      },
      readerControlsHolder: {
        position: 'relative',
        flexShrink: 0,
      },
      prevPages: {
        color: '#4D4D4D',
      },
      testStartButton: {
        boxShadow:
          '0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
        borderRadius: 20,
        border: `1rem solid ${theme.reader.background}`,
        background: theme.reader.background,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      readerScroll: {
        position: 'absolute',
        right: '-32px',
      },
    })
);
