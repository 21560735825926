import React, { FC, ReactElement } from 'react';

interface ReaderScrollStartProps {
  color?: string;
}

const ReaderPageScrollStart: FC<ReaderScrollStartProps> = (): ReactElement => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#EA580C" />
    <circle cx="15" cy="15" r="12" fill="white" />
    <g clipPath="url(#clip0_830_1371)">
      <path
        d={`M11.1904 9.75H14.0476C14.1739 9.75 14.295 9.80268 14.3843 9.89645C14.4736 9.99021 14.5238 10.1174 14.5238 10.25V11.25H19.2857C19.5476 11.25 19.5952 11.39 19.3904 11.56L16.1904 14.25L19.7619 17.25H12.9047C12.8416 17.25 12.781 17.2237 12.7364 17.1768C12.6917 17.1299 12.6666 17.0663 12.6666 17C12.6599 16.9687 12.6599 16.9363 12.6666 16.905C13.0142 16.045 14.5238 15.75 14.5238 15.75H11.1904`}
        fill="#EA580C"
        fillOpacity="0.2"
      />
      <path
        d={`M11.1904 9.75H14.0476C14.1739 9.75 14.295 9.80268 14.3843 9.89645C14.4736 9.99021 14.5238 10.1174 14.5238 10.25V11.25H19.2857C19.5476 11.25 19.5952 11.39 19.3904 11.56L16.1904 14.25L19.7619 17.25H12.9047C12.8416 17.25 12.781 17.2237 12.7364 17.1768C12.6917 17.1299 12.6666 17.0663 12.6666 17C12.6599 16.9687 12.6599 16.9363 12.6666 16.905C13.0142 16.045 14.5238 15.75 14.5238 15.75H11.1904`}
        stroke="#EA580C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5239 11.25V15.75"
        stroke="#EA580C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.238 9.25V20.75"
        stroke="#EA580C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_830_1371">
        <rect width="10" height="12" fill="white" transform="translate(10 9)" />
      </clipPath>
    </defs>
  </svg>
);

export default ReaderPageScrollStart;
