import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const CustomButton = withStyles({
  contained: {
    height: '48px',
    backgroundColor: colors.orange50,
    border: `1px solid ${colors.orange500}`,
    color: colors.orange700,
    borderRadius: '12px',
    textTransform: 'none',
    flexShrink: 0,
    boxShadow: 'none',
    '&:hover, &:focus': {
      color: colors.orange800,
      boxShadow: 'none',
      backgroundColor: colors.orange100,
      border: `1px solid ${colors.orange800}`,
    },
    '&:active': {
      backgroundColor: colors.orange100,
      border: `1px solid ${colors.orang900}`,
    },
    '&:disabled': {
      backgroundColor: colors.greyLight,
      borderRadius: '12px',
    },
    '& span': {
      fontWeight: 500,
      fontSize: '17px',
      lineHeight: '24px',
      color: 'inherit',
    },
    '& svg': {
      marginRight: '8px',
    },
  },
})(Button);
