import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { readerPauseAction, readerPlayAction } from '../../redux/commonActions';
import { readerActions } from '../../redux/reader';
import {
  useReaderPlaybackState,
  useReaderPosition,
  useReaderSpeechState,
} from '../../redux/reader/selectors';
import { useWords } from '../../redux/text/selectors';
import { useEnabledKeyboardKeys } from '../../redux/ui/selectors';

export const usePlaybackKeyboardControl = (): void => {
  const dispatch = useDispatch();
  const position = useReaderPosition();
  const words = useWords();
  const enabledKeys = useEnabledKeyboardKeys();
  const { playing } = useReaderPlaybackState();
  const { speechActive, speechPlaying } = useReaderSpeechState();
  const isPlaying = speechActive ? speechPlaying : playing;

  const onKeyPress = useCallback(
    event => {
      if (event.target.nodeName !== 'BODY') return;
      if (event.key === 'ArrowLeft' && enabledKeys.arrows && position !== 0) {
        event.preventDefault();
        dispatch(readerActions.setPosition(position - 1));
      }

      if (
        event.key === 'ArrowRight' &&
        enabledKeys.arrows &&
        position !== words.length - 1
      ) {
        event.preventDefault();
        dispatch(readerActions.setPosition(position + 1));
      }

      if (event.key === ' ' || event.key === 'p') {
        event.preventDefault();

        if (isPlaying) {
          if (speechActive) dispatch(readerActions.pauseSpeech());
          else dispatch(readerPauseAction());
        } else {
          if (speechActive) dispatch(readerActions.playSpeech());
          else dispatch(readerPlayAction());
        }
      }

      if (event.key === 'm') {
        event.preventDefault();

        if (isPlaying) {
          if (speechActive) {
            dispatch(readerActions.pauseSpeech());
            dispatch(readerActions.play());
          } else {
            dispatch(readerActions.pause());
            dispatch(readerActions.playSpeech());
          }
        }

        dispatch(readerActions.setSpeechActive(!speechActive));
      }
    },
    [position, isPlaying, speechActive, words.length]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => document.removeEventListener('keydown', onKeyPress);
  }, [onKeyPress]);
};
