import { ReadingHistoryItemModel } from '@read4speed/models';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';
import {
  acceptCollectionStateData,
  createCollectionState,
  getCollectionItems,
  ICollectionState,
} from '../utils';

export interface ReadingHistoryState {
  collection: ICollectionState<ReadingHistoryItemModel>;
}

const initialState: ReadingHistoryState = {
  collection: createCollectionState<ReadingHistoryItemModel>(),
};

const slice = createSlice({
  initialState,
  name: 'readingHistory',
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },
    acceptHistoryItems(
      state,
      action: PayloadAction<ReadingHistoryItemModel[]>
    ) {
      acceptCollectionStateData(state.collection, action.payload, {
        key: 'lastReadDate',
        order: 'desc',
      });
    },
  },
});

export const selectAllTextIds = createSelector<
  RootState,
  ReadingHistoryState,
  string[]
>(
  ({ readingHistory }) => readingHistory,
  ({ collection }) => {
    const readingHistoryItems = getCollectionItems(collection);
    return readingHistoryItems.map(
      ({ targetId }: { targetId: string }) => targetId
    );
  }
);

export const {
  actions: readingHistoryActions,
  reducer: readingHistoryReducer,
} = slice;
