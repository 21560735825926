import Box from '@material-ui/core/Box';
import { ReadingHistoryItemModel } from '@read4speed/models';
import {
  ParagraphCommentsModal,
  TextQuotesModal,
} from '@read4speed/models/src';
import clsx from 'clsx';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { continueReadingAction } from '../../redux/commonActions/continueReadingAction';
import { uiActions } from '../../redux/ui';
import { ReadingHistoryItemIcon } from './ReadingHistoryItemIcon';
import { useStyles } from './styles/readingHistoryItem';

interface ReadingHistoryItemProps {
  item: ReadingHistoryItemModel;
  quotes?: TextQuotesModal[];
  paragraphComments?: ParagraphCommentsModal;
}

export const ReadingHistoryItem: FC<ReadingHistoryItemProps> = ({
  item,
  quotes,
  paragraphComments,
}) => {
  const {
    previewText,
    targetId,
    wordIndex,
    lastSpeed,
    lastSpeechSpeed,
    type,
    isFinished,
    remainingReadingTime,
  } = item;
  const readerRouteMatch = useRouteMatch(APP_ROUTES_CONFIG.READER);
  const history = useHistory();

  const classes = useStyles();
  const dispatch = useDispatch();

  const onContinueClick = useCallback(() => {
    dispatch(uiActions.closeAppMenu());
    dispatch(continueReadingAction(item));
    if (!readerRouteMatch) history.push(APP_ROUTES_CONFIG.READER);
  }, [targetId, wordIndex, lastSpeed, lastSpeechSpeed, type, readerRouteMatch]);
  const title = item.type === 'BOOK' ? item.title : previewText;
  const isStarted = wordIndex > 50;
  const actionStr = isFinished ? (
    'Completed ✅'
  ) : isStarted ? (
    <Box display="flex" flexDirection="column" gridRowGap={3}>
      <Box display="flex" alignItems="center">
        Continue Reading{' '}
        <Box display="inline-block" mt={0.5} ml={0.5}>
          📖
        </Box>
      </Box>
    </Box>
  ) : (
    'Start Reading ⚡️'
  );

  return (
    <button
      className={clsx(classes.root, isStarted && 'started')}
      title="Read"
      onClick={onContinueClick}
    >
      <ReadingHistoryItemIcon item={item} />

      <Box className={classes.info}>
        <span>{title}</span>
        <span>
          {isFinished ? (
            <>
              Saved quotes: {quotes?.length || 0}, Saved notes:{' '}
              {paragraphComments?.comments?.length || 0}
            </>
          ) : (
            remainingReadingTime && <>Time to finish: {remainingReadingTime}</>
          )}
        </span>
      </Box>
      <span className={classes.action} onClick={onContinueClick}>
        {actionStr}
      </span>
    </button>
  );
};
