import { HighlightNameEnum } from '@read4speed/models';

import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateHighlightAction = (
  id: string,
  highlight: HighlightNameEnum[]
): AppThunk => async dispatch => {
  dispatch(
    userProfilesActions.update({
      id,
      data: { highlight },
    })
  );
  try {
    await UserProfilesService.updateHighlight(id, highlight);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`can't save the highlight`, error);
  }
};
