import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { sum } from 'lodash';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import HeadphonesIcon from 'src/components/Icons/headphones';
import MinusIcon from 'src/components/Icons/minus';
import PauseIcon from 'src/components/Icons/pause';
import PlayIcon from 'src/components/Icons/play';
import PlusIcon from 'src/components/Icons/plus';
import SettingsIcon from 'src/components/Icons/settings';
import { readerActions } from 'src/redux/reader';
import { uiActions } from 'src/redux/ui';
import { colors } from 'src/styles/colors';

import QuotesIcon from '../../components/Icons/quotesIcon';
import { ReaderSettings } from '../../components/ReaderSettings';
import { useSpeechSupported } from '../../components/SpeechEnabler';
import useAnalyticsEvents from '../../hooks/useAnalyticsEvents';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useAuthenticated } from '../../redux/auth/selectors';
import { readerPauseAction, readerPlayAction } from '../../redux/commonActions';
import {
  useReaderPlaybackState,
  useReaderPosition,
  useReaderSpeechState,
} from '../../redux/reader/selectors';
import { updateReadingHistoryItem } from '../../redux/readingHistory';
import { useTotalWeight, useWords } from '../../redux/text/selectors';
import { useQuotesData } from '../../redux/textQuotes/selectors';
import { quotesActions } from '../../redux/textQuotes/textQuotesSlice';
import { TextParserService } from '../../services/TextParserService';
import { QuotesConfirmation } from './QuotesConfirmationModal';
import { useStyles } from './styles/textReaderControls';

const MIN_SPEED = 0;
const MAX_SPEED = 2000;
const MIN_SPEECH_SPEED = 0.1;
const MAX_SPEECH_SPEED = 2;
const SPEED_STEP = 10;
const SPEED_SPEECH_STEP = 0.1;
const DEFAULT_CURSOR_COLOR = colors.cursorColor;

interface TextReaderControlsProps {
  totalTimeToRead: number;
}

export const TextReaderControls: FC<TextReaderControlsProps> = props => {
  const { totalTimeToRead } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const words = useWords();
  const [pushEvent] = useAnalyticsEvents();
  const totalWeight = useTotalWeight();
  const position = useReaderPosition();
  const speechSupported = useSpeechSupported();
  const { speed, playing } = useReaderPlaybackState();
  const isAuthenticated = useAuthenticated();
  const [isSpeedBtnActive, setSpeedBtnActive] = useState(false);
  const [newSpeed, setNewSpeed] = useState(speed);
  const { speechPlaying, speechSpeed, speechActive } = useReaderSpeechState();
  const isPlaying = speechActive ? speechPlaying : playing;
  const speedInput = useRef(null);
  const speedBtn = useRef(null);
  const { isQuotesActive } = useQuotesData();

  const closeSpeedInput = useCallback(
    (e): void => {
      const inputSpeedValue = parseInt(e.target.value) || newSpeed;
      if (isSpeedValid(inputSpeedValue)) {
        dispatch(readerActions.setSpeed(inputSpeedValue));
      } else {
        dispatch(readerActions.setSpeed(speed));
      }
      setSpeedBtnActive(false);
    },
    [newSpeed]
  );

  useOutsideClick(speedInput, speedBtn, closeSpeedInput, isSpeedBtnActive);

  const delayFactor = useMemo(
    () => TextParserService.speedToDelayFactor(words, totalWeight, speed),
    [words.length, totalWeight, speed]
  );

  const elapsedTime = useMemo(() => {
    const weight = sum(
      words.slice(0, position).map(TextParserService.getWordWeight)
    );
    const elapsedMs = TextParserService.calcDelayMS(weight, delayFactor);

    return {
      ms: elapsedMs,
      text: TextParserService.getTimeText(elapsedMs),
    };
  }, [position, delayFactor]);

  const remainingTime = useMemo(() => {
    const remainingMs = totalTimeToRead - elapsedTime.ms;

    return {
      ms: remainingMs,
      text: TextParserService.getTimeText(remainingMs),
    };
  }, [totalTimeToRead, position, elapsedTime]);

  const playClickHandler = useCallback(() => {
    pushEvent('player-play', {
      type: 'button-click',
    });
    dispatch(readerPlayAction(speechActive));
  }, [speechActive, pushEvent]);

  const pauseClickHandler = useCallback(() => {
    dispatch(
      updateReadingHistoryItem({ remainingReadingTime: remainingTime.text })
    );
    dispatch(readerPauseAction(speechActive));
  }, [speechActive, remainingTime]);

  const toggleSpeed = useCallback(() => {
    setNewSpeed(speed);
    setSpeedBtnActive(!isSpeedBtnActive);
    if (isPlaying) {
      if (speechActive) {
        dispatch(readerActions.pauseSpeech());
      } else {
        dispatch(readerActions.pause());
      }
    }
  }, [isSpeedBtnActive, isPlaying, speechActive, newSpeed]);

  const toggleSpeech = useCallback(() => {
    if (isPlaying) {
      if (speechActive) {
        dispatch(readerActions.pauseSpeech());
        dispatch(readerActions.play());
      } else {
        dispatch(readerActions.pause());
        dispatch(readerActions.playSpeech());
      }
    }

    dispatch(readerActions.setSpeechActive(!speechActive));
  }, [isPlaying, speechActive]);

  function getMinSpeed(): number {
    return speechActive ? MIN_SPEECH_SPEED : MIN_SPEED;
  }

  function getMaxSpeed(): number {
    return speechActive ? MAX_SPEECH_SPEED : MAX_SPEED;
  }

  function isSpeedValid(value: number): boolean {
    return value >= getMinSpeed() && value <= getMaxSpeed();
  }

  const decreaseSpeed = useCallback(() => {
    const speed = speechActive
      ? speechSpeed - SPEED_SPEECH_STEP
      : newSpeed - SPEED_STEP;

    if (isSpeedValid(speed)) {
      if (speechActive) {
        dispatch(readerActions.setSpeechSpeed(speed));
      } else {
        setNewSpeed(speed);
      }
    }
  }, [newSpeed, speechSpeed, speechActive]);

  const increaseSpeed = useCallback(() => {
    const speed = speechActive
      ? speechSpeed + SPEED_SPEECH_STEP
      : newSpeed + SPEED_STEP;

    if (isSpeedValid(speed)) {
      if (speechActive) {
        dispatch(readerActions.setSpeechSpeed(speed));
      } else {
        setNewSpeed(speed);
      }
    }
  }, [newSpeed, speechSpeed, speechActive]);

  const openReaderSettings = useCallback(() => {
    dispatch(uiActions.openReaderSettings());
    if (isPlaying) dispatch(readerPauseAction(speechActive));
  }, [isPlaying]);

  const handleSpeedChange = useCallback(
    e => {
      const speedValue = parseInt(e.target.value);
      setNewSpeed(speedValue > MAX_SPEED ? MAX_SPEED : speedValue);
    },
    [speechActive]
  );

  const handleSpeedKeyPress = useCallback(e => {
    // TODO handle arrow key press
    if (e.key === 'Enter') {
      handleSpeedChange(e);
      closeSpeedInput(e);
    } else if (!/\d/.test(e.key)) e.preventDefault();
  }, []);

  if (!totalTimeToRead) return null;

  return (
    <Box className={classes.root}>
      {!isPlaying && (
        <IconButton
          className={classes.playIcon}
          aria-label="play"
          onClick={playClickHandler}
        >
          <PlayIcon color={colors.orange600} />
        </IconButton>
      )}

      {isPlaying && (
        <IconButton
          className={classes.playIcon}
          aria-label="pause"
          onClick={pauseClickHandler}
        >
          <PauseIcon color={colors.grey300} />
        </IconButton>
      )}
      <span className={classes.time}>{remainingTime.text} left</span>

      <Box position="relative" flexShrink="0">
        <IconButton
          ref={speedBtn}
          aria-label="change speed"
          onClick={toggleSpeed}
        >
          <span className={classes.speed}>
            {speechActive ? `${speechSpeed}x` : `${speed} wpm`}
          </span>
        </IconButton>
        <div
          ref={speedInput}
          className={clsx(classes.speedBtns, isSpeedBtnActive && 'active')}
          aria-label="change speed"
        >
          <Button onClick={decreaseSpeed} aria-label="decrease speed">
            <MinusIcon color={colors.greyBase} />
          </Button>
          <div className={classes.speedText}>
            {speechActive ? (
              <span>{`${speechSpeed}x`}</span>
            ) : (
              <input
                className={classes.speedInput}
                type="text"
                value={speechActive ? speechSpeed : newSpeed || ''}
                onChange={handleSpeedChange}
                onKeyPress={handleSpeedKeyPress}
              />
            )}
            {!speechActive && 'wpm'}
          </div>
          <Button onClick={increaseSpeed} aria-label="increase speed">
            <PlusIcon color={colors.greyBase} />
          </Button>
        </div>
      </Box>

      {speechSupported && (
        <IconButton
          className={classes.speechIcon}
          aria-label={`turn ${speechActive ? 'off' : 'on'} speech`}
          onClick={toggleSpeech}
        >
          <HeadphonesIcon
            color={speechActive ? colors.orange600 : colors.greyBase}
          />
          <span className={speechActive ? 'active' : ''}>Listen</span>
        </IconButton>
      )}

      {isAuthenticated && (
        <>
          <IconButton
            className={classes.settingsBtn}
            aria-label="open settings"
            onClick={openReaderSettings}
          >
            <SettingsIcon color={colors.greyBase} />
          </IconButton>
          <IconButton
            onClick={() =>
              dispatch(quotesActions.setIsQuotesActive(!isQuotesActive))
            }
          >
            <QuotesIcon color={isQuotesActive ? DEFAULT_CURSOR_COLOR : ''} />
          </IconButton>

          <QuotesConfirmation />
          <ReaderSettings />
        </>
      )}
    </Box>
  );
};
