import {
  firestoreToPlainObject,
  firestoreToPlainObjectMap,
} from '@read4speed/firebase-utils';
import { BookImportResult, BookModel, BookPageModel } from '@read4speed/models';
import qs from 'querystring';
import urlJoin from 'url-join';

import { collections } from '../firebase';

export class BookService {
  private static API_URL = 'https://api.read4speed.com/api/v1';

  static async getBookPageByNumber(
    bookId: string,
    page: number
  ): Promise<BookPageModel | null> {
    const snapshot = await collections
      .bookPagesRef()
      .where('book', '==', bookId)
      .where('number', '==', page)
      .get();

    if (snapshot.empty) return null;

    return firestoreToPlainObjectMap(snapshot.docs)[0];
  }

  static async getPagesAfter(
    bookId: string,
    startNumber: number,
    count: number = 10
  ): Promise<BookPageModel[]> {
    const snapshot = await collections
      .bookPagesRef()
      .where('book', '==', bookId)
      .orderBy('number', 'asc')
      .where('number', '>', startNumber)
      .limit(count)
      .get();

    return firestoreToPlainObjectMap(snapshot.docs);
  }

  static async getPagesBefore(
    bookId: string,
    startNumber: number,
    count: number = 10
  ): Promise<BookPageModel[]> {
    const snapshot = await collections
      .bookPagesRef()
      .where('book', '==', bookId)
      .orderBy('number', 'desc')
      .where('number', '<', startNumber)
      .limit(count)
      .get();

    return firestoreToPlainObjectMap(snapshot.docs).reverse();
  }

  static async getBooks(): Promise<BookModel[]> {
    const snapshot = await collections.booksRef().get();
    return firestoreToPlainObjectMap(snapshot.docs);
  }

  static async uploadBook(file: File, type: 'pdf'): Promise<BookImportResult> {
    const formData = new FormData();

    formData.append('file', file);

    const response = await fetch(
      urlJoin(this.API_URL, 'book-uploads', `?${qs.stringify({ type })}`),
      {
        method: 'POST',
        body: formData,
      }
    );

    return await response.json();
  }

  static async updateBook(
    id: string,
    data: Partial<BookModel>
  ): Promise<BookModel> {
    await collections.booksRef().doc(id).update(data);
    return this.getBook(id);
  }

  static async removeBook(id: string): Promise<void> {
    await fetch(urlJoin(this.API_URL, 'books', id), { method: 'DELETE' });
  }

  static async getBook(id: string): Promise<BookModel> {
    const snapshot = await collections.booksRef().doc(id).get();
    return firestoreToPlainObject(snapshot);
  }
}
