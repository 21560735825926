import { ReadingHistoryItemType } from '@read4speed/models/src';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { readerActions } from '../reader';
import { textActions } from '../text';

interface Options {
  text: string;
  thumbSrc?: string;
  type?: ReadingHistoryItemType;
}

export const saveDiagnosticTextAction = createAsyncThunk<void, Options>(
  'common/saveText',
  async ({ text, thumbSrc = '', type = 'TEXT' }, { dispatch }) => {
    dispatch(textActions.acceptText(text));
    dispatch(textActions.setTextType(type));
    dispatch(textActions.setTextThumbSrc(thumbSrc));
    dispatch(readerActions.setPosition(-1));
  }
);
