import { getFirebase } from '../../firebase';
import { AppThunk } from '../store';
import { authActions } from './authSlice';

export const resetPassword = (email: string): AppThunk => async dispatch => {
  try {
    const actionCodeSettings = {
      url: `${window.location.origin}/?email=${email}`,
    };
    await getFirebase()
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(() => dispatch(authActions.setEmail(email)));
  } catch ({ code, message = '' }) {
    const error = {
      type: 'all',
      message: 'Something went wrong. Please try again.',
    };

    if (code === 'auth/user-not-found') {
      error.message = 'User with this email is not found.';
      error.type = 'email';
    }

    if (code === 'auth/invalid-email') {
      error.message = 'The email address is not valid.';
      error.type = 'email';
    }

    dispatch(authActions.setError(error));
  }
};
