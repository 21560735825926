import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { colors } from 'src/styles/colors';

import EnvelopeIcon from '../../components/Icons/envelope';
import { authActions, resetPassword } from '../../redux/auth';
import { useAuthError } from '../../redux/auth/selectors';
import { CustomInput } from '../../styles/customInput';
import { SubmitButton } from '../../styles/submitButton';
import { useStyles } from '../Signin/styles/signinForm';

interface ResetPasswordFormFields {
  email: string;
}

interface ResetPasswordFormProps {}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = () => {
  const dispatch = useDispatch();
  const authError = useAuthError();
  const classes = useStyles();
  const [emailError, setEmailError] = useState('');

  const {
    register,
    handleSubmit,
    formState,
  } = useForm<ResetPasswordFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { red1, red2 } = colors;
  const isEmailInvalid = !!(emailError || authError);

  useEffect(() => {
    return () => {
      dispatch(authActions.setError(null));
    };
  }, []);

  const submitHandler = handleSubmit(({ email }) => {
    if (!email) return;
    dispatch(resetPassword(email));
  });

  const onEmailChange = useCallback((): void => {
    if (emailError) setEmailError('');
    if (authError) dispatch(authActions.setError(null));
  }, [isEmailInvalid]);

  return (
    <>
      <form className={classes.root} onSubmit={submitHandler}>
        <CustomInput
          onChange={onEmailChange}
          className={isEmailInvalid ? 'invalid' : ''}
          inputRef={register({
            required: true,
            validate: value => {
              if (!/^.+\@.+\..+$/.test(value)) {
                setEmailError('The email address is invalid.');
              } else {
                setEmailError('');
                return true;
              }
            },
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EnvelopeIcon color={isEmailInvalid ? red2 : red1} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          required
          fullWidth
          id="email"
          placeholder="Email Address *"
          name="email"
          autoComplete="email"
          autoFocus
        />
        {isEmailInvalid ? (
          <Alert className={classes.alert} severity="error">
            {emailError || (authError && authError.message)}
          </Alert>
        ) : null}

        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={!formState.isValid || isEmailInvalid}
          className={clsx(classes.submitBtn, 'reset-password')}
        >
          Send
        </SubmitButton>
      </form>
    </>
  );
};
