import { UserProfileModel } from '@read4speed/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  acceptCollectionStateData,
  createCollectionState,
  ICollectionState,
  updateCollectionStateItem,
} from '../utils';

export interface UserProfilesState {
  loggedInProfileId?: string;
  collection: ICollectionState<UserProfileModel>;
}

const initialState: UserProfilesState = {
  loggedInProfileId: undefined,
  collection: createCollectionState<UserProfileModel>(),
};

const slice = createSlice({
  initialState,
  name: 'userProfiles',
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },
    setLoggedInProfile(state, action: PayloadAction<string>) {
      state.loggedInProfileId = action.payload;
    },
    acceptData(state, action: PayloadAction<UserProfileModel[]>) {
      acceptCollectionStateData(state.collection, action.payload);
    },
    update(
      state,
      action: PayloadAction<{ id: string; data: Partial<UserProfileModel> }>
    ) {
      const { id, data } = action.payload;
      updateCollectionStateItem(state.collection, id, data);
    },
  },
});

export const {
  reducer: userProfilesReducer,
  actions: userProfilesActions,
} = slice;
