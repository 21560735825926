import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { PrivacyPolicy } from '../../components/Legal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(8, '10%', 18),
      height: '100vh',
      width: '100vw',
      overflowY: 'scroll',
    },
  })
);

interface PrivacyPolicyPageProps {}

export const PrivacyPolicyPage: FC<PrivacyPolicyPageProps> = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <PrivacyPolicy />
    </Box>
  );
};
