import { useEffect, useRef } from 'react';
import { colors } from 'src/styles/colors';

export const useBackgroundWithRef = (): React.RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let parentNode: HTMLElement | null, container;

    if (null !== ref) {
      container = ref.current as HTMLDivElement;
      parentNode = container.parentNode as HTMLElement;
      if (parentNode) parentNode.style.backgroundColor = colors.grey1;
    }
    return () => {
      if (parentNode) parentNode.style.backgroundColor = '';
    };
  }, []);

  return ref;
};
