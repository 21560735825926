import { WordFormatEnum } from '@read4speed/models';

import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateWordFormatAction = (
  id: string | undefined,
  wordFormat: WordFormatEnum
): AppThunk => async dispatch => {
  if (id) {
    dispatch(
      userProfilesActions.update({
        id,
        data: { wordFormat },
      })
    );

    await UserProfilesService.updateWordFormat(id, wordFormat);
  }
};
