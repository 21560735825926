/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

interface QuotesIconProps {
  color?: string;
}

const QuotesIcon: FC<QuotesIconProps> = ({ color }): ReactElement => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2755 0.670952C19.0709 0.466403 18.7612 0.314453 18.4573 0.314453C18.1534 0.314453 17.8962 0.41965 17.6917 0.618354L5.41876 12.377C5.21421 12.5815 5.06226 12.8913 5.06226 13.1952V13.3997L11.9117 20.2492C12.2682 20.3018 12.6773 20.1966 12.9345 19.9453L24.6931 7.67236C25.1022 7.21066 25.1022 6.49766 24.6931 6.08857L19.2755 0.670952ZM20.6079 8.24509L13.8578 15.3984C13.5013 15.7549 12.9871 16.0121 12.4786 16.0121C11.9702 16.0121 11.5085 15.8075 11.0994 15.451L9.97142 14.2764C9.61492 13.9199 9.41037 13.4056 9.41037 12.8971C9.41037 12.3887 9.61492 11.927 10.024 11.5646L17.1832 4.81452C17.7969 4.25347 18.8722 4.30023 19.5326 4.96647L20.5028 5.93662C21.1164 6.6087 21.169 7.6256 20.6079 8.24509Z"
      fill={color || '#71717A'}
    />
    <path
      d="M0.199951 23.6855H3.21558L3.88183 23.0192L2.39739 21.488L0.199951 23.6855Z"
      fill={color || '#71717A'}
    />
    <path
      d="M4.60069 14.89C4.49549 14.7848 4.44874 14.738 4.39614 14.6328L3.06365 15.9653C2.95846 16.0705 2.9117 16.1698 2.9117 16.2692C2.9117 16.3686 2.9643 16.5263 3.06365 16.5731L3.77665 17.2861C3.9812 17.4906 3.9812 17.8471 3.77665 18.1043L2.39741 19.4835C2.24546 19.6355 2.24546 19.84 2.39741 19.9978L5.46564 23.0661C5.61759 23.1713 5.82214 23.1713 5.97993 23.0661L7.35917 21.6868C7.56372 21.4823 7.97282 21.4823 8.17737 21.6868L8.89037 22.3998C9.04232 22.5518 9.35206 22.5518 9.50401 22.3998L10.8365 21.0673C10.7313 21.0147 10.6845 20.9154 10.5794 20.8628L4.60069 14.89Z"
      fill={color || '#71717A'}
    />
  </svg>
);

export default QuotesIcon;
