import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateFontSizeAction = (
  id: string,
  fontSize: number
): AppThunk => async dispatch => {
  await UserProfilesService.updateFontSize(id, fontSize);
  dispatch(
    userProfilesActions.update({
      id,
      data: { fontSize },
    })
  );
};
