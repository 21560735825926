import { BookModel } from '@read4speed/models';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { getCollectionItems } from '../../utils';

export const useBooks = (): BookModel[] => {
  return useSelector<RootState, BookModel[]>(state =>
    getCollectionItems(state.library.books)
  );
};
