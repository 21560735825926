import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles(theme => {
  return createStyles({
    root: {
      width: '100%',
      minHeight: '100%',
      height: '100%',
      backgroundColor: 'white',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: '#D1F3FB',
      },
    },
    side: {
      width: '100%',
      padding: '80px 0 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& .desktop': {
        display: 'none',
      },
      '& h2': {
        margin: '64px 0 16px',
        fontWeight: '500',
        fontSize: '32px',
        lineHeight: '38px',
        color: colors.grey800,
        fontFamily: 'DM Sans',
        textAlign: 'center',
      },
      '& > p': {
        color: colors.greyBase,
        fontSize: '20px',
        lineHeight: '28px',
        textAlign: 'center',
        maxWidth: '400px',
        marginBottom: '33px',
        fontFamily: 'Syne',
      },
      [theme.breakpoints.up('md')]: {
        height: '100%',
        paddingTop: 0,
        '& .desktop': {
          display: 'unset',
        },
      },
    },
    illustration: {
      maxWidth: '405px',
    },
    main: {
      width: '100%',
      padding: '0 30px 80px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white',
      justifyContent: 'center',
      borderRadius: '20px 0 0 20px',
      '& > h1': {
        fontWeight: '500',
        fontSize: '38px',
        lineHeight: '46px',
        color: colors.grey800,
        fontFamily: 'DM Sans',
        textAlign: 'center',
        margin: '30px 0 0',
      },
      '& > p': {
        fontSize: '18px',
        lineHeight: '27px',
        color: colors.greyBase,
        margin: '16px 0 23px',
        maxWidth: '300px',
        fontFamily: 'Syne',
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        padding: '0 30px',
        height: '100%',
      },
    },
    backBtn: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Syne',
      fontSize: '18px',
      textDecoration: 'none',
      lineHeight: '27px',
      color: colors.greyBase,
      alignSelf: 'flex-start',
      position: 'absolute',
      left: '30px',
      top: '34px',
      width: '100%',
      cursor: 'pointer',
      '& span': {
        marginLeft: '17px',
      },
      [theme.breakpoints.up('md')]: {
        top: '60px',
        left: '54%',
      },
    },
    logo: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  });
});
