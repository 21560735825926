/* eslint-disable max-len */
import { getFirebase } from '../../firebase';
import { AppThunk } from '../store';
import { authActions } from './authSlice';

export const checkAuthCode = (code: string): AppThunk => async dispatch => {
  try {
    await getFirebase()
      .auth()
      .checkActionCode(code)
      .then(() => dispatch(authActions.setAuthCodeValid(true)));
  } catch ({ code, message = '' }) {
    const error = {
      type: 'all',
      message: 'Something went wrong. Please try again.',
    };

    if (code === 'auth/expired-action-code') {
      error.message = 'The password reset link has expired.';
    }

    if (code === 'auth/invalid-action-code') {
      error.message =
        'The password reset link is invalid. This can happen if the code is malformed, expired, or has already been used.';
    }

    if (code === 'auth/user-disabled') {
      error.message =
        'The user corresponding to the given password reset link has been disabled.';
    }

    if (code === 'auth/user-not-found') {
      error.message =
        'There is no user corresponding to the password reset link.';
    }

    dispatch(authActions.setError(error));
  }
};
