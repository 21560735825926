import { ReadingHistoryItemModel } from '@read4speed/models';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';

export const useLatestReadingHistoryItem = (): ReadingHistoryItemModel | null => {
  return useSelector<RootState, ReadingHistoryItemModel | null>(state => {
    const { collection } = state.readingHistory;
    return collection.byId[collection.ids[0]] || null;
  });
};
