import { History, LocationState } from 'history';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { getFirebase } from '../../firebase';
import { resetStoreAction } from '../../redux/commonActions';
import { AppThunk } from '../store';

export const signOutAction = (
  history: History<LocationState>
): AppThunk => async dispatch => {
  await getFirebase().auth().signOut();
  dispatch(resetStoreAction());
  history.push(APP_ROUTES_CONFIG.HOME);
};
