import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import MinusIcon from 'src/components/Icons/minus';
import PlusIcon from 'src/components/Icons/plus';
import { colors } from 'src/styles/colors';

import { ReaderFontService } from '../../services/ReaderFontService';
import { useStyles } from './styles/readerSettings';

interface ReaderSettingsFontSizeSelectorProps {
  size?: number;
  onChange: (value: number) => void;
}

const DEBOUNCE = 300;
export const ReaderSettingsFontSizeSelector: FC<ReaderSettingsFontSizeSelectorProps> = ({
  size = ReaderFontService.DEFAULT_SIZE,
  onChange,
}) => {
  const classes = useStyles();
  const [localSize, setLocalSize] = useState(size);
  const timeout = useRef<NodeJS.Timeout | undefined>();

  const min = ReaderFontService.MIN_SIZE;
  const max = ReaderFontService.MAX_SIZE;

  const incHandler = useCallback(() => {
    const newSize = localSize + 1;
    if (newSize > max) return;
    if (timeout.current) clearTimeout(timeout.current);
    setLocalSize(newSize);
    timeout.current = setTimeout(() => onChange(newSize), DEBOUNCE);
  }, [localSize, onChange]);

  const decHandler = useCallback(() => {
    const newSize = localSize - 1;
    if (newSize < min) return;
    if (timeout.current) clearTimeout(timeout.current);
    setLocalSize(newSize);
    timeout.current = setTimeout(() => onChange(newSize), DEBOUNCE);
  }, [localSize, onChange]);

  useEffect(() => setLocalSize(size), [size]);

  return (
    <label className={classes.row}>
      <span>Font Size</span>

      <Box display="flex" alignItems="center">
        <IconButton className={classes.fontSizeBtn} onClick={decHandler}>
          <MinusIcon color={colors.greyBase} />
        </IconButton>

        <span className={classes.fontSizeText}>{localSize}</span>

        <IconButton className={classes.fontSizeBtn} onClick={incHandler}>
          <PlusIcon color={colors.greyBase} />
        </IconButton>
      </Box>
    </label>
  );
};
