import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useIsAdmin } from '../../redux/auth/selectors';
import { uiActions } from '../../redux/ui';
import { useBookshelfState } from '../../redux/ui/selectors';
import { BookshelfBookWizard } from './BookshelfBookWizard';
import { BookshelfLibrary } from './BookshelfLibrary';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: theme.spacing(40),
      height: '100vh',
      width: '80vw',
      maxWidth: '100vw',
      display: 'flex',
      flexDirection: 'column',
    },
    section: {
      flexGrow: 0,
      padding: theme.spacing(2),
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    sectionMain: {
      flexGrow: 1,
      overflowY: 'scroll',
      overflowX: 'hidden',
      background: theme.palette.background.default,
    },
  })
);

interface BookshelfDrawerProps {}

export const BookshelfDrawer: FC<BookshelfDrawerProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const bookshelf = useBookshelfState();
  const isAdmin = useIsAdmin();

  const onOpen = useCallback(() => {
    dispatch(uiActions.openBookshelf());
  }, []);

  const onClose = useCallback(() => {
    dispatch(uiActions.closeBookshelf());
  }, []);

  const openWizard = useCallback(() => {
    dispatch(uiActions.setBookshelfView('WIZARD'));
  }, []);

  const openLibrary = useCallback(() => {
    dispatch(uiActions.setBookshelfView('LIBRARY'));
  }, []);

  const onWizardDone = useCallback(() => {
    openLibrary();
  }, []);

  return (
    <SwipeableDrawer
      open={bookshelf.opened}
      anchor={'left'}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Box className={classes.root}>
        <Box className={clsx(classes.section, classes.sectionHeader)}>
          <Typography variant={'h6'}>Bookshelf</Typography>

          {bookshelf.view === 'WIZARD' && (
            <Button
              color={'secondary'}
              variant={'contained'}
              onClick={openLibrary}
            >
              Back to library
            </Button>
          )}

          {bookshelf.view === 'LIBRARY' && isAdmin && (
            <Button
              color={'secondary'}
              variant={'contained'}
              onClick={openWizard}
            >
              ADD BOOK
            </Button>
          )}
        </Box>

        <Divider />

        <Box className={clsx(classes.section, classes.sectionMain)}>
          {bookshelf.view === 'LIBRARY' && <BookshelfLibrary />}
          {bookshelf.view === 'WIZARD' && (
            <BookshelfBookWizard onDone={onWizardDone} />
          )}
        </Box>

        <Divider />

        <Box className={classes.section}>
          <Typography variant={'body2'} />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
