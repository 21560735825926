/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

interface HeadphonesIconProps {
  color: string;
}

const HeadphonesIcon: FC<HeadphonesIconProps> = ({ color }): ReactElement => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6365 12.75H18.6365C18.2387 12.75 17.8571 12.908 17.5758 13.1893C17.2945 13.4707 17.1365 13.8522 17.1365 14.25V18C17.1365 18.3978 17.2945 18.7794 17.5758 19.0607C17.8571 19.342 18.2387 19.5 18.6365 19.5H20.1365C20.5343 19.5 20.9158 19.342 21.1971 19.0607C21.4784 18.7794 21.6365 18.3978 21.6365 18V12.75ZM21.6365 12.75C21.6365 11.5624 21.4014 10.3865 20.9449 9.2901C20.4883 8.19373 19.8192 7.19856 18.9763 6.36196C18.1333 5.52536 17.1331 4.86388 16.0333 4.41564C14.9335 3.96739 13.7558 3.74125 12.5682 3.75026C11.3806 3.74125 10.203 3.96739 9.10319 4.41564C8.00339 4.86388 7.00318 5.52536 6.16021 6.36196C5.31725 7.19856 4.6482 8.19373 4.19163 9.2901C3.73506 10.3865 3.5 11.5624 3.5 12.75V18C3.5 18.3978 3.65804 18.7794 3.93934 19.0607C4.22064 19.342 4.60218 19.5 5 19.5H6.5C6.89782 19.5 7.27936 19.342 7.56066 19.0607C7.84196 18.7794 8 18.3978 8 18V14.25C8 13.8522 7.84196 13.4707 7.56066 13.1893C7.27936 12.908 6.89782 12.75 6.5 12.75H3.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HeadphonesIcon;
