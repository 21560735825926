import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { ThemeNameEnum } from '@read4speed/models';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';
import ChevronIcon from 'src/components/Icons/chevronBig';
import { colors } from 'src/styles/colors';
import { CustomMenu } from 'src/styles/customMenu';

import { useStyles } from './styles/readerSettings';

function getThemeName(str: ThemeNameEnum): string {
  switch (str) {
    case 'WHITE':
      return 'White';
    case 'PAPERWHITE':
      return 'Paper White';
    case 'DARK':
      return 'Dark';
  }
}

interface ReaderSettingsThemeProps {
  value?: ThemeNameEnum;
  onChange(theme: ThemeNameEnum): void;
}

export const ReaderSettingsTheme: FC<ReaderSettingsThemeProps> = ({
  value = 'WHITE',
  onChange,
}) => {
  const classes = useStyles();
  const [localValue, setLocalValue] = useState<ThemeNameEnum>(value);
  const [themeMenu, setThemeMenu] = useState(null);
  const isThemeMenuOpen = Boolean(themeMenu);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    onChange(localValue);
  }, [localValue]);

  const toggleThemeMenu = useCallback(event => {
    setThemeMenu(event.currentTarget);
  }, []);

  const closeThemeMenu = useCallback(() => {
    setThemeMenu(null);
  }, []);

  const handleChange = useCallback((value: ThemeNameEnum) => {
    setLocalValue(value);
    closeThemeMenu();
  }, []);

  return (
    <label className={classes.row}>
      <span>Theme</span>

      <Button
        className={clsx(classes.settingsBtn, classes.dropdownBtn)}
        aria-haspopup="true"
        aria-label="user menu"
        aria-expanded={isThemeMenuOpen ? 'true' : undefined}
        onClick={toggleThemeMenu}
      >
        {getThemeName(localValue)}
        <ChevronIcon color={colors.greyBase} />
      </Button>

      <CustomMenu
        autoFocus={false}
        anchorEl={themeMenu}
        open={isThemeMenuOpen}
        onClose={closeThemeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ style: { transform: 'translateY(4px)' } }}
      >
        <MenuItem disableRipple onClick={() => handleChange('WHITE')}>
          White
        </MenuItem>
        <MenuItem disableRipple onClick={() => handleChange('PAPERWHITE')}>
          Paper White
        </MenuItem>
        <MenuItem disableRipple onClick={() => handleChange('DARK')}>
          Dark
        </MenuItem>
      </CustomMenu>
    </label>
  );
};
