import './fonts.scss';

import { CssBaseline } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AssignmentTurnedInOutlined } from '@material-ui/icons';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Skeleton } from '@material-ui/lab';
import { Dispatch } from '@reduxjs/toolkit';
import { History } from 'history';
import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { APP_ROUTES_CONFIG } from './appRoutesConfig';
import { AppMenuDrawer } from './components/AppMenuDrawer';
import { AuthStateListener } from './components/AuthStateListener';
import { BookshelfDrawer } from './components/BookshelfDrawer';
import FullLogoIcon from './components/Icons/fullLogo';
import LogoutIcon from './components/Icons/logout';
import PencilIcon from './components/Icons/pencil';
import ReadingTestDialog from './components/ReadingTestDialog/ReadingTestDialog';
import { SideNav } from './components/Sidenav';
import { Snackbar } from './components/Snackbar';
import { signOutAction } from './redux/auth';
import { useAuthenticated, useAuthLoaded } from './redux/auth/selectors';
import { loadTextAction } from './redux/commonActions';
import { loadReadingHistoryAction } from './redux/readingHistory';
import { uiActions } from './redux/ui';

const useStyles = makeStyles<Theme, { isSidebarVisible: boolean }>(theme =>
  createStyles({
    content: {
      zIndex: -2,
      background: 'white',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: ({ isSidebarVisible }) => (isSidebarVisible ? 'grid' : 'block'),
      gridTemplateColumns: ({ isSidebarVisible }) =>
        isSidebarVisible ? 'auto 1fr' : 'none',
    },

    sidebar: {
      borderRadius: '0 20px 20px 0',
    },

    loadingState: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    loadingSkeleton: {
      width: 150,
    },
    logo: {
      '& svg': {
        display: 'none',
      },
      '& svg:last-child': {
        display: 'block',
      },
      [theme.breakpoints.up('sm')]: {
        '& svg:last-child': {
          display: 'none',
        },
        '& svg:first-child': {
          display: 'block',
        },
      },
    },
  })
);

interface AppProps {}

const sidebarData = [
  {
    icon: <HomeOutlinedIcon />,
    title: 'Home',
    onClick: (_dispatch: Dispatch, history: History) =>
      history.push(APP_ROUTES_CONFIG.HOME),
  },
  {
    icon: <AssignmentTurnedInOutlined />,
    title: 'Saved Words',
    onClick: (dispatch: Dispatch, _history: History) =>
      dispatch(uiActions.openSavedWords()),
  },
  {
    icon: <EqualizerIcon />,
    title: 'Leaderboard',
    onClick: (_dispatch: Dispatch, history: History) =>
      history.push(APP_ROUTES_CONFIG.STATISTICS),
  },
  {
    icon: <ListAltIcon />,
    title: 'Reading Notes',
    onClick: (dispatch: Dispatch, _history: History) =>
      dispatch(uiActions.openSavedQuotes()),
  },
  {
    icon: <PencilIcon color="currentColor" />,
    title: 'Edit Profile',
    onClick: (dispatch: Dispatch, _history: History) =>
      dispatch(uiActions.openUserSettings()),
  },
  {
    icon: <LogoutIcon color="currentColor" />,
    title: 'Sign Out',
    onClick: (dispatch: Dispatch, history: History) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(signOutAction(history)),
  },
];

export const App: FC<AppProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const authLoaded = useAuthLoaded();
  const isAuthenticated = useAuthenticated();
  const routeHistory = useHistory();

  useEffect(() => {
    if (!authLoaded || location.pathname.includes('/hooks/article')) return;
    dispatch(loadTextAction());
    dispatch(loadReadingHistoryAction());
  }, [authLoaded]);

  const isSidebarVisible = useMemo(() => {
    const pathname = location.pathname;
    return !(
      pathname.includes(APP_ROUTES_CONFIG.READER) ||
      pathname.includes(APP_ROUTES_CONFIG.SIGNIN) ||
      pathname.includes(APP_ROUTES_CONFIG.SIGNUP) ||
      pathname.includes(APP_ROUTES_CONFIG.READER_TEST)
    );
  }, [history.location.pathname]);

  const classes = useStyles({ isSidebarVisible });

  if (authLoaded && !isAuthenticated) {
    const pathname = location.pathname;
    if (
      !pathname.includes(APP_ROUTES_CONFIG.SIGNIN || APP_ROUTES_CONFIG.SIGNUP)
    ) {
      routeHistory.push(APP_ROUTES_CONFIG.SIGNIN);
      return null;
    }
  }

  return (
    <>
      <CssBaseline />
      <AuthStateListener />
      {!authLoaded && (
        <div className={classes.loadingState}>
          <IconButton className={classes.logo} aria-label="exit">
            <FullLogoIcon />
          </IconButton>
          <Skeleton className={classes.loadingSkeleton} />
        </div>
      )}
      {authLoaded && (
        <main className={classes.content}>
          {isSidebarVisible && (
            <SideNav className={classes.sidebar}>
              <SideNav.Top>
                <IconButton
                  aria-label="exit"
                  onClick={() => history.push(APP_ROUTES_CONFIG.HOME)}
                >
                  <FullLogoIcon />
                </IconButton>
              </SideNav.Top>
              <SideNav.Main>
                {sidebarData.map(({ icon, title, onClick }) => (
                  <Button
                    key={title}
                    color="default"
                    onClick={() => onClick(dispatch, history)}
                  >
                    {icon}
                    {title}
                  </Button>
                ))}
              </SideNav.Main>
            </SideNav>
          )}
          <AppRoutes />
          <AppMenuDrawer />
          <ReadingTestDialog />
          <BookshelfDrawer />
          <Snackbar />
        </main>
      )}
    </>
  );
};
