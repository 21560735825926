import { UnderlineHighlightNameEnum } from '@read4speed/models/src/UnderlineHighlightNameEnum';

import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateUnderlineHighlightAction = (
  id: string,
  underlineHighlight: UnderlineHighlightNameEnum[]
): AppThunk => async dispatch => {
  dispatch(
    userProfilesActions.update({
      id,
      data: { underlineHighlight },
    })
  );
  try {
    await UserProfilesService.updateUnderlineHighlight(id, underlineHighlight);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`can't save the highlight`, error);
  }
};
