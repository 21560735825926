import queryString from 'query-string';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';

enum UserActions {
  emailVerification = 'verifyEmail',
  resetPassword = 'resetPassword',
}

const queryParams = queryString.parse(location?.search);

export const AuthVerify: FC = () => {
  const history = useHistory();
  const { mode, oobCode, continueUrl } = queryParams;

  if (mode === UserActions.emailVerification && oobCode) {
    history.push(APP_ROUTES_CONFIG.EMAIL_VERIFY + `/${oobCode}`);
  }

  if (mode === UserActions.resetPassword && oobCode) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Object is possibly 'null'.
    const email = (continueUrl as string).match(/email=(.*)/)[1];

    if (email)
      history.push(
        APP_ROUTES_CONFIG.PASSWORD_RECOVERY + `/${oobCode}?email=${email}`
      );
  }

  return null;
};
