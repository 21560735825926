import { ThemeNameEnum } from '@read4speed/models';

import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateThemeAction = (
  id: string,
  theme: ThemeNameEnum
): AppThunk => async dispatch => {
  dispatch(
    userProfilesActions.update({
      id,
      data: { theme },
    })
  );
  try {
    await UserProfilesService.updateTheme(id, theme);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`can't save the theme`, error);
  }
};
