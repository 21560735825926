import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const CustomIconButton = withStyles({
  root: {
    borderRadius: '12px',
    width: '48px',
    height: '48px',
    backgroundColor: colors.orange600,
    '&:hover, &:focus': {
      backgroundColor: colors.orange700,
    },
    '&:active': {
      backgroundColor: colors.orange800,
    },
  },
})(IconButton);
