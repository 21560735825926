/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

interface CloseIconProps {
  color: string;
}

const CloseIcon: FC<CloseIconProps> = ({ color }): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2879 3L11.5 9.78786L4.71214 3L3 4.71214L9.78786 11.5L3 18.2879L4.71214 20L11.5 13.2121L18.2879 20L20 18.2879L13.2121 11.5L20 4.71214L18.2879 3Z"
      fill={color}
    />
  </svg>
);

export default CloseIcon;
