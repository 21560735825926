import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from './appRoutesConfig';
import { ArticleHookPage } from './Pages/ArticleHook';
import { AuthVerify } from './Pages/AuthVerify';
import { HomePage } from './Pages/Home';
import { PasswordRecoveryPage } from './Pages/PasswordRecovery';
import { PasswordResetPage } from './Pages/PasswordReset';
import { PrivacyPolicyPage } from './Pages/PrivacyPolicy';
import { ReaderPage } from './Pages/Reader';
import ReadingTestPage from './Pages/Reader/ReadingTestPage';
import { SigninPage } from './Pages/Signin';
import { SignupPage } from './Pages/Signup';
import { StatisticsPage } from './Pages/Statistics';
import { VerifyEmail } from './Pages/VerifyEmail';
import { useTextLoaded } from './redux/text/selectors';

interface AppRoutesProps {}

export const AppRoutes: FC<AppRoutesProps> = () => {
  const textLoaded = useTextLoaded();

  return (
    <Switch>
      <Route path={APP_ROUTES_CONFIG.SIGNIN}>
        <SigninPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.SIGNUP}>
        <SignupPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.PASSWORD_RESET}>
        <PasswordResetPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.PASSWORD_RECOVERY}>
        <PasswordRecoveryPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.ARTICLE_HOOK}>
        <ArticleHookPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.HOME}>
        <HomePage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.READER}>
        <ReaderPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.READER_TEST}>
        <ReadingTestPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.STATISTICS}>
        <StatisticsPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.AUTH_VERIFY}>
        <AuthVerify />
      </Route>

      <Route path={APP_ROUTES_CONFIG.EMAIL_VERIFY}>
        <VerifyEmail />
      </Route>

      <Route path={APP_ROUTES_CONFIG.PRIVACY_POLICY}>
        <PrivacyPolicyPage />
      </Route>

      {textLoaded && <Redirect to={APP_ROUTES_CONFIG.READER} />}

      <Redirect from={'/'} to={APP_ROUTES_CONFIG.HOME} />
    </Switch>
  );
};
