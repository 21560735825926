import { LineHeightEnum } from '@read4speed/models';

import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateLineHeightAction = (
  id: string | undefined,
  lineHeight: LineHeightEnum
): AppThunk => async dispatch => {
  if (id) {
    dispatch(
      userProfilesActions.update({
        id,
        data: { lineHeight },
      })
    );

    await UserProfilesService.updateLineHeight(id, lineHeight);
  }
};
