/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

interface PlayIconProps {
  color: string;
}

const PlayIcon: FC<PlayIconProps> = ({ color }): ReactElement => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 25.7992C16.8947 25.7992 20.1505 24.4507 22.5509 22.0502C24.9514 19.6497 26.2999 16.394 26.2999 12.9992C26.2999 9.60445 24.9514 6.34872 22.5509 3.94825C20.1505 1.54779 16.8947 0.199219 13.5 0.199219C10.1052 0.199219 6.84945 1.54779 4.44898 3.94825C2.04852 6.34872 0.699951 9.60445 0.699951 12.9992C0.699951 16.394 2.04852 19.6497 4.44898 22.0502C6.84945 24.4507 10.1052 25.7992 13.5 25.7992ZM12.788 8.46802C12.547 8.30725 12.2669 8.21492 11.9776 8.20086C11.6882 8.18681 11.4005 8.25156 11.1451 8.38822C10.8897 8.52488 10.6762 8.7283 10.5274 8.9768C10.3785 9.2253 10.2999 9.50955 10.3 9.79922V16.1992C10.2999 16.4889 10.3785 16.7731 10.5274 17.0216C10.6762 17.2701 10.8897 17.4736 11.1451 17.6102C11.4005 17.7469 11.6882 17.8116 11.9776 17.7976C12.2669 17.7835 12.547 17.6912 12.788 17.5304L17.5879 14.3304C17.8071 14.1843 17.9868 13.9863 18.111 13.7541C18.2353 13.5219 18.3003 13.2626 18.3003 12.9992C18.3003 12.7358 18.2353 12.4765 18.111 12.2443C17.9868 12.0121 17.8071 11.8141 17.5879 11.668L12.788 8.46802Z"
      fill={color}
    />
  </svg>
);

export default PlayIcon;
