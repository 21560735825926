import { Divider, Tooltip } from '@material-ui/core';
import { Box, Chip, LinearProgress, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { EventTypes } from '@read4speed/models/dist/AnalyticsEventsModel';
import { groupBy, map, omit, reduce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { TopBar } from '../../components/TopBar';
import useAnalyticsEvents from '../../hooks/useAnalyticsEvents';
import {
  useLatestReadingHistoryItem,
  useReadingHistoryItems,
} from '../../redux/readingHistory/selectors';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      ...theme.global.containerRoot,
      [theme.breakpoints.up('sm')]: {
        fontSize: '27px',
      },
    },
    collapse: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    avatar: {
      background: 'none',
      border: `1px solid ${theme.palette.grey['400']}`,
    },
    linearProgress: {
      width: '100%',
      maxWidth: 200,
      alignSelf: 'center',
    },
    fieldValue: {
      maxWidth: 150,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    container: {
      padding: 16,
      background: theme.palette.background.default,
      borderRadius: 8,
      minWidth: 345,
    },
    list: {
      overflowY: 'scroll',
      marginBottom: 40,
    },
  })
);

const historyItemsContent: Record<string, string> = {
  totalItems: 'Total reading items',
  finishedItems: 'Finished reading items',
  unfinishedItems: 'In progress reading items',
  averageReadingSpeed: 'Average reading speed',
  averageSpeechSpeed: 'Average speech speed',
  readWordsCount: 'Total words read',
  totalWordsCount: 'Total words in reading items',
};

const currentItemContent: Record<string, string> = {
  readingStatus: 'Current reading status',
  previewText: 'Title',
  lastSpeed: 'Last reading speed',
  lastSpeechSpeed: 'Last speed speech',
  remainingReadingTime: 'Remaining time to finish',
  totalWordsCount: 'Total article words',
  wordIndex: 'Total words read', // read words count
  lastReadDate: 'Last read date',
  timestamp: 'Start reading date',
};

const eventsContent: Partial<Record<EventTypes, string>> = {
  'player-play': 'Play button click count',
};

export const StatisticsPage = (): JSX.Element => {
  const classes = useStyles();
  const [, getUserEvents] = useAnalyticsEvents();
  const [userEvents, setUserEvents] = useState({});
  const readingHistoryItems = useReadingHistoryItems();
  const latestReadingHistoryItem = useLatestReadingHistoryItem();
  const readingHistoryItemsData = useMemo(
    () =>
      readingHistoryItems.reduce(
        (acc, item, idx) => {
          const isLastCycle = idx === readingHistoryItems.length - 1;
          const sumReadingSpeed = item?.lastSpeed + acc.averageReadingSpeed;
          const sumSpeechSpeed = item?.lastSpeechSpeed + acc.averageSpeechSpeed;
          const finishedItems = item?.isFinished
            ? acc.finishedItems + 1
            : acc.finishedItems;
          return {
            ...acc,
            finishedItems,
            unfinishedItems: isLastCycle
              ? readingHistoryItems.length - finishedItems
              : 0,
            averageReadingSpeed: isLastCycle
              ? sumReadingSpeed / readingHistoryItems.length
              : sumReadingSpeed,
            averageSpeechSpeed: isLastCycle
              ? sumSpeechSpeed / readingHistoryItems.length
              : sumSpeechSpeed,
            totalWordsCount: item?.totalWordsCount + acc.totalWordsCount,
            readWordsCount: item?.wordIndex + acc.readWordsCount,
          };
        },
        {
          totalItems: readingHistoryItems.length,
          finishedItems: 0,
          unfinishedItems: 0,
          averageReadingSpeed: 0,
          averageSpeechSpeed: 0,
          totalWordsCount: 0,
          readWordsCount: 0,
        }
      ),
    [readingHistoryItems.length]
  );

  const latestReadingHistoryItemData = useMemo(
    () =>
      reduce(
        currentItemContent,
        (acc, _value, key) => {
          if (!latestReadingHistoryItem || key === 'readingStatus') return acc;
          return latestReadingHistoryItem.hasOwnProperty(key)
            ? {
                ...acc,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                [key]: latestReadingHistoryItem[key],
              }
            : acc;
        },
        {
          readingStatus: latestReadingHistoryItem?.isFinished
            ? 'Finished'
            : latestReadingHistoryItem?.wordIndex
            ? 'In progress'
            : 'Not started',
          ...omit(currentItemContent, 'readingStatus'),
          timestamp: '',
        }
      ),
    [latestReadingHistoryItem]
  );

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const events = await getUserEvents();
      const groupedEvents = groupBy(events, event => event.eventName);
      setUserEvents(groupedEvents);
    };
    fetchData();
  }, [getUserEvents]);

  return (
    <Container maxWidth={'md'} className={classes.root}>
      <TopBar />
      <Typography variant="h4" gutterBottom align="center">
        Reading statistics
      </Typography>
      <Typography variant="subtitle1">
        <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
          {latestReadingHistoryItemData?.timestamp ? (
            <>
              <Box
                className={classes.container}
                display="flex"
                gridRowGap={8}
                flexDirection="column"
              >
                <Typography variant="h6" gutterBottom align="center">
                  Current reading article data
                </Typography>
                {map(latestReadingHistoryItemData, (value, key) => {
                  const isValidDate = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(
                    value
                  );
                  return (
                    <Box display="flex" justifyContent="space-between">
                      <Box mr={2}>{currentItemContent[key]}:</Box>
                      <Chip
                        clickable
                        size="small"
                        label={
                          isValidDate ? (
                            moment(value).format('YYYY.MM.DD [at] hh:mm A')
                          ) : (
                            <Tooltip
                              disableHoverListener={
                                !(
                                  typeof value === 'string' &&
                                  value?.length > 23
                                )
                              }
                              title={value}
                            >
                              <div className={classes.fieldValue}>{value}</div>
                            </Tooltip>
                          )
                        }
                        variant="outlined"
                      />
                    </Box>
                  );
                })}
              </Box>
            </>
          ) : (
            <LinearProgress
              color="secondary"
              className={classes.linearProgress}
            />
          )}
          {readingHistoryItemsData.totalItems ? (
            <>
              <Box
                className={classes.container}
                display="flex"
                gridRowGap={8}
                flexDirection="column"
              >
                <Typography variant="h6" gutterBottom align="center">
                  Global Data
                </Typography>
                {map(readingHistoryItemsData, (value, key) => {
                  return (
                    <Box display="flex" justifyContent="space-between">
                      <Box mr={2}>{historyItemsContent[key]}:</Box>
                      <Chip
                        clickable
                        size="small"
                        label={value}
                        variant="outlined"
                      />
                    </Box>
                  );
                })}
              </Box>
            </>
          ) : (
            <LinearProgress
              color="secondary"
              className={classes.linearProgress}
            />
          )}
        </Box>
        <br />
        <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
          {!!Object.keys(userEvents).length ? (
            <>
              <Box
                className={classes.container}
                display="flex"
                gridRowGap={8}
                flexDirection="column"
              >
                <Typography variant="h6" gutterBottom align="center">
                  Current reading article data
                </Typography>
                {map(
                  userEvents,
                  (value: Record<string, string>[], key: EventTypes) => {
                    return (
                      <Box display="flex" justifyContent="space-between">
                        <Box mr={2}>{eventsContent[key]}:</Box>
                        <Chip
                          clickable
                          size="small"
                          label={value?.length}
                          variant="outlined"
                        />
                      </Box>
                    );
                  }
                )}
              </Box>
            </>
          ) : (
            <LinearProgress
              color="secondary"
              className={classes.linearProgress}
            />
          )}
          <Box
            className={classes.container}
            display="flex"
            gridRowGap={8}
            flexDirection="column"
          >
            <Typography variant="h6" gutterBottom align="center">
              Diagnostic data
            </Typography>
            No data yet 🤭
          </Box>
        </Box>
      </Typography>
      <Box my={4}>
        <Divider />
      </Box>
    </Container>
  );
};
