import { Badge, createStyles, IconButton, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import { ParagraphModel } from '@read4speed/models';
import { ParagraphCommentsModal } from '@read4speed/models/src';
import { CommentModal } from '@read4speed/models/src/ParagraphCommentsModal';
import { isEqual } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import * as uuid from 'uuid';

import { firestoreTimeStampNow } from '../../firebase';
import { useTextId } from '../../redux/text/selectors';
import { ParagraphCommentsService } from '../../services/ParagraphCommentsService';
import { CommentsBox } from './CommentsBox';

interface OwnProps {
  paragraph: ParagraphModel;
}

type Props = OwnProps;

const useCommentsStyles = makeStyles(() =>
  createStyles({
    rootIcon: {
      marginLeft: 16,
    },
    badge: {
      display: 'flex',
      alignSelf: 'center',
    },
  })
);

const getParagraphComments = async (
  textRefId: string
): Promise<ParagraphCommentsModal[] | null> => {
  return await ParagraphCommentsService.getByTextRefId(textRefId);
};

export const Comments: FC<Props> = ({ paragraph }) => {
  const [isCommentsBoxVisible, setIsCommentsBoxVisible] = useState(false);
  const [paragraphComments, setParagraphComments] = useState<
    Partial<ParagraphCommentsModal> | undefined
  >();
  const commentsStyles = useCommentsStyles();
  const textId = useTextId();

  useEffect(() => {
    const loadComments = async (): Promise<void> => {
      if (textId) {
        const paragraphComments = await getParagraphComments(textId);
        if (paragraphComments?.length) {
          const filteredParagraph = paragraphComments.find(
            loadedParagraphData =>
              isEqual(paragraph, loadedParagraphData.paragraph)
          );
          if (filteredParagraph) {
            setParagraphComments(filteredParagraph);
          }
        }
      }
    };
    loadComments();
  }, []);

  const updateParagraphComments = async (
    newComments: CommentModal[]
  ): Promise<void> => {
    if (textId) {
      if (paragraphComments?.uid) {
        const paragraphData = await ParagraphCommentsService.getByUid(
          paragraphComments.uid
        );
        if (paragraphData) {
          await ParagraphCommentsService.update(paragraphData.id, {
            comments: newComments,
          });
          setParagraphComments(prevState => {
            return {
              ...prevState,
              comments: newComments,
            };
          });
        }
      } else {
        setParagraphComments(() => {
          return {
            paragraph,
            textRefId: textId,
            comments: newComments,
            uid: uuid.v4(),
            timestamp: firestoreTimeStampNow(),
          };
        });
        await ParagraphCommentsService.create({
          paragraph,
          textRefId: textId,
          comments: newComments,
          uid: uuid.v4(),
        });
      }
    }
  };

  return (
    <Badge
      badgeContent={paragraphComments?.comments?.length}
      className={commentsStyles.badge}
      color="secondary"
    >
      <IconButton
        className={commentsStyles.rootIcon}
        aria-label="settings"
        onFocus={e => e.target.blur()}
        onClick={() => setIsCommentsBoxVisible(true)}
      >
        <CommentIcon />
      </IconButton>
      <Popover
        id={'comments'}
        open={isCommentsBoxVisible}
        onClose={() => setIsCommentsBoxVisible(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CommentsBox
          comments={paragraphComments?.comments || []}
          updateParagraphComments={updateParagraphComments}
          handleClose={() => setIsCommentsBoxVisible(false)}
        />
      </Popover>
    </Badge>
  );
};
