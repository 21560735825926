import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles(theme => {
  return createStyles({
    root: {
      maxWidth: '340px',
      fontFamily: 'Syne',
      fontSize: '17px',
      fontWeight: 400,
      color: colors.greyBase,
    },
    alert: {
      padding: 0,
      color: colors.red2,
      fontFamily: 'Syne',
      fontSize: '17px',
      background: 'transparent',
      minHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      margin: '10px 0 13px',
      '& > div': {
        padding: 0,
      },
      '& svg': {
        color: colors.red2,
      },
      '& .MuiAlert-icon': {
        marginRight: '10px',
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    submitBtn: {
      height: '50px',
      color: 'white',
      backgroundColor: colors.orange600,
      borderRadius: '8px',
      fontWeight: 500,
      fontSize: '18px',
      '&:hover': {
        backgroundColor: colors.orange600,
      },
      '& svg': {
        marginRight: '16px',
      },
      '&.reset-password': {
        marginTop: '40px',
      },
    },
    googleSignIn: {
      color: colors.orange600,
      backgroundColor: '#F3F4F6',
      '&:hover': {
        backgroundColor: '#F3F4F6',
      },
    },
    divider: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '20px',
      margin: '30px 0',
      '& span': {
        margin: '0 13px',
      },
      '& hr': {
        width: '100%',
        height: '1px',
        color: colors.grey300,
      },
    },
    controls: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '24px',
      margin: '25px 0',
      '&.signup': {
        height: '48px',
        margin: '20px 0 24px',
      },
      '& .signup': {
        alignItems: 'flex-start',
        '& .checkbox': {
          marginTop: '-6px',
        },
        '& a': {
          whiteSpace: 'nowrap',
          marginLeft: 0,
        },
      },
      '& .label': {
        fontFamily: 'Syne',
        fontSize: '17px',
        '& span': {
          marginRight: '5px',
        },
      },
      '& .checkbox:hover': {
        backgroundColor: 'transparent',
      },
    },
    signup: {
      marginTop: '40px',
      textAlign: 'center',
    },
    link: {
      color: colors.orange600,
      marginLeft: '5px',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  });
});
