/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

interface LogoutIconProps {
  color: string;
}

const LogoutIcon: FC<LogoutIconProps> = ({ color }): ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.75C2 1.784 2.784 1 3.75 1H6.25C6.44891 1 6.63968 1.07902 6.78033 1.21967C6.92098 1.36032 7 1.55109 7 1.75C7 1.94891 6.92098 2.13968 6.78033 2.28033C6.63968 2.42098 6.44891 2.5 6.25 2.5H3.75C3.6837 2.5 3.62011 2.52634 3.57322 2.57322C3.52634 2.62011 3.5 2.6837 3.5 2.75V13.25C3.5 13.388 3.612 13.5 3.75 13.5H6.25C6.44891 13.5 6.63968 13.579 6.78033 13.7197C6.92098 13.8603 7 14.0511 7 14.25C7 14.4489 6.92098 14.6397 6.78033 14.7803C6.63968 14.921 6.44891 15 6.25 15H3.75C3.28587 15 2.84075 14.8156 2.51256 14.4874C2.18437 14.1592 2 13.7141 2 13.25V2.75ZM12.44 7.25H6.75C6.55109 7.25 6.36032 7.32902 6.21967 7.46967C6.07902 7.61032 6 7.80109 6 8C6 8.19891 6.07902 8.38968 6.21967 8.53033C6.36032 8.67098 6.55109 8.75 6.75 8.75H12.44L10.47 10.72C10.3963 10.7887 10.3372 10.8715 10.2962 10.9635C10.2552 11.0555 10.2332 11.1548 10.2314 11.2555C10.2296 11.3562 10.2482 11.4562 10.2859 11.5496C10.3236 11.643 10.3797 11.7278 10.451 11.799C10.5222 11.8703 10.607 11.9264 10.7004 11.9641C10.7938 12.0018 10.8938 12.0204 10.9945 12.0186C11.0952 12.0168 11.1945 11.9948 11.2865 11.9538C11.3785 11.9128 11.4613 11.8537 11.53 11.78L14.78 8.53C14.9205 8.38937 14.9993 8.19875 14.9993 8C14.9993 7.80125 14.9205 7.61063 14.78 7.47L11.53 4.22C11.4613 4.14631 11.3785 4.08721 11.2865 4.04622C11.1945 4.00523 11.0952 3.98319 10.9945 3.98141C10.8938 3.97963 10.7938 3.99816 10.7004 4.03588C10.607 4.0736 10.5222 4.12974 10.451 4.20096C10.3797 4.27218 10.3236 4.35701 10.2859 4.4504C10.2482 4.54379 10.2296 4.64382 10.2314 4.74452C10.2332 4.84523 10.2552 4.94454 10.2962 5.03654C10.3372 5.12854 10.3963 5.21134 10.47 5.28L12.44 7.25Z"
      fill={color}
    />
  </svg>
);

export default LogoutIcon;
