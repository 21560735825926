import { BookPageModel } from '@read4speed/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  acceptCollectionStateData,
  createCollectionState,
  ICollectionState,
} from '../utils';

export type PageTexts = Record<string, string>;

export interface BookSlicePagesState {
  pages: ICollectionState<BookPageModel>;
}
export interface BookSlicePageTextsState {
  pageTexts: PageTexts;
}
export interface BookSliceBookInfoState {
  id: string | null;
  title: string | null;
  pagesCount: number;
}

export type BookSliceState = BookSlicePagesState &
  BookSlicePageTextsState &
  BookSliceBookInfoState;

const initialState: BookSliceState = {
  id: null,
  title: null,
  pages: createCollectionState(),
  pageTexts: {},
  pagesCount: 0,
};

interface SetBookInfoPayload {
  pagesCount: number;
  bookId: string;
  title: string;
}

export const bookSlice = createSlice({
  initialState,
  name: 'book',
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },
    acceptPages(state, action: PayloadAction<BookPageModel[]>) {
      acceptCollectionStateData(state.pages, action.payload, {
        key: 'number',
        order: 'asc',
      });
    },
    acceptPageTexts(state, action: PayloadAction<PageTexts>) {
      Object.assign(state.pageTexts, action.payload);
    },
    setBookInfo(state, action: PayloadAction<SetBookInfoPayload>) {
      const { bookId, pagesCount, title } = action.payload;
      state.pagesCount = pagesCount;
      state.id = bookId;
      state.title = title;
    },
  },
});
