import { FirestoreModelBase } from '@read4speed/models';

import { ICollectionState } from './ICollectionState';

export const removeFromCollectionState = <TData extends FirestoreModelBase>(
  collectionState: ICollectionState<TData>,
  id: string
): void => {
  const index = collectionState.ids.indexOf(id);
  if (index !== -1) {
    delete collectionState.byId[id];
    collectionState.ids.splice(index, 1);
  }
};
