import { ReadingHistoryItemType } from '@read4speed/models';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';

export const useTextType = (): ReadingHistoryItemType => {
  return useSelector<RootState, ReadingHistoryItemType>(
    state => state.text.textType
  );
};
