import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { TextQuotesModal } from '@read4speed/models';
import { Dictionary } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { groupBy, isEmpty, map } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from 'src/components/Icons/close';
import { uiActions } from 'src/redux/ui';

import { useAppSelector } from '../../redux/hooks';
import { selectAllTextIds } from '../../redux/readingHistory';
import { useQuotesData } from '../../redux/textQuotes/selectors';
import { quotesActions } from '../../redux/textQuotes/textQuotesSlice';
import { useSavedQuotesListOpened } from '../../redux/ui/selectors';
import { QuotesDrawer, useQuotesStyles } from './styles/savedQuotesListStyles';
import { useStyles } from './styles/savedWordsList';

export const SavedQuotesList: FC = () => {
  const [quotes, setQuotes] = useState<Dictionary<TextQuotesModal[]>>({});
  const classes = useStyles();
  const quotesClasses = useQuotesStyles();
  const dispatch = useDispatch();
  const isVisible = useSavedQuotesListOpened();
  const data = useQuotesData();
  const readingItemsIds = useAppSelector(selectAllTextIds);

  useEffect(() => {
    if (readingItemsIds.length && !data.quotes.length) {
      dispatch(quotesActions.fetchQuotes(readingItemsIds));
    }
  }, []);

  useEffect(() => {
    if (data.quotes.length) {
      const groupedQuotes = groupBy(data.quotes, 'textRefId');
      setQuotes(groupedQuotes);
    }
  }, [data.quotes]);

  const handleDeleteQuote = (docId?: string, textRefId?: string) => {
    if (docId && textRefId) {
      dispatch(quotesActions.removeQuote({ docId, textRefId }));
    }
  };

  const close = useCallback(() => {
    dispatch(uiActions.closeSavedQuotes());
  }, []);

  return (
    <QuotesDrawer
      container={document.body}
      anchor="bottom"
      open={isVisible}
      onClose={close}
      onOpen={() => ({})}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box className={classes.header}>
        <span>Saved Quotes</span>
        <IconButton aria-label="exit" onClick={close}>
          <CloseIcon color="black" />
        </IconButton>
      </Box>

      <List className={clsx([classes.root, quotesClasses.root])}>
        {!isEmpty(quotes) ? (
          map(quotes, (value: TextQuotesModal[]) => {
            return (
              <>
                <Typography variant="h6">{value[0].textRefTitle}</Typography>
                {value.map(({ quote, uid, id, textRefId }) => {
                  return (
                    <ListItem
                      key={uid}
                      alignItems="flex-start"
                      className={classes.listItem}
                    >
                      <ListItemText
                        primary={quote.text}
                        primaryTypographyProps={{
                          className: classes.listItemText,
                        }}
                      />
                      <div className={classes.commentActions}>
                        <IconButton
                          aria-label="settings"
                          onClick={() => handleDeleteQuote(id, textRefId)}
                        >
                          <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                      </div>
                    </ListItem>
                  );
                })}
                <Divider classes={{ root: quotesClasses.divider }} />
              </>
            );
          })
        ) : (
          <Typography color="textSecondary">No saved quotes yet 😔</Typography>
        )}
      </List>
    </QuotesDrawer>
  );
};
