/* eslint-disable max-len */
import React, { ReactElement } from 'react';

const BookIcon = (): ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="8" fill="#FDE047" />
    <path
      d="M9.3335 22.2503C9.3335 21.6978 9.55299 21.1679 9.94369 20.7772C10.3344 20.3865 10.8643 20.167 11.4168 20.167H22.6668"
      stroke="#92400E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4168 7.66699H22.6668V24.3337H11.4168C10.8643 24.3337 10.3344 24.1142 9.94369 23.7235C9.55299 23.3328 9.3335 22.8029 9.3335 22.2503V9.75033C9.3335 9.19779 9.55299 8.66789 9.94369 8.27719C10.3344 7.88649 10.8643 7.66699 11.4168 7.66699V7.66699Z"
      stroke="#92400E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BookIcon;
