import { TextQuotesModal } from '@read4speed/models/src/TextQuotesModal';
import { createSlice } from '@reduxjs/toolkit';

import {
  createQuote,
  fetchQuotes,
  removeQuote,
  sliceIdentifier,
} from './actions';

export interface TextQuotesState {
  quotes: TextQuotesModal[];
  edges: number[];
  isQuotesActive: boolean;
  isCreatingQuote: boolean;
}

const initialState: TextQuotesState = {
  quotes: [],
  edges: [],
  isQuotesActive: false,
  isCreatingQuote: false,
};

const textQuotesSlice = createSlice({
  name: sliceIdentifier,
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },
    setEdges(state, { payload }: { payload: number }) {
      if (state.edges.length >= 2) {
        state.edges = [];
      }
      if (payload < state.edges[0]) {
        state.edges.unshift(payload);
      }
      state.edges.push(payload);
    },
    clearEdges(state) {
      state.edges = [];
    },
    setIsQuotesActive(state, { payload }) {
      state.isQuotesActive = payload;
      state.edges = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(createQuote.fulfilled, (state, action) => {
      if (action.payload) {
        state.quotes.push(action.payload);
      }
      state.isCreatingQuote = false;
    });
    builder.addCase(createQuote.pending, state => {
      state.isCreatingQuote = true;
    });
    builder.addCase(fetchQuotes.fulfilled, (state, action) => {
      state.quotes = action.payload || [];
    });
    builder.addCase(removeQuote.fulfilled, (state, { payload }) => {
      const quoteIndexToRemove = state.quotes.findIndex(
        ({ id }) => id === payload
      );
      state.quotes = [
        ...state.quotes.slice(0, quoteIndexToRemove),
        ...state.quotes.slice(quoteIndexToRemove + 1),
      ];
    });
  },
});

export const { reducer: quotesReducer } = textQuotesSlice;

export const quotesActions = {
  ...textQuotesSlice.actions,
  createQuote,
  fetchQuotes,
  removeQuote,
};
