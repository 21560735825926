import { Button } from '@material-ui/core';
import { FontNameEnum } from '@read4speed/models';
import clsx from 'clsx';
import React, { FC, useCallback, useMemo } from 'react';

import { ReaderFontService } from '../../services/ReaderFontService';
import { useStyles } from './styles/readerSettings';

interface FontPreviewProps {
  selected: boolean;
  fontName: FontNameEnum;
  onClick: (fontName: FontNameEnum) => void;
}

export const FontPreview: FC<FontPreviewProps> = ({
  fontName,
  selected,
  onClick,
}) => {
  const classes = useStyles();
  const styles = useMemo(
    () => ReaderFontService.makeFontFamilyStyles(fontName),
    [fontName]
  );
  const clickHandler = useCallback(() => !selected && onClick(fontName), [
    fontName,
    selected,
  ]);

  return (
    <Button
      className={clsx(
        classes.settingsBtn,
        classes.typefaceBtn,
        selected && 'active'
      )}
      style={styles}
      onClick={clickHandler}
    >
      Aa
    </Button>
  );
};
