/* eslint-disable max-len */
import React, { ReactElement } from 'react';

const GoogleIcon = (): ReactElement => (
  <svg
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_0_162)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.68097 16C6.68097 14.9932 6.84808 14.0279 7.1467 13.1226L1.92271 9.13351C0.904555 11.2006 0.331055 13.5299 0.331055 16C0.331055 18.4681 0.90407 20.7958 1.92053 22.8617L7.14161 18.8649C6.8459 17.9637 6.68097 17.0019 6.68097 16Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8527 6.84095C18.0399 6.84095 20.0155 7.61595 21.5676 8.88405L26.083 4.375C23.3315 1.97952 19.8038 0.5 15.8527 0.5C9.71862 0.5 4.44668 4.00784 1.92236 9.1335L7.14611 13.1226C8.34978 9.46893 11.7808 6.84095 15.8527 6.84095Z"
        fill="#EA4335"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8527 25.159C11.7811 25.159 8.35002 22.5311 7.14635 18.8774L1.92236 22.8658C4.44668 27.9922 9.71862 31.5 15.8527 31.5C19.6386 31.5 23.2533 30.1556 25.966 27.6369L21.0075 23.8035C19.6083 24.6848 17.8464 25.159 15.8527 25.159Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6693 16C30.6693 15.084 30.5281 14.0976 30.3165 13.1819H15.853V19.1705H24.1785C23.7621 21.2124 22.6292 22.782 21.0077 23.8035L25.9663 27.6369C28.8159 24.9922 30.6693 21.0523 30.6693 16Z"
        fill="#4285F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_162">
        <rect
          width="31"
          height="31"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleIcon;
