import { firestoreToPlainObject } from '@read4speed/firebase-utils';
import {
  FontNameEnum,
  HighlightNameEnum,
  LineHeightEnum,
  ScrollTypeEnum,
  TextAlignEnum,
  ThemeNameEnum,
  UserProfileModel,
} from '@read4speed/models';
import { WordFormatEnum } from '@read4speed/models/src';
import { AbsenceEmailsModel } from '@read4speed/models/src/AbsenceEmailsModel';
import { FixationPointsEnum } from '@read4speed/models/src/FixationPointsEnum';
import { UnderlineHighlightNameEnum } from '@read4speed/models/src/UnderlineHighlightNameEnum';

import { collections, firestoreTimeStampNow } from '../firebase';
import { ScheduledEmailTypes } from '../hooks/useAbsenceEmailsUpdate';

export class UserProfilesService {
  static async ensure(
    uid: string,
    data: Partial<UserProfileModel>
  ): Promise<UserProfileModel> {
    const userProfileByUID = await this.getByUid(uid);
    const userProfileByEmail = await this.getByEmail(data?.email || '');
    if (
      userProfileByUID &&
      userProfileByUID.id &&
      !userProfileByUID?.email &&
      data?.email
    ) {
      await this.update(userProfileByUID.id, { email: data.email });
    }
    if (
      uid &&
      userProfileByEmail &&
      userProfileByEmail.id &&
      !userProfileByEmail.uid
    ) {
      await this.update(userProfileByEmail.id, { ...userProfileByEmail, uid });
    }
    return userProfileByUID || userProfileByEmail || this.create(uid, data);
  }

  static async ensureEmailVerification(
    docId: string,
    email: string,
    emailVerified: boolean
  ): Promise<void> {
    const userProfileByEmail = await this.getByEmail(email);
    if (userProfileByEmail?.isEmailVerified !== emailVerified) {
      this.updateEmailVerification(docId, emailVerified);
    }
  }

  static async create(
    uid: string,
    data: Partial<UserProfileModel> = {}
  ): Promise<UserProfileModel> {
    const documentReference = await collections.userProfilesRef().add({
      ...data,
      uid,
      isEmailVerified: data?.isEmailVerified || false,
      timestamp: firestoreTimeStampNow(),
    });
    const snapshot = await documentReference.get();
    return firestoreToPlainObject(snapshot);
  }

  static async getById(id: string): Promise<UserProfileModel | null> {
    const snapshot = await collections.userProfilesRef().doc(id).get();
    if (!snapshot.exists) return null;
    return firestoreToPlainObject(snapshot);
  }

  static async getByUid(uid: string): Promise<UserProfileModel | null> {
    const snapshot = await collections
      .userProfilesRef()
      .where('uid', '==', uid)
      .get();

    if (snapshot.size === 0) return null;

    return firestoreToPlainObject(snapshot.docs[0]);
  }

  static async getByEmail(email: string): Promise<UserProfileModel | null> {
    const snapshot = await collections
      .userProfilesRef()
      .where('email', '==', email)
      .get();

    if (snapshot.size === 0) return null;

    return firestoreToPlainObject(snapshot.docs[0]);
  }

  static async update(
    id: string,
    data: Partial<UserProfileModel>
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update(data);
  }

  static async updateCursorColor(
    id: string,
    cursorColor: string
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ cursorColor });
  }

  static async updateFont(id: string, fontName: FontNameEnum): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ fontName });
  }

  static async updateTextAlign(
    id: string,
    textAlign: TextAlignEnum
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ textAlign });
  }

  static async updateEmailVerification(
    id: string,
    isEmailVerified: boolean
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ isEmailVerified });
  }

  static async updateAbsenceEmails(
    id: string,
    absenceEmails: AbsenceEmailsModel[],
    emailsType: ScheduledEmailTypes
  ): Promise<void> {
    await collections
      .userProfilesRef()
      .doc(id)
      .update({ [emailsType]: absenceEmails });
  }

  static async updateSavedWords(
    id: string,
    savedWords: string[]
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ savedWords });
  }

  static async updateFontSize(id: string, fontSize: number): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ fontSize });
  }

  static async updateTheme(id: string, theme: ThemeNameEnum): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ theme });
  }

  static async updateLineHeight(
    id: string,
    lineHeight: LineHeightEnum
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ lineHeight });
  }

  static async updateScrollType(
    id: string,
    scrollType: ScrollTypeEnum
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ scrollType });
  }

  static async updateWordFormat(
    id: string,
    wordFormat: WordFormatEnum
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ wordFormat });
  }

  static async updateHighlight(
    id: string,
    highlight: HighlightNameEnum[]
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ highlight });
  }

  static async updateUnderlineHighlight(
    id: string,
    underlineHighlight: UnderlineHighlightNameEnum[]
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ underlineHighlight });
  }

  static async updateFixationPoints(
    id: string,
    fixationPoints: FixationPointsEnum[]
  ): Promise<void> {
    await collections.userProfilesRef().doc(id).update({ fixationPoints });
  }
}
