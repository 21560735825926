import TextField from '@material-ui/core/TextField';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SubmitButton } from 'src/styles/submitButton';

import { readCustomInputTextAction } from '../../redux/commonActions';
import { useTextBusy } from '../../redux/text/selectors';
import { useStyles } from './styles/textInput';

interface TextInputProps {
  text?: string;
}

interface TextInputForm {
  text: string;
}

export const TextInput: FC<TextInputProps> = ({ text }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const textBusy = useTextBusy();

  const { register, handleSubmit, formState, trigger } = useForm<TextInputForm>(
    {
      mode: 'all',
      reValidateMode: 'onChange',
    }
  );

  const onSubmit = handleSubmit(({ text }) => {
    if (!text) return;
    dispatch(readCustomInputTextAction(text, history));
  });

  useEffect(() => {
    trigger('text');
  }, [text]);

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <label>
        <span>Add text here:</span>
        <TextField
          inputRef={register({ required: true })}
          className={classes.textInput}
          id="outlined-textarea"
          name="text"
          rows={9}
          rowsMax={20}
          multiline
          InputProps={{ disableUnderline: true }}
        />
      </label>
      <SubmitButton
        disabled={!formState.isValid || textBusy}
        variant="contained"
        type="submit"
      >
        Start Reading
      </SubmitButton>
    </form>
  );
};
