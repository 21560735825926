import { pick } from 'lodash';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { DiagnosticReadStats } from '../diagnosticSlice';

export const useDiagnosticReadStats = (): DiagnosticReadStats => {
  return useSelector<RootState, DiagnosticReadStats>(state => {
    return pick(state.diagnostic, [
      'startTime',
      'endTime',
      'readingTime',
      'WPM',
    ]);
  });
};
