import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'none',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px',
      fontSize: '28px',
      lineHeight: '32px',
      color: colors.grey800,
      borderBottom: `1px solid ${colors.greyLight}`,
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    body: {
      padding: '32px 16px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '12px',
      [theme.breakpoints.up('sm')]: {
        padding: '24px',
        borderRadius: '20px',
      },
    },
    row: {
      position: 'relative',
      fontSize: '16px',
      lineHeight: '24px',
      color: colors.grey600,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
      '&:last-of-type': {
        marginBottom: 0,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
      },
    },
    settingsBtn: {
      fontFamily: 'inherit',
      fontWeight: 'normal',
      marginLeft: '8px',
      borderRadius: '12px',
      backgroundColor: colors.grey50,
      border: `1px solid ${colors.grey300}`,
      padding: '11px',
      height: '40px',
      [theme.breakpoints.up('sm')]: {
        height: '48px',
      },
      '& svg': {
        marginLeft: '14px',
      },
      '& span': {
        color: colors.grey69,
        fontSize: '15px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '17px',
        },
      },
      '&.active': {
        borderColor: colors.orange600,
        backgroundColor: colors.orange50,
      },
    },
    dropdownBtn: {
      justifyContent: 'space-between',
      paddingLeft: '16px',
    },
    typefaceBtn: {
      width: '48px',
      minWidth: '48px',
      height: '48px',
      '& span': {
        lineHeight: '17px',
      },
    },
    fontSizeText: {
      fontSize: '20px',
      lineHeight: '24px',
      margin: '0 12px',
      color: colors.grey700,
    },
    fontSizeBtn: {
      padding: '12px',
    },
    colorPicker: {
      '& .react-colorful': {
        position: 'absolute',
        zIndex: 1,
        top: '52px',
        right: 0,
      },
    },
    colorPreview: {
      minWidth: '40px',
      maxWidth: '40px',
      width: '40px',
      height: '40px',
      borderRadius: '12px',
      marginLeft: '8px',
      transitionDuration: '0.15s',
      [theme.breakpoints.up('sm')]: {
        minWidth: '48px',
        maxWidth: '48px',
        width: '48px',
        height: '48px',
      },
    },
    colorText: {
      padding: '11px 15px 11px 11px',
      marginLeft: 'auto',
      '& .color-hash': {
        fontSize: '17px',
        color: colors.greyBase,
      },
    },
  })
);
