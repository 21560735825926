import { UserProfileModel } from '@read4speed/models';
import { AbsenceEmailsModel } from '@read4speed/models/src/AbsenceEmailsModel';
import { SendGridEmailConfig } from '@read4speed/models/src/ScheduledEmailsModel';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { firestoreTimeStampNow } from '../firebase';
import { updateAbsenceEmails } from '../redux/commonActions';
import { useAuthorizedUserProfile } from '../redux/userProfiles/selectors';
import { ScheduledEmailsService } from '../services/ScheduledEmailsService';

const SENDER_EMAIL = 'hello@read4speed.com';

export type ScheduledEmailTypes = 'absenceEmails' | 'articleAbsenceEmails';

interface TimeoutConfig {
  after: number;
  period?: string;
  data?: Record<string, unknown>;
}

interface OwnProps {
  additionalData?: Record<string, unknown>;
  timeoutConfigs: TimeoutConfig[];
  dateCallback: (date: Date | number, amount: number) => Date;
  emailsType: ScheduledEmailTypes;
  templateId: string;
}

enum ScheduledEmailStatusEnum {
  pending = 'PENDING',
  completed = 'COMPLETED',
  error = 'ERROR',
}

const getSendgridEmail = (
  userProfile: UserProfileModel,
  templateId: string,
  data = {}
): Record<'sendgridEmail', SendGridEmailConfig> => {
  return {
    sendgridEmail: {
      templateId,
      to: userProfile.email as string,
      from: SENDER_EMAIL,
      dynamic_template_data: data,
    },
  };
};

export const useAbsenceEmailsUpdate = (
  props: OwnProps,
  predicates: unknown[] = []
): void => {
  const {
    timeoutConfigs,
    dateCallback,
    emailsType,
    templateId,
    additionalData = {},
  } = props;
  const dispatch = useDispatch();
  const userProfile = useAuthorizedUserProfile();

  useEffect(() => {
    const updateScheduledEmails = async (): Promise<void> => {
      const predicatesCheck = predicates.every(Boolean);
      if (
        predicatesCheck &&
        userProfile &&
        userProfile?.id &&
        userProfile?.isEmailVerified
      ) {
        const prevAbsenceEmails = userProfile[emailsType] || [];
        const existedAbsenceEmails: AbsenceEmailsModel[] = [];

        if (prevAbsenceEmails.length) {
          await Promise.all(
            prevAbsenceEmails.map(async (absenceEmail: AbsenceEmailsModel) => {
              const scheduledEmail = await ScheduledEmailsService.getById(
                absenceEmail?.id
              );
              if (absenceEmail?.id && scheduledEmail) {
                existedAbsenceEmails.push(absenceEmail);
                const today = firestoreTimeStampNow();
                const scheduledDate = dateCallback(
                  today.toDate(),
                  absenceEmail.absenceTimeout
                );
                return ScheduledEmailsService.update(absenceEmail.id, {
                  scheduledDate: scheduledDate,
                  status: ScheduledEmailStatusEnum.pending,
                  sendgridEmail: {
                    ...scheduledEmail?.sendgridEmail,
                    dynamic_template_data: {
                      ...scheduledEmail?.sendgridEmail?.[
                        'dynamic_template_data'
                      ],
                      ...additionalData,
                    },
                  },
                });
              }
            })
          );
        }

        if (existedAbsenceEmails.length !== timeoutConfigs.length) {
          const newScheduledEmailsPromises = timeoutConfigs
            .filter(({ after }) => {
              const existedAbsenceEmail = existedAbsenceEmails.find(
                ({ absenceTimeout }) => absenceTimeout === after
              );
              return !existedAbsenceEmail;
            })
            .map(timeoutConfig => {
              const today = firestoreTimeStampNow();
              const scheduledDate = dateCallback(
                today.toDate(),
                timeoutConfig.after
              );
              const data = { ...timeoutConfig?.data, ...additionalData };
              return {
                absenceTimeout: timeoutConfig.after,
                emailPromise: ScheduledEmailsService.create({
                  timestamp: today,
                  scheduledDate: scheduledDate,
                  status: ScheduledEmailStatusEnum.pending,
                  userEmail: userProfile.email as string,
                  ...getSendgridEmail(userProfile, templateId, data),
                }),
              };
            });

          const newScheduledEmails = await Promise.all(
            newScheduledEmailsPromises.map(
              async ({ absenceTimeout, emailPromise }) => {
                const emailData = await emailPromise;
                return {
                  absenceTimeout,
                  id: emailData.id,
                };
              }
            )
          );

          const updatedAbsenceEmails = timeoutConfigs.map(({ after }) => {
            const findEmail = (time: number) => ({
              absenceTimeout,
            }: {
              absenceTimeout: number;
            }) => absenceTimeout === time;
            const updateScheduledEmail = newScheduledEmails.find(
              findEmail(after)
            ) as AbsenceEmailsModel;
            if (!updateScheduledEmail) {
              return existedAbsenceEmails.find(
                findEmail(after)
              ) as AbsenceEmailsModel;
            }
            return updateScheduledEmail;
          });
          dispatch(
            updateAbsenceEmails(
              userProfile.id,
              updatedAbsenceEmails,
              emailsType
            )
          );
        }
      }
    };

    updateScheduledEmails();
  }, [userProfile?.id, ...predicates]);

  return;
};
