import { FirestoreModelBase } from '@read4speed/models';

import { ICollectionState } from './ICollectionState';

export const createCollectionState = <
  T extends FirestoreModelBase
>(): ICollectionState<T> => {
  return {
    byId: {},
    ids: [],
    loaded: false,
    loading: false,
    loadingIds: [],
  };
};
