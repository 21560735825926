import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const calculateLineStyles = (props?: Record<PropertyKey, any>) => {
  let width = '';
  let offset = '';
  if (props?.lineWidthOptions) {
    const fullWidth =
      props?.lineWidthOptions?.left + props?.lineWidthOptions?.width;
    const w = fullWidth * 0.75; // get 75% from full width
    const o = (fullWidth - w) / 2; // calculate offset to center element
    width = `${w < 100 ? fullWidth : w}px`;
    offset = `${w < 100 ? 0 : o}px`;
  }
  return { width, offset };
};

interface StyleProps {
  lineWidthOptions?: Record<PropertyKey, number>;
  top: number;
  left: number;
  width: number;
  height: number;
  pTop: number;
  pHeight: number;
  backgroundColor: string;
  isLineMode: boolean;
  isWordMode: boolean;
  isUnderlineLineMode: boolean;
  isUnderlineWordMode: boolean;
  isParagraphMode: boolean;
  isFixationPoints: boolean;
}

const animationDuration = '0.1s';
const widthAnimationDuration = '0.08s';

export const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    root: {
      zIndex: -1,
      display: 'flex',
      position: 'absolute',
      height: '16px',
      padding: '4px',
      borderRadius: '8px',
      transition: `top ${animationDuration} ease, left ${animationDuration} ease, width ${widthAnimationDuration} ease`,
    },
    lineMode: p => {
      const background = p.backgroundColor;
      const { width, offset } = calculateLineStyles(p);
      return {
        width: width || '100%',
        marginLeft: offset || 0,
        background,
        top: p.top,
        height: p.height,
        left: 0,
        right: 0,
        opacity: 0.5,
      };
    },
    underlineLineMode: p => {
      const { width, offset } = calculateLineStyles(p);
      return {
        width: width || '100%',
        paddingTop: '4px',
        marginLeft: offset || 0,
        top: p.top + 4,
        height: p.height - 8 - 2,
        left: 0,
        right: 0,
        opacity: 0.5,
        borderBottom: '2px solid black',
        borderRadius: 0,
      };
    },
    wordMode: p => ({
      top: p.top,
      left: p.left - 4,
      width: p.width + 8,
      height: p.height,
      backgroundColor: p.backgroundColor,
    }),
    underlineWordMode: p => ({
      top: p.top,
      left: p.left - 4,
      width: p.width + 8,
      height: p.height - 8,
      borderBottom: '2px solid black',
      borderRadius: 0,
    }),
    paragraphMode: p => ({
      left: 0,
      right: 0,
      top: p.pTop,
      height: p.pHeight,
      opacity: 0.2,
      backgroundColor: p.backgroundColor,
    }),
    fixationPoints: p => {
      const { width, offset } = calculateLineStyles(p);
      const bgColor = p.isLineMode
        ? 'rgba(255, 255, 255, 1)'
        : 'rgba(0, 0, 0, 1)';
      return {
        background:
          Number(width.split('px')[0]) < 300
            ? // eslint-disable-next-line max-len
              `linear-gradient(90deg, transparent 20%, ${bgColor} 25%, transparent 30%, transparent 70%, ${bgColor} 75%, transparent 80%)`
            : // eslint-disable-next-line max-len
              `linear-gradient(90deg, transparent 0%, ${bgColor} 5%, transparent 10%, transparent 45%, ${bgColor} 50%, transparent 55%, transparent 90%, ${bgColor} 95%, transparent 100%)`,
        width: width || '100%',
        paddingTop: '4px',
        marginLeft: offset || 0,
        top: p.top + 4,
        height: p.height - 8 - 2,
        left: 0,
        right: 0,
        opacity: 0.5,
      };
    },
  })
);
