import { UserModel } from '@read4speed/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthenticatedState {
  isAuthenticated: boolean;
  user: UserModel | null;
}

interface AuthLoadedState {
  isLoaded: boolean;
}

interface AuthErrorState {
  error: AuthError | null;
}

interface PasswordResetState {
  isCodeValid: boolean;
  email: string | null;
}

export type AuthState = AuthenticatedState &
  AuthLoadedState &
  AuthErrorState &
  PasswordResetState;

//  ------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isCodeValid: false,
  user: null,
  error: null,
  email: null,
  isLoaded: false,
};

//  ------------------------------------

export interface AuthError {
  type: string;
  message: string;
}

interface SetAuthenticatedPayload {
  isAuthenticated: boolean;
  user: UserModel | null;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },
    setError(state, action: PayloadAction<AuthError | null>) {
      state.error = action.payload;
    },
    setLoadedState(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload;
    },
    setAuthCodeValid(state, action: PayloadAction<boolean>) {
      state.isCodeValid = action.payload;
    },
    setEmail(state, action: PayloadAction<string | null>) {
      state.email = action.payload;
    },
    setAuthenticated(
      state,
      { payload }: PayloadAction<SetAuthenticatedPayload>
    ) {
      state.isAuthenticated = payload.isAuthenticated;
      state.user = payload.user;
      state.isLoaded = true;
    },
  },
});

export const { reducer: authReducer, actions: authActions } = authSlice;
