import { RefObject, useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

function useOutsideClick<T extends HTMLElement = HTMLElement>(
  ref1: RefObject<T>,
  ref2: RefObject<T>,
  handler: (event: AnyEvent) => void,
  isActivated: boolean = true
): void {
  useEffect(() => {
    const listener = (event: AnyEvent): void => {
      if (!isActivated) return;
      const el1 = ref1?.current;
      const el2 = ref2?.current;

      if (
        (el1 && el1.contains(event.target as Node)) ||
        (el2 && el2.contains(event.target as Node))
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [ref1, ref2, handler]);
}

export default useOutsideClick;
