import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    text: {
      fontSize: 'inherit',
      fontFamily: 'inherit',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    line: {
      flex: 'auto',
      height: '1px',
      background: theme.palette.grey['400'],
    },
  })
);

interface PageSeparatorProps {
  pageNumber: number;
}

export const PageSeparator: FC<PageSeparatorProps> = ({ pageNumber }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <div className={classes.line} />
      <Typography className={classes.text} variant={'caption'}>
        page {pageNumber}
      </Typography>
      <div className={classes.line} />
    </Box>
  );
};
