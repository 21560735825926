import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { isEqual } from 'lodash';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    input: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
      width: `calc(50% - ${theme.spacing(2)}px)`,
    },
    alert: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

export interface BookFormFields {
  author: string;
  title: string;
}

interface BookFormProps {
  data: BookFormFields;
  onSave: (data: BookFormFields) => void;
  onCancel: () => void;
}

export const BookForm: FC<BookFormProps> = ({ data, onSave, onCancel }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, formState } = useForm<BookFormFields>(
    {
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: data,
    }
  );

  const submitHandler = handleSubmit(update => {
    if (!isEqual(update, data)) onSave(update);
  });

  return (
    <form onSubmit={submitHandler} className={classes.root}>
      <TextField
        className={classes.input}
        required
        autoFocus
        id={'author'}
        label={'Author'}
        name={'author'}
        color={'secondary'}
        inputRef={register({ required: true })}
        variant={'standard'}
      />

      {errors.author && (
        <Alert className={classes.alert} severity="error">
          Author is required
        </Alert>
      )}

      <TextField
        className={classes.input}
        required
        id={'title'}
        label={'Title'}
        name={'title'}
        color={'secondary'}
        inputRef={register({ required: true })}
        variant={'standard'}
      />

      {errors.title && (
        <Alert className={classes.alert} severity="error">
          Title is required
        </Alert>
      )}

      <Box className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={!formState.isValid}
          type="submit"
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          Confirm
        </Button>
      </Box>
    </form>
  );
};
