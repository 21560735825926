import React, { FC, ReactElement } from 'react';

interface MinusIconProps {
  color: string;
}

const MinusIcon: FC<MinusIconProps> = ({ color }): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 12H4" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default MinusIcon;
