import React, { FC, ReactElement } from 'react';

interface ChevronBigIconProps {
  color: string;
}

const ChevronBigIcon: FC<ChevronBigIconProps> = ({ color }): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 9L12 17L20 9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronBigIcon;
