import { firestoreToPlainObjectMap } from '@read4speed/firebase-utils';
import { ParagraphCommentsModal } from '@read4speed/models/src';

import { collections, firestoreTimeStampNow } from '../firebase';

export class ParagraphCommentsService {
  static async create(
    data: Omit<ParagraphCommentsModal, 'timestamp'>
  ): Promise<FirebaseFirestore.DocumentReference<ParagraphCommentsModal>> {
    return await collections.paragraphCommentsRef().add({
      ...data,
      timestamp: firestoreTimeStampNow(),
    });
  }

  static async getByUid(
    uid: string
  ): Promise<FirebaseFirestore.QueryDocumentSnapshot<ParagraphCommentsModal> | null> {
    const snapshot = await collections
      .paragraphCommentsRef()
      .where('uid', '==', uid)
      .get();

    if (snapshot.empty) return null;

    return snapshot.docs[0];
  }

  static async getByTextRefId(
    textRefId: string
  ): Promise<ParagraphCommentsModal[] | null> {
    const snapshot = await collections
      .paragraphCommentsRef()
      .where('textRefId', '==', textRefId)
      .get();

    if (snapshot.empty) return null;

    return firestoreToPlainObjectMap(snapshot.docs);
  }

  static async getByTextRefIds(
    textRefIds: string[]
  ): Promise<ParagraphCommentsModal[] | null> {
    const batchesPromise = [];
    while (textRefIds.length) {
      const batch = textRefIds.splice(0, 10);
      batchesPromise.push(
        collections.paragraphCommentsRef().where('textRefId', 'in', batch).get()
      );
    }

    const res = await Promise.all(batchesPromise).then(content =>
      content.flat()
    );

    const snapshot = res.reduce((acc: ParagraphCommentsModal[], curr) => {
      if (curr.empty) return acc;
      const arr = firestoreToPlainObjectMap(curr.docs);
      return acc.concat(arr);
    }, []);

    return snapshot;
  }

  static async update(
    id: string,
    data: Partial<ParagraphCommentsModal>
  ): Promise<void> {
    await collections
      .paragraphCommentsRef()
      .doc(id)
      .update({
        ...data,
        timestamp: firestoreTimeStampNow(),
      });
  }

  static async delete(id: string): Promise<void> {
    await collections.paragraphCommentsRef().doc(id).delete();
  }
}
