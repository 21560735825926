import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { booksLoadAction } from '../../redux/library';
import { useBooks } from '../../redux/library/selectors';
import { BookCard } from './BookCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
  })
);

interface BookshelfLibraryProps {}

export const BookshelfLibrary: FC<BookshelfLibraryProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const books = useBooks();

  useEffect(() => {
    dispatch(booksLoadAction());
  }, []);

  return (
    <Box className={classes.root}>
      <Grid container justify={'space-between'} spacing={1}>
        {books.map(b => (
          <Grid item xs={12} sm={12} md={6} key={b.id}>
            <BookCard book={b} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
