import {
  ReadingHistoryService,
  UpdateHistoryItemData,
} from '../../services/ReadingHistoryService';
import { AppThunk } from '../store';
import { readingHistoryActions } from './readingHistorySlice';

export const updateReadingHistoryItem = (
  fields: Partial<UpdateHistoryItemData>
): AppThunk => async (dispatch, getState) => {
  const state = getState();

  const { textId, textType } = state.text;

  const uid = state.auth.user?.uid;

  if (!uid || !textId) return;

  const { docs } = await ReadingHistoryService.getSnapshotByUId(
    uid,
    textId,
    textType
  );
  const readingHistoryItemId = docs?.[0]?.id;
  if (readingHistoryItemId) {
    const item = await ReadingHistoryService.updateItem(
      readingHistoryItemId,
      fields
    );
    dispatch(readingHistoryActions.acceptHistoryItems([item]));
  }
};
