import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useReaderTitleState } from '../../redux/reader/selectors';
import { useTextId, useWords } from '../../redux/text/selectors';
import { useQuotesData } from '../../redux/textQuotes/selectors';
import { quotesActions } from '../../redux/textQuotes/textQuotesSlice';

const QUOTE_STATES = {
  isLoading: false,
  isFinished: false,
  idle: true,
};

export const QuotesConfirmation = () => {
  const [open, setOpen] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [quoteState, setQuoteState] = useState(QUOTE_STATES);
  const { edges } = useQuotesData();
  const textId = useTextId();
  const words = useWords();
  const { currentTitle } = useReaderTitleState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (edges.length >= 2) {
      setOpen(true);
      const selectedWordsArray = words.slice(edges[0], edges[1] + 1);
      const selectedWordsString = selectedWordsArray.reduce((acc, curr) => {
        return acc + ' ' + curr.text;
      }, '');
      setSelectedText(selectedWordsString.trim());
    }
    if (!edges.length) {
      setOpen(false);
    }
  }, [edges]);

  const handleClose = () => {
    setOpen(false);
    dispatch(quotesActions.clearEdges());
  };

  const handleSave = async () => {
    if (textId && edges.length && selectedText) {
      setQuoteState({ ...QUOTE_STATES, isLoading: true });
      const newQuote = {
        textRefTitle: currentTitle,
        textRefId: textId,
        quote: { wordStart: edges[0], wordEnd: edges[1], text: selectedText },
      };

      await dispatch(quotesActions.createQuote(newQuote));
      setQuoteState({ ...QUOTE_STATES, isFinished: true });
    }
  };

  useEffect(() => {
    if (quoteState.isFinished) {
      setSelectedText('Quote saved!');
      setTimeout(() => {
        setQuoteState({ ...QUOTE_STATES });
        dispatch(quotesActions.clearEdges());
      }, 1500);
    }
  }, [quoteState.isFinished]);

  const renderContent = () => {
    if (quoteState.isLoading) {
      return <Skeleton />;
    }
    return (
      <>
        ```
        <br />
        {selectedText}
        <br />
        ```
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle id="draggable-dialog-title">
        Save highlighted quote?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{renderContent()}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={quoteState.isLoading || quoteState.isFinished}
          autoFocus
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={quoteState.isLoading || quoteState.isFinished}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
