import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { colors } from 'src/styles/colors';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import LockIcon from '../../components/Icons/lock';
import { authActions, changePassword } from '../../redux/auth';
import { useAuthError } from '../../redux/auth/selectors';
import { useAppDispatch } from '../../redux/store';
import { CustomInput } from '../../styles/customInput';
import { SubmitButton } from '../../styles/submitButton';
import { useStyles } from '../Signin/styles/signinForm';

interface PasswordRecoveryFormFields {
  password: string;
  passwordConfirm: string;
}

interface PasswordRecoveryFormProps {
  oobCode: string;
}

export const PasswordRecoveryForm: FC<PasswordRecoveryFormProps> = ({
  oobCode,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const authError = useAuthError();
  const classes = useStyles();
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const {
    register,
    handleSubmit,
    formState,
    watch,
  } = useForm<PasswordRecoveryFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { red1, red2 } = colors;
  const passwordRef = useRef({});
  passwordRef.current = watch('password', '');

  useEffect(() => {
    return () => {
      dispatch(authActions.setError(null));
    };
  }, []);

  const isAuthError = authError && authError.type === 'all';
  const isPasswordError = authError && authError.type === 'password';
  const isConfirmPasswordError = authError && authError.type === 'confirm-pass';

  const authErrorMessage = isAuthError ? authError?.message : '';
  const passwordErrorMessage = isPasswordError
    ? authError?.message
    : passwordError;
  const confirmPasswordErrorMessage = isConfirmPasswordError
    ? authError?.message
    : confirmPasswordError;

  const isPasswordInvalid = passwordErrorMessage || isAuthError;
  const isConfirmPasswordInvalid = confirmPasswordErrorMessage || isAuthError;
  const isError = !!(isPasswordInvalid || isConfirmPasswordInvalid);

  const submitHandler = handleSubmit(({ password, passwordConfirm }) => {
    if (oobCode && password && password === passwordConfirm) {
      dispatch(changePassword(oobCode, password))
        .then(() => history.push(APP_ROUTES_CONFIG.SIGNIN))
        .catch(({ code }) => {
          const error = {
            type: 'all',
            message: 'Something went wrong. Please try again.',
          };

          if (code === 'auth/weak-password') {
            error.message = 'The new password is not strong enough.';
            error.type = 'password';
          }

          dispatch(authActions.setError(error));
        });
    }
  });

  const onPasswordChange = useCallback((): void => {
    if (passwordErrorMessage) {
      setPasswordError('');
      dispatch(authActions.setError(null));
    }
  }, [passwordErrorMessage]);

  const onConfirmPasswordChange = useCallback((): void => {
    if (confirmPasswordErrorMessage) {
      setConfirmPasswordError('');
      dispatch(authActions.setError(null));
    }
  }, [confirmPasswordErrorMessage]);

  return (
    <>
      <form className={classes.root} onSubmit={submitHandler}>
        <CustomInput
          onChange={onPasswordChange}
          className={isPasswordInvalid ? 'invalid' : ''}
          inputRef={register({
            required: true,
            validate: (value: string) => {
              if (!/\d/g.test(value)) {
                setPasswordError('Password should include a digit.');
              } else if (!/\D/g.test(value)) {
                setPasswordError(
                  'Password should include at least one non-digit.'
                );
              } else if (value.length < 6) {
                setPasswordError('Password should be at least 6 letters long.');
              } else {
                setPasswordError('');
                return true;
              }
            },
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color={isPasswordInvalid ? red2 : red1} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          required
          fullWidth
          id="password"
          type="password"
          placeholder="New Password *"
          name="password"
          autoFocus
        />
        {passwordErrorMessage && (
          <Alert className={classes.alert} severity="error">
            {passwordErrorMessage}
          </Alert>
        )}

        <CustomInput
          onChange={onConfirmPasswordChange}
          className={isConfirmPasswordInvalid ? 'invalid' : ''}
          inputRef={register({
            required: true,
            validate: value => {
              if (value !== passwordRef.current) {
                setConfirmPasswordError('Passwords do not match.');
              } else {
                setConfirmPasswordError('');
                return true;
              }
            },
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color={isConfirmPasswordInvalid ? red2 : red1} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          required
          fullWidth
          name="passwordConfirm"
          placeholder="Confirm Password *"
          type="password"
          id="passwordConfirm"
        />
        {isAuthError || confirmPasswordErrorMessage ? (
          <Alert className={classes.alert} severity="error">
            {isAuthError ? authErrorMessage : confirmPasswordErrorMessage}
          </Alert>
        ) : null}

        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={!formState.isValid || isError}
          className={clsx(classes.submitBtn, 'reset-password')}
        >
          Update password
        </SubmitButton>
      </form>
    </>
  );
};
