import { firestoreToPlainObject } from '@read4speed/firebase-utils';
import { TextModel } from '@read4speed/models';
import { MD5 } from 'crypto-js';

import { collections, firestoreTimeStampNow } from '../firebase';
import { DEFAULT_TEXT } from './defaultText';

export class TextDataService {
  /**
   * @param text text to save
   * @returns id of {@link TextModel}
   */
  static async saveText(text: string): Promise<string> {
    this.setLSText(text);

    const textMD5 = MD5(text);
    const snapshot = await collections
      .textsRef()
      .where('textMD5', '==', textMD5.toString())
      .get();

    if (snapshot.size > 0) return snapshot.docs[0].id;

    return this.saveToFirestore(text);
  }

  static async loadTextUnauthenticated(): Promise<{
    text: string;
    textId?: string;
  }> {
    const text = this.getLSText() || (await this.getDefaultText());
    const textId = await this.saveText(text);

    return { text, textId };
  }

  static async update(
    id: string,
    data: TextModel
  ): Promise<FirebaseFirestore.WriteResult> {
    return await collections.textsRef().doc(id).update(data);
  }

  static async readFromFirestoreById(id: string): Promise<TextModel | null> {
    const snapshot = await collections.textsRef().doc(id).get();
    if (!snapshot.exists) return null;
    return firestoreToPlainObject(snapshot);
  }

  static getLSText(): string | null {
    return localStorage.getItem('REED4SPEED/text');
  }

  static setLSText(text: string): void {
    return localStorage.setItem('REED4SPEED/text', text);
  }

  static async getDefaultText(): Promise<string> {
    return DEFAULT_TEXT;
  }

  private static async saveToFirestore(text: string): Promise<string> {
    const textMD5 = MD5(text).toString();
    const reference = await collections.textsRef().add({
      text,
      textMD5,
      timestamp: firestoreTimeStampNow(),
    });

    return reference.id;
  }
}
