import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useScrollIntoView from '../../hooks/useScrollIntoView';
import { preloadPrevBookPagesAction } from '../../redux/book';
import { useBookInfo, useBookPagesBefore } from '../../redux/book/selectors';
import { useReaderPosition } from '../../redux/reader/selectors';
import { useTextPageNumber } from '../../redux/text/selectors';
import { useStyles } from './styles/readerPage';
import { TextReaderPagePreview } from './TextReaderPagePreview';

interface TextReaderUpperPagesProps {}

export const TextReaderUpperPages: FC<TextReaderUpperPagesProps> = () => {
  const dispatch = useDispatch();
  const pageNumber = useTextPageNumber();
  const position = useReaderPosition();
  const pagesBefore = useBookPagesBefore(pageNumber);
  const { pagesCount, id } = useBookInfo();
  const classes = useStyles({});

  useScrollIntoView(`span[data-word-index='${position}']`, [pagesBefore]);

  useEffect(() => {
    if (pageNumber === 0) return;
    if (pagesBefore.length >= 2) return;
    if (pageNumber - pagesBefore.length <= 0) return;
    if (!id) return;
    dispatch(preloadPrevBookPagesAction(id, pageNumber, 2));
  }, [pageNumber, pagesBefore.length, pagesCount, id]);

  return (
    <div className={classes.prevPages}>
      {pagesBefore.map(page => (
        <TextReaderPagePreview page={page} key={page.id} />
      ))}
    </div>
  );
};
