import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateCursorColorAction = (
  id: string,
  cursorColor: string
): AppThunk => async dispatch => {
  await UserProfilesService.updateCursorColor(id, cursorColor);
  dispatch(
    userProfilesActions.update({
      id,
      data: { cursorColor },
    })
  );
};
