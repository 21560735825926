import { FontNameEnum } from '@read4speed/models';
import { CSSProperties } from 'react';

export interface FontConfig {
  name: FontNameEnum;
  family: string;
  weight: number;
  generic: 'sans-serif' | 'serif';
}

export class ReaderFontService {
  static FONT_CONFIGS: Record<FontNameEnum, FontConfig> = {
    ACCESSIBLE: {
      name: 'ACCESSIBLE',
      family: 'Avenir',
      generic: 'sans-serif',
      weight: 500,
    },
    EUCLID: {
      name: 'EUCLID',
      generic: 'sans-serif',
      family: 'Euclid',
      weight: 400,
    },
    SERIF: {
      name: 'SERIF',
      family: 'CaslonPro',
      generic: 'serif',
      weight: 500,
    },
    SANS_SERIF: {
      name: 'SANS_SERIF',
      generic: 'sans-serif',
      family: 'Rubik',
      weight: 400,
    },
  };

  static DEFAULT_FONT: FontNameEnum = 'SANS_SERIF';

  static DEFAULT_SIZE = 16;
  static MAX_SIZE = 72;
  static MIN_SIZE = 8;

  static getAvailableFontConfigs(): FontConfig[] {
    return Object.values(this.FONT_CONFIGS);
  }

  static getFontConfig(name: FontNameEnum = this.DEFAULT_FONT): FontConfig {
    return this.FONT_CONFIGS[name];
  }

  static makeFontFamilyStyles(name?: FontNameEnum): CSSProperties {
    const { family, generic, weight } = this.getFontConfig(name);
    return {
      fontFamily: `'${family}', ${generic}`,
      fontWeight: weight,
    };
  }

  static makeFontSizeStyles(fontSize: number | undefined): CSSProperties {
    if (fontSize) return { fontSize: `${fontSize}px` };
    else return {};
  }
}
