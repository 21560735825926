/* eslint-disable max-len */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { TopBar } from '../../components/TopBar';
import { getFirebase } from '../../firebase';
import { updateUserProfileAction } from '../../redux/userProfiles';
import { useAuthorizedUserProfileId } from '../../redux/userProfiles/selectors';

enum ErrorCodes {
  expired = 'auth/expired-action-code',
  invalid = 'auth/invalid-action-code',
}

const useStyles = makeStyles(theme => {
  return createStyles({
    root: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 24px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 24px 24px',
      },
    },
  });
});

export const VerifyEmail: FC = () => {
  const routeHistory = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const profileId = useAuthorizedUserProfileId();
  const [isVerificationProceeded, setIsVerificationProceeded] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState({
    title: 'Your email has been verified',
    description: 'You can now sign in with your new account',
  });

  const oobCode = location?.pathname.split('/').pop();

  useEffect(() => {
    const verifyActionCode = async (): Promise<void> => {
      if (!oobCode) {
        setVerificationStatus({
          title: 'Try verifying your email again',
          description: 'Not supported or missed parameters',
        });
      }

      if (profileId) {
        try {
          await getFirebase()
            .auth()
            .applyActionCode(oobCode as string);
          setIsVerificationProceeded(true);
          if (profileId) {
            dispatch(
              updateUserProfileAction(profileId, { isEmailVerified: true })
            );
          }
        } catch ({ code = '', message }) {
          setIsVerificationProceeded(true);
          if (code === ErrorCodes.expired) {
            setVerificationStatus({
              title: 'Failed to complete the action',
              description: 'Your request to verify your email has expired.',
            });
          } else if (code === ErrorCodes.invalid) {
            setVerificationStatus({
              title: 'Failed to complete the action',
              description:
                'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
            });
          }
        }
      }
    };

    verifyActionCode();
  }, [profileId]);

  if (!isVerificationProceeded) return null;

  return (
    <Container className={classes.root}>
      <TopBar />
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {verificationStatus.title}
          </Typography>
          <Typography variant="body1" component="p">
            {verificationStatus.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => routeHistory.push(APP_ROUTES_CONFIG.HOME)}>
            Return to home page
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};
