import { Player } from '@lottiefiles/react-lottie-player';
import Box from '@material-ui/core/Box';
import { ReadingHistoryItemModel } from '@read4speed/models';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TextCursor } from 'src/components/TextCursor';
import { TextRenderer } from 'src/components/TextRenderer';
import { readerActions } from 'src/redux/reader';
import { useReadingHistoryItems } from 'src/redux/readingHistory/selectors';
import { SubmitButton } from 'src/styles/submitButton';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { PageSeparator } from '../../components/PageSeparator';
import { continueReadingAction } from '../../redux/commonActions/continueReadingAction';
import {
  useReaderIsFinished,
  useReaderPlaybackState,
  useReaderPosition,
  useReaderSpeechState,
} from '../../redux/reader/selectors';
import {
  useParagraphs,
  useTextPageNumber,
  useTextType,
  useTotalWordsCount,
  useWords,
} from '../../redux/text/selectors';
import { uiActions } from '../../redux/ui';
import { updateSavedWords } from '../../redux/userProfiles';
import { useAuthorizedUserProfile } from '../../redux/userProfiles/selectors';
import { useStyles } from './styles/textReader';
import { usePlayback } from './usePlayback';
import { usePlaybackKeyboardControl } from './usePlaybackKeyboardControl';

const ALLOWED_WORDS_COUNT = 10;

interface TextReaderProps {
  totalTimeToRead: string;
}

export const TextReader: FC<TextReaderProps> = props => {
  const { totalTimeToRead } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const paragraphs = useParagraphs();
  const words = useWords();
  const position = useReaderPosition();
  const readerContainerRef = useRef<HTMLDivElement>(null);
  const currentPageNumber = useTextPageNumber();
  const textType = useTextType();
  const userProfile = useAuthorizedUserProfile();
  const isFinished = useReaderIsFinished();
  const history = useHistory();
  const historyItems = useReadingHistoryItems();
  const { speed } = useReaderPlaybackState();
  const { speechSpeed, speechActive } = useReaderSpeechState();
  const [isSpeechFinal, setIsSpeechFinal] = useState(false);
  const [speedFinal, setSpeedFinal] = useState(0);
  const [
    nextArticle,
    setNextArticle,
  ] = useState<ReadingHistoryItemModel | null>(null);
  const totalWordsCount = useTotalWordsCount();

  const scrollTo = useCallback((isTop: boolean, delay: number) => {
    const scrollContainer = readerContainerRef.current?.parentElement;

    if (!scrollContainer) return;

    const top = isTop
      ? 0
      : scrollContainer.scrollHeight - scrollContainer.clientHeight;

    if (scrollContainer) {
      setTimeout(() => {
        scrollContainer.scroll({
          top,
          left: 0,
          behavior: 'smooth',
        });
      }, delay);
    }
  }, []);

  usePlayback();
  usePlaybackKeyboardControl();

  useEffect(() => {
    if (isFinished) {
      setIsSpeechFinal(speechActive);
      setSpeedFinal(speechActive ? speechSpeed : speed);
      scrollTo(false, 400);
    }
  }, [isFinished]);

  useEffect(() => {
    setNextArticle(historyItems.filter(item => !item.isFinished)[0]);
  }, [historyItems]);

  // Negative position, means it loaded the first time.
  // See the readerSlice initial state.
  const playedBefore = position >= 0;
  useEffect(() => {
    if (!playedBefore) dispatch(readerActions.setPosition(0));
  }, [playedBefore]);

  // TODO handle for speech
  const onWordClick = useCallback(index => {
    dispatch(readerActions.setPosition(index));
  }, []);

  const goLibraryClickHandler = useCallback(() => {
    history.push(APP_ROUTES_CONFIG.HOME);
  }, []);

  const onNextArticleClick = useCallback(() => {
    if (nextArticle) {
      dispatch(continueReadingAction(nextArticle));
      scrollTo(true, 400);
    }
  }, [nextArticle]);

  const onWordDoubleClick = useCallback(
    (word: string) => {
      if (userProfile?.id) {
        const savedWords = userProfile?.savedWords?.length
          ? [...userProfile?.savedWords, word]
          : [word];
        if (
          !userProfile?.savedWords ||
          userProfile?.savedWords?.length < ALLOWED_WORDS_COUNT
        ) {
          dispatch(updateSavedWords(userProfile.id, savedWords));
          dispatch(
            uiActions.openSnackbar({
              type: 'success',
              message: `Word was successfully saved into the list.`,
            })
          );
        } else {
          dispatch(
            uiActions.openSnackbar({
              type: 'warning',
              message: `Available words to save reached. ${ALLOWED_WORDS_COUNT} words max!`,
            })
          );
        }
      }
    },
    [userProfile?.id, userProfile?.savedWords]
  );

  return (
    <div className={classes.root} ref={readerContainerRef}>
      {textType === 'BOOK' && <PageSeparator pageNumber={currentPageNumber} />}
      <TextRenderer
        readerPosition={position}
        paragraphs={paragraphs}
        words={words}
        onWordClick={onWordClick}
        onWordDoubleClick={onWordDoubleClick}
      />
      {isFinished && (
        <div className={classes.finish}>
          <Player
            autoplay
            loop
            speed={0.5}
            src="https://assets3.lottiefiles.com/packages/lf20_ISbOsd.json"
            style={{ height: '256px', width: '256px' }}
          ></Player>
          <span>You’ve finished this article!</span>
          <Box display="flex" alignItems="center">
            <div className={classes.result}>
              <span>{speedFinal}</span>
              <span>{isSpeechFinal ? 'X' : 'WPM'}</span>
            </div>
            <div className={classes.result}>
              <span>{totalWordsCount}</span>
              <span>Words</span>
            </div>
            <div className={classes.result}>
              <span>{totalTimeToRead}</span>
              <span>Time</span>
            </div>
          </Box>
          <div className={classes.finishBtns}>
            <SubmitButton onClick={goLibraryClickHandler} variant="contained">
              Go to library
            </SubmitButton>
            {nextArticle && (
              <SubmitButton onClick={onNextArticleClick} variant="outlined">
                Next article
              </SubmitButton>
            )}
          </div>
        </div>
      )}
      {words[position] && (
        <TextCursor
          position={position}
          readerContainerRef={readerContainerRef}
        />
      )}
    </div>
  );
};
