import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { uiActions } from '../../redux/ui';
import { useSnackBarState } from '../../redux/ui/selectors';

const Alert: FC<AlertProps> = props => (
  <MuiAlert elevation={6} variant="filled" {...props} />
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export const CustomSnackbar: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { opened, type, message } = useSnackBarState();

  const handleClose: VoidFunction = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(uiActions.closeSnackbar());
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={opened}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
