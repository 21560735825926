import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import ArrowLeftIcon from '../../components/Icons/arrowLeft';
import BigLogoIcon from '../../components/Icons/logoBig';
import SignupIllustration from '../../components/Icons/signupIllustration';
import { useAuthenticated } from '../../redux/auth/selectors';
import { SignupForm } from './SignupForm';
import { useStyles } from './styles/signupPage';

interface SignupPageProps {}

export const SignupPage: FC<SignupPageProps> = () => {
  const history = useHistory();
  const isAuthenticated = useAuthenticated();
  const classes = useStyles();

  const goSignin = useCallback(() => {
    history.push(APP_ROUTES_CONFIG.SIGNIN);
  }, []);

  if (isAuthenticated) {
    history.push(APP_ROUTES_CONFIG.HOME);
  }

  return (
    <main className={classes.root}>
      <a className={classes.backBtn} onClick={goSignin}>
        <ArrowLeftIcon />
        <span>Back to Sign In</span>
      </a>
      <div className={classes.side}>
        <BigLogoIcon />
        <h2 className="desktop">Read Faster, Remember More</h2>
        <p className="desktop">
          Science-backed tools to make you a better reader on all your devices
        </p>
        <div className="desktop">
          <SignupIllustration />
        </div>
      </div>
      <div className={classes.main}>
        <h1>Unlock your brain&apos;s reading potential</h1>
        <p>Register for a free account</p>
        <SignupForm />
      </div>
    </main>
  );
};
