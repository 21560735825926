import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';

const ADMIN_USERS = [
  '6ZRcwuAEqYa98htAVwTLAjgtgLh2',
  'bRnqMQtRafPs3oEZBXOIN7TdI0h1',
  'Z70ueJ2tuhdtlm9nn15Q6nFHknz1',
];

export const useIsAdmin = (): boolean => {
  return useSelector<RootState, boolean>(state => {
    const { isAuthenticated, user } = state.auth;
    if (!isAuthenticated || !user?.uid) return false;
    return ADMIN_USERS.includes(user?.uid);
  });
};
