import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import { colors } from 'src/styles/colors';

interface Props {
  stopped: boolean;
  resetScrollState: VoidFunction;
}

const CONTENT_TEST = `
  How fast can you read?

  The idea of actual speed reading sounds amazing to anyone who wants to learn, read, and do more. Who wouldn’t want to read faster, especially if they could do it without any loss of comprehension? 
  
  But is it really possible?
  
  In this brief test, we’ll also teach you about the history of speed reading and the various ways people have tried using to learn to become faster readers.
  
  Ever since Evelyn Wood’s Reading Dynamics training program in 1959, many businesses have promoted programs trying to teach people to read faster. Around this time in the 1960s, especially in the US, people were fascinated about the idea of speed reading, as a handful of celebrities actively promoted Evelyn Wood’s speed reading courses in TV advertisements in the 1960s and 1970s and they popped up in hundreds of cities worldwide. 
  
  US President John F Kennedy was reported to read 1,200 words per minute, making the public more and more interested in the idea of speed reading as a form of personal growth. However, JFK had no actual association with Evelyn Wood’s program. Evelyn Wood herself claimed to read at 2,700 words per minute and to be able to train students to read 3 to 10 times as fast after her course.
  
  You may be wondering, how did she do it? 
  
  Evelyn Wood claimed to teach people to read down a page rather than across it, chunking words and phrases together and erasing the silent voice in our heads that says each word while we read — a process known as subvocalization. Subvocalization is how most people are taught to read, where they say each word to themselves in their head as they read it. However, this limits how fast we can read, as we can only go as fast as we can talk, and nowhere near the thousands of words per minute that some people like actor Burt Lancaster, astronaut John Glenn, Queen Ingrid of Denmark, and Wisconsin senator William Proxmire claimed to be able to read after taking Wood’s course.
  
  There were several success stories from these speed reading programs (for instance, senator William Proxmire of Wisconsin claimed to read at 20,000 words per minute), but there was also more scrutiny over time. Several people started to consider speed reading a scam, and the techniques taught by Wood and others are widely disputed by scientists who have studied how we read. 
  
  Some tech companies have offered new technology that they claim can make people better readers. Apps like Spritz using Rapid Serial Visual Presentation (RSVP) technology have raised tens of millions of dollars, but unfortunately, the science once again did not support their methods and they have not worked well for most readers. RSVP technology flashes one word on the screen at a time at a set reading speed. 
  
  RSVP apps might be a fun novelty to see how fast you can try to keep up, but it also makes reading much more difficult than it should be. Screens should make reading easier, not harder. Our eyes rely on saccades and regressions to read, but RSVP apps do not use thes. 
  
  Saccades are the rapid movements our eyes make as we move from one fixation point to another across a line of words, and regressions are the back-skipping movements our eyes often make to re-read previous text. Without regressions and saccades, RSVP apps have proven to decrease reading comprehensions for the vast majority of their users.
  
  One lucky thing about the world today is we have a lot of research out there, and we know a lot about the actual science of reading in 2021.
  
  What the research shows is that there is definitely a trade-off between reading speed and reading comprehension. It is unlikely that readers will be able to double or triple their reading speeds while still being able to understand the text as well as if they read at normal speed. 
  
  Still, there are scientifically proven ways for you to become a better reader. We’re building an app based on science, not fads like super speed reading courses or RSVP technology. Reading faster and remembering more of what you read are achievable goals that we know everyone can improve with the right methods.
  
  We want to make powerful and simple reading technology that is accessible to everyone, so after you click the button below and  enter your email address to view your reading test results, you can try using our app for free to immediately start reading better on your computers, smartphones, and all your devices and become a truly better reader…. 
  
  Click ‘I’m Finished’ so we can calculate your reading speed.
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraph: {
      whiteSpace: 'pre-line',
      marginBottom: theme.spacing(1),
      fontSize: 'inherit',
      fontFamily: 'inherit',
    },
    content: {
      filter: 'blur(2px)',
    },
    root: {
      width: '100%',
      padding: 0,
      position: 'relative',
      color: colors.grey800,
    },
  })
);

const FirstStep: FC<Props> = props => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <div className={clsx(props.stopped && classes.content)}>
        <Typography
          component={'p'}
          variant={'body1'}
          className={classes.paragraph}
        >
          {CONTENT_TEST}
        </Typography>
      </div>
    </Box>
  );
};

FirstStep.displayName = 'FirstStep';

export default FirstStep;
