import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px',
      fontSize: '28px',
      lineHeight: '32px',
      color: colors.grey800,
      borderBottom: `1px solid ${colors.greyLight}`,
    },
    root: {
      marginTop: 16,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: '16px',
      maxHeight: 300,
      overflowY: 'scroll',
      padding: '0 1rem',
      rowGap: 16,
    },
    commentActions: {
      display: 'flex',
      flexDirection: 'column',
    },
    deleteIcon: {
      marginTop: 6,
      marginLeft: 10,
      '&:hover': {
        fill: theme.palette.secondary.main,
      },
    },
    editIcon: {
      '&:hover': {
        fill: theme.palette.secondary.main,
      },
    },
    listItem: {
      borderRadius: 8,
      background: theme.palette.grey['200'],
    },
    listItemText: {
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
    },
  })
);
