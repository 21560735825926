import { BookModel } from '@read4speed/models';

import { BookService } from '../../services/BookService';
import { AppThunk } from '../store';
import { libraryActions } from './librarySlice';

export const bookUpdateAction = (
  id: string,
  data: Partial<BookModel>
): AppThunk => async dispatch => {
  const book = await BookService.updateBook(id, data);
  dispatch(libraryActions.acceptBooks([book]));
};
