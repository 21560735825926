import { pick } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';

import { RootState } from '../../rootReducer';
import { diagnosticActions, DiagnosticInitialState } from '../diagnosticSlice';

export type DiagnosticModalState = Pick<DiagnosticInitialState, 'isModalOpen'>;
export type DiagnosticModalActionsReturn<A extends Action> = {
  setModalOpenState: () => A;
};

export const useDiagnosticModal = (): DiagnosticModalState &
  DiagnosticModalActionsReturn<
    ReturnType<typeof diagnosticActions.setModalOpenState>
  > => {
  const dispatch = useDispatch();
  const modalState = useSelector<RootState, DiagnosticModalState>(state => {
    return pick(state.diagnostic, ['isModalOpen']);
  });
  const modalActions = {
    setModalOpenState: () => dispatch(diagnosticActions.setModalOpenState()),
  };

  return {
    ...modalState,
    ...modalActions,
  };
};
