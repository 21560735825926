import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { readNextPageAction } from '../../redux/commonActions';
import { readerActions } from '../../redux/reader';
import {
  useReaderPlaybackState,
  useReaderPosition,
} from '../../redux/reader/selectors';
import { updateReadingHistoryItem } from '../../redux/readingHistory';
import { useTotalWeight, useWords } from '../../redux/text/selectors';
import { TextParserService } from '../../services/TextParserService';

export const usePlayback = (): void => {
  const dispatch = useDispatch();

  const { playing, paused, speed } = useReaderPlaybackState();

  const words = useWords();
  const totalWeight = useTotalWeight();
  const position = useReaderPosition();

  const delayFactor = useMemo(
    () => TextParserService.speedToDelayFactor(words, totalWeight, speed),
    [words.length, speed, totalWeight]
  );

  const tidRef = useRef<number>();

  // that all needed for auto-adjusting latency
  const lastTSRef = useRef<number | null>(null);
  const lastDelayRef = useRef<number>(0);
  const latencyRef = useRef<number>(0);

  const clearPlaybackTimeout = useCallback(() => {
    if (!tidRef.current) return;
    clearTimeout(tidRef.current);
    tidRef.current = undefined;
  }, []);

  useEffect(() => {
    clearPlaybackTimeout();

    if (paused) {
      lastTSRef.current = null;
      lastDelayRef.current = 0;
      latencyRef.current = 0;
    }

    if (playing) {
      // end of text. Stop and go to next page
      if (!words[position]) {
        dispatch(readNextPageAction());
        if (location.pathname !== '/challenge') {
          dispatch(updateReadingHistoryItem({ isFinished: true }));
        }
        return;
      }

      const delay = TextParserService.calcDelayMS(
        words[position].weight,
        delayFactor
      );

      // calc real latency on the latest position change
      const realLastLatency =
        lastTSRef.current === null
          ? 10
          : Date.now() - lastTSRef.current - lastDelayRef.current;

      lastTSRef.current = Date.now();

      // adjust latency
      latencyRef.current =
        delay < 25
          ? // delay was too small to use it for adjustment
            latencyRef.current
          : latencyRef.current + realLastLatency;

      lastDelayRef.current = delay;

      // eslint-disable-next-line no-console
      console.debug(`Real latency: ${realLastLatency}`);
      // eslint-disable-next-line no-console
      console.debug(`Applied latency correction: ${latencyRef.current}`);

      const timeout = delay - latencyRef.current;

      tidRef.current = (setTimeout(
        () => dispatch(readerActions.setPosition(position + 1)),
        timeout <= 0 ? 0 : timeout
      ) as unknown) as number;
    }

    return () => clearPlaybackTimeout();
  }, [playing, position, words.length, delayFactor]);
};
