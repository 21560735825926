import { ParagraphModel } from '@read4speed/models';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';

export const useParagraphs = (): ParagraphModel[] => {
  return useSelector<RootState, ParagraphModel[]>(
    state => state.text.paragraphs
  );
};
