import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { preloadNextBookPagesAction } from '../../redux/book';
import { useBookInfo, useBookPagesAfter } from '../../redux/book/selectors';
import { useTextPageNumber } from '../../redux/text/selectors';
import { TextReaderPagePreview } from './TextReaderPagePreview';

interface TextReaderLowerPagesProps {}

export const TextReaderLowerPages: FC<TextReaderLowerPagesProps> = () => {
  const dispatch = useDispatch();
  const pageNumber = useTextPageNumber();
  const pagesAfter = useBookPagesAfter(pageNumber);
  const { pagesCount, id } = useBookInfo();

  useEffect(() => {
    if (pagesAfter.length >= 2) return;
    if (pageNumber === 0) return;
    if (pagesAfter.length + pageNumber - 2 >= pagesCount) return;
    if (!id) return;
    dispatch(preloadNextBookPagesAction(id, pageNumber, 2));
  }, [pageNumber, pagesAfter.length, pagesCount, id]);

  return (
    <div>
      {pagesAfter.map(page => (
        <TextReaderPagePreview page={page} key={page.id} />
      ))}
    </div>
  );
};
