import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserProfileModel } from '@read4speed/models';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from 'src/components/Icons/close';
import { uiActions } from 'src/redux/ui';

import { useSavedWordsListOpened } from '../../redux/ui/selectors/useSavedWordsListOpened';
import { updateSavedWords } from '../../redux/userProfiles';
import { CustomDrawer } from '../../styles/customDrawer';
import { useStyles } from './styles/savedWordsList';

interface UserSettingsProps {
  profile: UserProfileModel;
}

export const SavedWordsList: FC<UserSettingsProps> = ({ profile }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isVisible = useSavedWordsListOpened();
  const { savedWords, id } = profile;

  const handleDeleteWord = (word: string): void => {
    if (id && savedWords?.length) {
      const wordsToSave = savedWords.filter(
        w => w.toLowerCase() !== word.toLowerCase()
      );
      dispatch(updateSavedWords(id, wordsToSave));
    }
  };

  const close = useCallback(() => {
    dispatch(uiActions.closeSavedWords());
  }, []);

  return (
    <CustomDrawer
      container={document.body}
      anchor="bottom"
      open={isVisible}
      onClose={close}
      onOpen={() => ({})}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box className={classes.header}>
        <span>Saved Words</span>
        <IconButton aria-label="exit" onClick={close}>
          <CloseIcon color="black" />
        </IconButton>
      </Box>

      <List className={classes.root}>
        {savedWords && savedWords?.length ? (
          savedWords.map((word, idx) => {
            return (
              <ListItem
                key={idx}
                alignItems="flex-start"
                className={classes.listItem}
              >
                <ListItemText
                  primary={word}
                  primaryTypographyProps={{
                    className: classes.listItemText,
                  }}
                />
                <div className={classes.commentActions}>
                  <IconButton
                    aria-label="settings"
                    onClick={() => handleDeleteWord(word)}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </div>
              </ListItem>
            );
          })
        ) : (
          <Typography color="textSecondary">No saved words yet 😔</Typography>
        )}
      </List>
    </CustomDrawer>
  );
};
