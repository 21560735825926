import {
  ParagraphModel,
  ReadingHistoryItemType,
  WordModel,
} from '@read4speed/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TextParseResults } from '../../services/TextParserService';

export interface GeneralState {
  textType: ReadingHistoryItemType;
  thumbSrc?: string;
  sourceURL?: string | null;
  busy: boolean;
  pageNumber: number;
  text: string;
  textId: string | null;
  loaded: boolean;
  parsed: boolean;
  totalWeight: number;
  totalParagraphsCount: number;
  totalCharsCount: number;
  totalWordsCount: number;
}

export interface WordsState {
  words: WordModel[];
}

export interface ParagraphsState {
  paragraphs: ParagraphModel[];
}

export type TextState = GeneralState & WordsState & ParagraphsState;

const initialState: TextState = {
  pageNumber: 0,
  paragraphs: [],
  loaded: false,
  busy: false,
  parsed: false,
  text: '',
  textType: 'TEXT',
  textId: null,
  totalParagraphsCount: 0,
  totalCharsCount: 0,
  totalWeight: 0,
  totalWordsCount: 0,
  words: [],
};

const slice = createSlice({
  name: 'text',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },

    setBusy(state, action: PayloadAction<boolean>) {
      state.busy = action.payload;
    },

    acceptText(state, action: PayloadAction<string>) {
      state.text = action.payload;
      state.loaded = true;

      // reset parsed stuff
      state.textType = 'TEXT';
      state.parsed = false;
      state.words = [];
      state.paragraphs = [];
      state.totalCharsCount = 0;
      state.totalWordsCount = 0;
      state.totalWeight = 0;
      state.totalParagraphsCount = 0;
    },
    setTextId(state, action: PayloadAction<string | null>) {
      state.textId = action.payload;
    },
    setTextType(state, action: PayloadAction<ReadingHistoryItemType>) {
      state.textType = action.payload;
    },
    setTextThumbSrc(state, action: PayloadAction<string | undefined>) {
      state.thumbSrc = action.payload;
    },
    setTextSourceURL(state, action: PayloadAction<string | undefined | null>) {
      state.sourceURL = action.payload;
    },
    setPageNumber(state, action: PayloadAction<number>) {
      state.pageNumber = action.payload;
    },
    acceptParseResults(state, action: PayloadAction<TextParseResults>) {
      const {
        words,
        paragraphs,
        paragraphsCount,
        wordsCount,
        totalLength,
        totalWeight,
      } = action.payload;

      state.words = words;
      state.paragraphs = paragraphs;
      state.totalCharsCount = totalLength;
      state.totalWordsCount = wordsCount;
      state.totalWeight = totalWeight;
      state.totalParagraphsCount = paragraphsCount;

      state.parsed = true;
    },
  },
});

export const { reducer: textReducer, actions: textActions } = slice;
