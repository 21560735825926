import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ReaderCursorState {
  position: number;
}

export interface ReaderPlaybackState {
  playing: boolean;
  paused: boolean;
  speed: number;
}

export interface ReaderProgressState {
  elapsedTime: number;
}

export interface ReaderSpeechState {
  speechActive: boolean;
  speechPlaying: boolean;
  speechSpeed: number;
}

export interface ReaderTitleState {
  currentTitle: string;
  isFinished: boolean;
}

type ReaderState = ReaderCursorState &
  ReaderPlaybackState &
  ReaderProgressState &
  ReaderSpeechState &
  ReaderTitleState;

const initialState: ReaderState = {
  elapsedTime: 0,
  paused: false,
  playing: false,
  position: -1,
  speed: 300,
  speechActive: false,
  speechPlaying: false,
  speechSpeed: 1,
  currentTitle: '',
  isFinished: false,
};

const slice = createSlice({
  name: 'reader',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },

    setCurrentTitle(state, action: PayloadAction<string>) {
      state.currentTitle = action.payload;
    },

    setIsFinished(state, action: PayloadAction<boolean>) {
      state.isFinished = action.payload;
    },

    setSpeed(state, action: PayloadAction<number>) {
      state.speed = action.payload;
    },

    setPosition(state, action: PayloadAction<number>) {
      state.position = action.payload;
    },

    setNextPosition(state) {
      state.position = state.position + 1;
    },

    playSpeech(state) {
      state.speechPlaying = true;
    },

    pauseSpeech(state) {
      state.speechPlaying = false;
    },

    setSpeechActive(state, action: PayloadAction<boolean>) {
      state.speechActive = action.payload;
    },

    setSpeechSpeed(state, action: PayloadAction<number>) {
      state.speechSpeed = Math.round(action.payload * 10) / 10;
    },

    play(state) {
      state.playing = true;
      state.paused = false;
    },

    pause(state) {
      state.playing = false;
      state.paused = true;
    },
  },
});

export const { reducer: readerReducer, actions: readerActions } = slice;
