import { ReadingHistoryItemModel } from '@read4speed/models';

import { BookService } from '../../services/BookService';
import { TextDataService } from '../../services/TextDataService';
import { bookSlice } from '../book';
import { readerActions } from '../reader';
import { AppThunk } from '../store';
import { textActions } from '../text';

export const continueReadingAction = (
  historyItem: Pick<
    ReadingHistoryItemModel,
    | 'type'
    | 'pageNumber'
    | 'targetId'
    | 'wordIndex'
    | 'lastSpeed'
    | 'lastSpeechSpeed'
    | 'thumbSrc'
    | 'previewText'
    | 'isFinished'
  >
): AppThunk => async dispatch => {
  const {
    type,
    pageNumber,
    targetId,
    wordIndex,
    lastSpeed,
    lastSpeechSpeed,
    thumbSrc,
    isFinished,
    previewText,
  } = historyItem;

  if (type === 'TEXT' || type === 'ARTICLE') {
    const textModel = await TextDataService.readFromFirestoreById(targetId);

    if (!textModel || !textModel.id) {
      return;
    }

    TextDataService.setLSText(textModel.text);

    dispatch(textActions.reset());
    dispatch(textActions.acceptText(textModel.text));
    dispatch(textActions.setTextId(textModel.id));
    dispatch(textActions.setTextType(type));
    dispatch(textActions.setTextThumbSrc(thumbSrc));
    dispatch(textActions.setTextSourceURL(textModel?.url));
    dispatch(readerActions.setPosition(wordIndex));
    dispatch(readerActions.setCurrentTitle(previewText));
    dispatch(readerActions.setSpeed(lastSpeed));
    dispatch(readerActions.setSpeechSpeed(lastSpeechSpeed || 1));
    dispatch(readerActions.setIsFinished(isFinished));
  }

  if (type === 'BOOK' && pageNumber !== undefined && pageNumber !== null) {
    // now targetId means bookId :)
    const book = await BookService.getBook(targetId);
    const page = await BookService.getBookPageByNumber(targetId, pageNumber);

    if (!page) throw new Error(`something wrong: can't load the page`);

    TextDataService.setLSText(page.text);

    dispatch(
      bookSlice.actions.setBookInfo({
        title: book.title,
        bookId: book.id as string,
        pagesCount: book.pagesCount,
      })
    );

    dispatch(bookSlice.actions.acceptPages([page]));
    dispatch(textActions.reset());
    dispatch(textActions.acceptText(page.text));
    dispatch(textActions.setTextId(targetId));
    dispatch(textActions.setTextType(type));
    dispatch(textActions.setPageNumber(pageNumber));
    dispatch(readerActions.setPosition(wordIndex));
    dispatch(readerActions.setSpeed(lastSpeed));
    dispatch(textActions.setTextThumbSrc(''));
    dispatch(readerActions.setSpeechSpeed(lastSpeechSpeed || 1));
  }
};
