import { getFirebase, getPersistence } from '../../firebase';
import { AppThunk } from '../store';
import { authActions } from './authSlice';

export const signInWithEmailAndPasswordAction = (
  email: string,
  password: string,
  isPersist: boolean
): AppThunk => async dispatch => {
  try {
    const firebase = getFirebase();
    const persistence = getPersistence();
    const auth = firebase.auth();
    await auth.setPersistence(
      isPersist ? persistence.LOCAL : persistence.SESSION
    );
    await auth.signInWithEmailAndPassword(email, password);
  } catch ({ code }) {
    const error = {
      type: 'all',
      message: 'Something went wrong. Please try again.',
    };

    if (code === 'auth/user-not-found') {
      error.message = 'User with this email is not found.';
      error.type = 'email';
    }
    if (code === 'auth/user-disabled') {
      error.message = 'User with this email has been disabled.';
      error.type = 'email';
    }
    if (code === 'auth/invalid-email') {
      error.message = 'The email address is invalid.';
      error.type = 'email';
    }
    if (code === 'auth/wrong-password') {
      error.message = 'The password is invalid.';
      error.type = 'password';
    }

    dispatch(authActions.setError(error));
  }
};
