import clsx from 'clsx';
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { colors } from 'src/styles/colors';

import { useReaderPlaybackState } from '../../redux/reader/selectors';
import { useWords } from '../../redux/text/selectors';
import { useAuthorizedUserProfile } from '../../redux/userProfiles/selectors';
import { SCROLL_TYPE } from '../ReaderSettings/ReaderSettingsScrollType';
import { useStyles } from './styles';

const cursorColor = colors.cursorColor;

interface TextCursorProps {
  position: number;
  readerContainerRef: MutableRefObject<HTMLElement | null>;
}

const getScrollCorrelation = (
  readerContainer: HTMLElement | null,
  elementPosition: number
): number => {
  const node = readerContainer?.querySelector<HTMLSpanElement>(
    `[data-word-index='${elementPosition}']`
  );
  const rect = node?.getBoundingClientRect();
  const clientHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const heightOffset = clientHeight / 2 - 300;
  if (rect) {
    return rect.top < heightOffset ? 1000 : -2000;
  }
  return 0;
};

export const TextCursor: FC<TextCursorProps> = ({
  position,
  readerContainerRef,
}) => {
  const [lineWidthOptions, setLineWidthOptions] = useState<
    ReturnType<typeof getElementLocation>
  >();
  const [, setIsSmoothScrolling] = useState(false);
  const { playing } = useReaderPlaybackState();
  const profile = useAuthorizedUserProfile();
  const words = useWords();
  const highlight = (profile && profile.highlight) || ['Word'];
  const isWordMode = highlight.indexOf('Word') >= 0;
  const isLineMode = highlight.indexOf('Line') >= 0;
  const isParagraphMode = highlight.indexOf('Paragraph') >= 0;
  const underlineHighlight = (profile && profile.underlineHighlight) || [];
  const fixationPoints = (profile && profile.fixationPoints) || [];
  const isUnderlineWordMode = underlineHighlight.indexOf('UnderlineWord') >= 0;
  const isUnderlineLineMode = underlineHighlight.indexOf('UnderlineLine') >= 0;
  const isFixationPoints = fixationPoints.indexOf('Three') >= 0;
  const backgroundColor = profile?.cursorColor || cursorColor;

  const { top, left, width, height, pTop, pHeight } = getElementLocation(
    readerContainerRef.current,
    position
  );

  useLayoutEffect(() => {
    // check for 'width' to execute code after UI is rendered
    if (width) {
      let nextPosition = position + 1;
      let next = getElementLocation(readerContainerRef.current, nextPosition);
      let nextWord = words[nextPosition];
      while (next.left - left > 0) {
        if (nextWord.isFirst)
          setLineWidthOptions(
            getElementLocation(readerContainerRef.current, nextPosition - 1)
          );
        nextPosition++;
        next = getElementLocation(readerContainerRef.current, nextPosition);
        nextWord = words[nextPosition];
      }
      setLineWidthOptions(
        getElementLocation(readerContainerRef.current, nextPosition - 1)
      );
    }
  }, [position, width]);

  const classes = useStyles({
    lineWidthOptions,
    top,
    left,
    width,
    height,
    pTop,
    pHeight,
    backgroundColor,
    isLineMode,
    isWordMode,
    isUnderlineLineMode,
    isUnderlineWordMode,
    isParagraphMode,
    isFixationPoints,
  });

  useEffect(() => {
    const { scrollTop } = readerContainerRef.current?.parentElement || {
      scrollTop: 0,
    };

    // eslint-disable-next-line max-len
    const scrollTopOffset = 6 * height; // offset of 6 (starts from 2 lines above the reader view + 4 lines down from the first visible line) lines above with height according to the current font-size

    if (profile?.scrollType === SCROLL_TYPE.JUMP) {
      if (scrollTop - top < top - scrollTopOffset) {
        scroll.scrollTo(
          top - scrollTopOffset + (readerContainerRef?.current?.offsetTop || 0),
          {
            containerId: 'reader-scroll-container',
            duration: 300,
          }
        );
      }
    }

    if (profile?.scrollType === SCROLL_TYPE.SMOOTH && playing) {
      const scrollCorrelation = getScrollCorrelation(
        readerContainerRef.current,
        position
      );
      if (scrollTop - top < top - scrollTopOffset) {
        scroll.scrollTo(
          top - scrollTopOffset + (readerContainerRef?.current?.offsetTop || 0),
          {
            containerId: 'reader-scroll-container',
            duration: 2000 + scrollCorrelation,
          }
        );
      }
    }

    if (!playing) {
      setIsSmoothScrolling(false);
      setTimeout(() => {
        if (readerContainerRef?.current?.offsetTop) {
          scroll.scrollTo(
            top - scrollTopOffset + readerContainerRef.current.offsetTop,
            {
              containerId: 'reader-scroll-container',
              smooth: 'linear',
              duration: 300,
            }
          );
        }
      }, 500);
    }
  }, [top, playing]);

  useEffect(() => {
    // eslint-disable-next-line max-len
    const scrollTopOffset = 6 * height; // offset of 6 (starts from 2 lines above the reader view + 4 lines down from the first visible line) lines above with height according to the current font-size
    scroll.scrollTo(
      top - scrollTopOffset + (readerContainerRef?.current?.offsetTop || 0),
      {
        containerId: 'reader-scroll-container',
      }
    );
  }, [readerContainerRef.current]);

  return (
    <>
      {isLineMode ? (
        <div className={clsx(classes.root, classes.lineMode)} />
      ) : null}
      {isWordMode ? (
        <div className={clsx(classes.root, classes.wordMode)} />
      ) : null}
      {isParagraphMode ? (
        <div className={clsx(classes.root, classes.paragraphMode)} />
      ) : null}
      {isUnderlineWordMode ? (
        <div className={clsx(classes.root, classes.underlineWordMode)} />
      ) : null}
      {isUnderlineLineMode ? (
        <div className={clsx(classes.root, classes.underlineLineMode)} />
      ) : null}
      {isFixationPoints ? (
        <div className={clsx(classes.root, classes.fixationPoints)} />
      ) : null}
    </>
  );
};

function getElementLocation(
  readerContainer: HTMLElement | null,
  position: number
): {
  top: number;
  left: number;
  width: number;
  height: number;
  pTop: number;
  pHeight: number;
} {
  const initValue = {
    width: 0,
    left: 0,
    top: 0,
    height: 0,
    pTop: 0,
    pHeight: 0,
  };

  if (!readerContainer) return initValue;

  const node = readerContainer.querySelector<HTMLSpanElement>(
    `[data-word-index='${position}']`
  );

  if (!node) return initValue;

  const {
    clientWidth = 0,
    offsetLeft = 0,
    offsetTop = 0,
    clientHeight = 0,
  } = node;

  const {
    offsetTop: parentTop = 0,
    clientHeight: parentHeight = 0,
  } = node.parentNode as HTMLElement;

  return {
    width: clientWidth,
    left: offsetLeft,
    top: offsetTop,
    pTop: parentTop,
    height: clientHeight,
    pHeight: parentHeight,
  };
}

// function isElementInViewport(
//   readerContainer: HTMLElement | null,
//   elementPosition: number
// ): boolean {
//   const node = readerContainer?.querySelector<HTMLSpanElement>(
//     `[data-word-index='${elementPosition}']`
//   );
//   const rect = node?.getBoundingClientRect();
//   if (rect) {
//     return (
//       rect.top + 100 >= 0 &&
//       rect.bottom - 100 <=
//         (window.innerHeight || document.documentElement.clientHeight)
//     );
//   }
//   return false;
// }
