import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      color: theme.reader.text,
    },
    finish: {
      padding: '40px 0',
      margin: '40px -30px 0',
      [theme.breakpoints.up('sm')]: {
        margin: '48px -64px 0',
        padding: '48px 0',
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderTop: `1px solid ${colors.grey200}`,
      '& > span': {
        fontSize: '24px',
        lineHeight: '120%',
        color: colors.grey800,
        marginTop: '8px',
      },
    },
    finishBtns: {
      '& button': {
        height: '40px',
        margin: '0 6px',
        '& span': {
          fontSize: '16px',
        },
      },
    },
    result: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '24px',
      fontSize: '14px',
      lineHeight: '120%',
      color: colors.grey400,
      '& > span:first-of-type': {
        lineHeight: '120%',
        color: colors.greyBase,
        fontSize: '20px',
        marginBottom: '4px',
      },
    },
  })
);
