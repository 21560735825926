import { Action, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import queryString from 'query-string';

const queryParams = queryString.parse(location?.search);

const CONTENT_WORDS_COUNT = 779;

const initialState = {
  isModalOpen: queryParams?.challenge === null,
  paused: false,
  playing: false,
  stopped: true,
  startTime: moment(),
  endTime: moment(),
  readingTime: '00:00',
  WPM: 0,
};

export type DiagnosticInitialState = typeof initialState;
export type DiagnosticPlaybackState = Pick<
  DiagnosticInitialState,
  'stopped' | 'playing' | 'paused'
>;
export type DiagnosticReadStats = Pick<
  DiagnosticInitialState,
  'startTime' | 'endTime' | 'readingTime' | 'WPM'
>;

const slice = createSlice({
  name: 'diagnostic',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },

    stop(
      state,
      {
        payload = state,
      }: { payload: Partial<DiagnosticInitialState> | undefined } & Action
    ) {
      Object.assign(state, payload);
      state.playing = false;
      state.paused = false;
      state.stopped = true;
      state.endTime = moment();
    },

    play(state) {
      state.playing = true;
      state.paused = false;
      state.stopped = false;
      state.startTime = moment();
      state.WPM = 0;
    },

    pause(state) {
      state.playing = false;
      state.paused = true;
      state.stopped = false;
    },

    calculateWPM(state) {
      const { startTime, endTime } = state;
      const readDuration = moment.duration(endTime.diff(startTime));
      const readMinutes = readDuration.minutes();
      const readSeconds = readDuration.seconds();
      state.WPM = (CONTENT_WORDS_COUNT / (readMinutes * 60 + readSeconds)) * 60;
    },

    setModalOpenState(state) {
      state.isModalOpen = !state.isModalOpen;
    },
  },
});

export const { reducer: diagnosticReducer, actions: diagnosticActions } = slice;
