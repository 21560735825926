import { ReadingHistoryItemType } from '@read4speed/models/src';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { TextDataService } from '../../services/TextDataService';
import { readerActions } from '../reader';
import { textActions } from '../text';

interface Options {
  text: string;
  thumbSrc?: string;
  type?: ReadingHistoryItemType;
}

export const saveTextAction = createAsyncThunk<void, Options>(
  'common/saveText',
  async ({ text, thumbSrc = '', type = 'TEXT' }, { dispatch }) => {
    const textId = await TextDataService.saveText(text);

    if (!textId) throw new Error('text id is empty');

    dispatch(textActions.acceptText(text));
    dispatch(textActions.setTextType(type));
    dispatch(textActions.setTextId(textId));
    dispatch(textActions.setTextThumbSrc(thumbSrc));
    dispatch(readerActions.setPosition(-1));
  }
);
