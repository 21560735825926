import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const CustomMenu = withStyles({
  paper: {
    minWidth: '140px',
    overflow: 'visible',
    boxShadow: '0 0 6px rgba(0,0,0,.05)',
  },
  list: {
    padding: 0,
    background: colors.grey50,
    border: `1px solid ${colors.grey5}`,
    borderRadius: '10px',
    overflow: 'hidden',
    '& li': {
      display: 'flex',
      alignItems: 'center',
      padding: '12px',
      color: colors.grey8,
      fontSize: '14px',
      lineHeight: '16px',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${colors.grey5}`,
      },
      '& svg': {
        marginRight: '12px',
      },
      '&:hover, &:focus': {
        color: colors.orange600,
        backgroundColor: 'white',
      },
    },
  },
})(Menu);
