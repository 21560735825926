export const colors = {
  greyBase: '#71717A',
  greyLight: '#E0E0E0',
  grey50: '#FAFAFA',
  grey100: '#F3F4F6',
  grey200: '#E4E4E7',
  grey300: '#D4D4D8',
  grey400: '#A1A1AA',
  grey600: '#52525B',
  grey700: '#3F3F46',
  grey800: '#27272A',
  grey69: '#3F3F48',
  grey1: '#F4F4F5',
  grey5: '#D9D9D9',
  grey6: '#57534E',
  grey8: '#595959',
  red1: '#E85A24',
  red2: '#DC2626',
  red3: '#EA4335',
  lime50: '#F7FEE7',
  lime100: '#ECFCCB',
  lime500: '#84CC16',
  lime600: '#65A30D',
  lime700: '#4D7C0F',
  lime800: '#3F6212',
  lime900: '#365314',
  orange50: '#FFF7ED',
  orange100: '#FFEDD5',
  orange400: '#FB923C',
  orange500: '#F97316',
  orange600: '#EA580C',
  orange700: '#C2410C',
  orange800: '#9A3412',
  orang900: '#7C2D12',
  cursorColor: '#EA580B',
};
