import {
  firestoreToPlainObject,
  firestoreToPlainObjectMap,
} from '@read4speed/firebase-utils';
import {
  ReadingHistoryItemModel,
  ReadingHistoryItemType,
} from '@read4speed/models';
import { ParagraphModel } from '@read4speed/models/src';

import { collections, firestoreTimeStampNow } from '../firebase';
import { BookService } from './BookService';

export interface CreateReadingHistoryItemData {
  speed: number;
  speechSpeed: number;
  targetId: string;
  position: number;
  type: ReadingHistoryItemType;
  text: string;
  pageNumber?: number;
  thumbSrc?: string;
  previewText?: string;
  firstParagraph: ParagraphModel;
  totalWordsCount: number;
  isFinished?: boolean;
}

export interface SaveCurrentTextData {
  targetId: string;
  text: string;
  speed: number;
  speechSpeed: number;
  position: number;
  type: ReadingHistoryItemType;
  pageNumber?: number;
  previewText?: string;
  thumbSrc?: string;
  firstParagraph: ParagraphModel;
  totalWordsCount: number;
  isFinished?: boolean;
}

export interface PreviewText
  extends Pick<ParagraphModel, 'start' | 'charsCount' | 'end'> {
  author?: string;
}

export type UpdateHistoryItemData = Partial<ReadingHistoryItemModel>;

export class ReadingHistoryService {
  static async loadHistory(uid: string): Promise<ReadingHistoryItemModel[]> {
    const snapshot = await collections
      .readingHistoryItemsRef()
      .where('uid', '==', uid)
      .orderBy('lastReadDate', 'desc')
      .limit(30)
      .get();

    return firestoreToPlainObjectMap(snapshot.docs);
  }

  static async saveCurrentText(
    uid: string,
    data: SaveCurrentTextData
  ): Promise<ReadingHistoryItemModel> {
    const {
      targetId,
      position,
      speed,
      speechSpeed,
      text,
      type,
      pageNumber,
      thumbSrc = '',
      firstParagraph,
      totalWordsCount,
      previewText,
    } = data;

    const querySnapshot = await collections
      .readingHistoryItemsRef()
      .where('uid', '==', uid)
      .where('targetId', '==', targetId)
      .where('type', '==', type)
      .get();

    if (querySnapshot.size === 0) {
      return this.createItem(uid, {
        position,
        speed,
        speechSpeed,
        targetId,
        text,
        type,
        pageNumber,
        thumbSrc,
        firstParagraph,
        totalWordsCount,
        previewText:
          previewText ||
          this.previewText(text, {
            ...firstParagraph,
          }),
      });
    }

    return this.updateItem(querySnapshot.docs[0].id, {
      wordIndex: position,
      lastSpeed: speed,
      lastSpeechSpeed: speechSpeed,
      totalWordsCount,
      pageNumber,
      previewText:
        previewText ||
        this.previewText(text, {
          ...firstParagraph,
        }),
      thumbSrc,
    });
  }

  static async updateItem(
    id: string,
    data: UpdateHistoryItemData
  ): Promise<ReadingHistoryItemModel> {
    await collections
      .readingHistoryItemsRef()
      .doc(id)
      .update({
        ...data,
        lastReadDate: firestoreTimeStampNow(),
      });

    const updated = await this.getById(id);

    if (!updated) {
      throw new Error(`unexpected empty ReadingHistoryItemModel ${id}`);
    }

    return updated;
  }

  static async createItem(
    uid: string,
    {
      position,
      speed,
      speechSpeed,
      targetId,
      type,
      pageNumber = 0,
      thumbSrc = '',
      totalWordsCount,
      previewText = '',
    }: CreateReadingHistoryItemData
  ): Promise<ReadingHistoryItemModel> {
    const bookInfo = type === 'BOOK' ? await this.getBookInfo(targetId) : null;

    const reference = await collections.readingHistoryItemsRef().add({
      uid,
      type,
      targetId,
      pageNumber,
      totalWordsCount,
      previewText,
      lastSpeed: speed,
      lastSpeechSpeed: speechSpeed,
      timestamp: firestoreTimeStampNow(),
      lastReadDate: firestoreTimeStampNow(),
      isFinished: false,
      remainingReadingTime: '',
      wordIndex: position,
      thumbSrc,
      ...(bookInfo || {}),
    });

    const snapshot = await reference.get();

    return firestoreToPlainObject(snapshot);
  }

  static async getLatestItem(
    uid: string
  ): Promise<ReadingHistoryItemModel | null> {
    const snapshot = await collections
      .readingHistoryItemsRef()
      .where('uid', '==', uid)
      .orderBy('lastReadDate', 'desc')
      .limit(1)
      .get();

    if (snapshot.size === 0) return null;

    return firestoreToPlainObject(snapshot.docs[0]);
  }

  static async getById(id: string): Promise<ReadingHistoryItemModel | null> {
    const snapshot = await collections.readingHistoryItemsRef().doc(id).get();
    if (!snapshot.exists) return null;
    return firestoreToPlainObject(snapshot);
  }

  static async getSnapshotByUId(
    uid: string,
    targetId: string,
    type: ReadingHistoryItemType
  ): Promise<FirebaseFirestore.QuerySnapshot<ReadingHistoryItemModel>> {
    return await collections
      .readingHistoryItemsRef()
      .where('uid', '==', uid)
      .where('targetId', '==', targetId)
      .where('type', '==', type)
      .get();
  }

  private static previewText(
    text: string,
    { start, charsCount, end, author }: PreviewText = {
      start: 0,
      charsCount: 140,
      end: 0,
      author: '',
    }
  ): string {
    const articleTitle = `${text.substr(start, charsCount + end)} ${
      length >= 140 ? '...' : ''
    }`.trim();
    return author ? `${articleTitle} - ${author}` : articleTitle;
  }

  private static async getBookInfo(
    bookId: string
  ): Promise<{ author: string; title: string } | null> {
    const book = await BookService.getBook(bookId);
    if (!book) return null;
    return {
      author: book.author,
      title: book.title,
    };
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.service = ReadingHistoryService;
