import clsx from 'clsx';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import ArrowLeftIcon from '../../components/Icons/arrowLeft';
import LoginIllustration from '../../components/Icons/loginIllustration';
import BigLogoIcon from '../../components/Icons/logoBig';
import { authActions } from '../../redux/auth';
import {
  useAuthenticated,
  usePasswordResetEmail,
} from '../../redux/auth/selectors';
import { SubmitButton } from '../../styles/submitButton';
import { useStyles } from '../Signin/styles/signinPage';
import { ResetPasswordForm } from './ResetPasswordForm';

interface ResetPasswordProps {}

export const PasswordResetPage: FC<ResetPasswordProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useAuthenticated();
  const email = usePasswordResetEmail();
  const classes = useStyles();

  useEffect(() => {
    return () => {
      dispatch(authActions.setEmail(null));
    };
  }, []);

  const goSignin = useCallback(() => {
    history.push(APP_ROUTES_CONFIG.SIGNIN);
  }, []);

  if (isAuthenticated) {
    history.push(APP_ROUTES_CONFIG.HOME);
  }

  return (
    <main className={classes.root}>
      {!email ? (
        <a className={classes.backBtn} onClick={goSignin}>
          <ArrowLeftIcon />
          <span>Back to Sign In</span>
        </a>
      ) : null}
      <div className={classes.side}>
        <BigLogoIcon />
        <h2 className="desktop">Read Faster, Remember More</h2>
        <p className="desktop">
          Science-backed tools to make you a better reader on all your devices
        </p>
        <div className="desktop">
          <LoginIllustration />
        </div>
      </div>
      <div className={clsx('forgot-password', classes.main)}>
        {email ? (
          <>
            <h1>Recovery Email Sent</h1>
            <p>
              Check your email {email} for further instructions to reset your
              password
            </p>
            <SubmitButton type="submit" variant="contained" onClick={goSignin}>
              Go to Sign In
            </SubmitButton>
          </>
        ) : (
          <>
            <h1>Forgot Password</h1>
            <p>
              We will send you an email with futher instructions to reset your
              password.
            </p>
            <ResetPasswordForm />
          </>
        )}
      </div>
    </main>
  );
};
