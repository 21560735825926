import Box from '@material-ui/core/Box/Box';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { BookImportResult } from '@read4speed/models';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { bookDeleteAction, bookUpdateAction } from '../../redux/library';
import { BookContentPreview } from './BookContentPreview';
import { BookForm, BookFormFields } from './BookForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    preview: {
      flex: 'auto',
      overflowY: 'scroll',
    },
  })
);

interface BookImportConfirmProps {
  importResult: BookImportResult;
  onDone: (success: boolean) => void;
}

export const BookImportConfirm: FC<BookImportConfirmProps> = ({
  importResult,
  onDone,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onSave = useCallback((data: BookFormFields) => {
    dispatch(bookUpdateAction(importResult.bookId, data));
    onDone(true);
  }, []);

  const onCancel = useCallback(() => {
    dispatch(bookDeleteAction(importResult.bookId));
    onDone(false);
  }, []);

  return (
    <Box className={classes.root}>
      <Typography align={'center'}>Review & Edit Book Details</Typography>

      <Divider className={classes.divider} />

      <BookForm
        data={importResult.parsedBook}
        onSave={onSave}
        onCancel={onCancel}
      />

      <Divider className={classes.divider} />

      <BookContentPreview importResult={importResult} />
    </Box>
  );
};
