import { TextAlignEnum } from '@read4speed/models';

import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateTextAlignAction = (
  id: string | undefined,
  textAlign: TextAlignEnum
): AppThunk => async dispatch => {
  if (id) {
    dispatch(
      userProfilesActions.update({
        id,
        data: { textAlign },
      })
    );

    await UserProfilesService.updateTextAlign(id, textAlign);
  }
};
