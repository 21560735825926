import { UserModel } from '@read4speed/models';
import { addDays } from 'date-fns';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ensureAuthorizedUserProfileAction } from 'src/redux/userProfiles';

import { getFirebase } from '../../firebase';
import { useAbsenceEmailsUpdate } from '../../hooks';
import { authActions } from '../../redux/auth';

interface AuthStateListenerProps {}

const timeoutConfigs = [
  {
    after: 3,
    data: {
      time: '3 days',
    },
  },
  {
    after: 7,
    data: {
      time: '7 days',
    },
  },
  {
    after: 14,
    data: {
      time: '14 days',
    },
  },
  {
    after: 30,
    data: {
      time: '30 days',
    },
  },
];

export const AuthStateListener: FC<AuthStateListenerProps> = () => {
  const dispatch = useDispatch();
  useAbsenceEmailsUpdate({
    timeoutConfigs,
    dateCallback: addDays,
    emailsType: 'absenceEmails',
    templateId: 'd-a2afa58d30834c5399167e898419aa08',
  });

  useEffect(() => {
    getFirebase()
      .auth()
      .onAuthStateChanged(user => {
        if (user && user.uid && user.email) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          chrome?.runtime?.sendMessage?.('pbjbkpedldhjckdppkdibigleakofdhg', {
            data: user.uid,
            command: 'easyreader-event-auth',
          });
          dispatch(
            authActions.setAuthenticated({
              isAuthenticated: true,
              user: user.toJSON() as UserModel,
            })
          );

          dispatch(
            ensureAuthorizedUserProfileAction(user.uid, {
              email: user.email,
              isEmailVerified: user?.emailVerified || false,
            })
          );
        } else dispatch(authActions.setLoadedState(true));
      });
  }, []);

  return null;
};
