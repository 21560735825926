import { Color } from '@material-ui/lab/Alert';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';

const queryParams = queryString.parse(location?.search);

export interface AppMenuState {
  opened: boolean;
  userSettingsOpened: boolean;
  savedWordsOpened: boolean;
  savedQuotesOpened: boolean;
  readerSettingsOpened: boolean;
}

export interface SnackbarState {
  opened: boolean;
  message: string;
  type: Color;
}

export type BookshelfView = 'LIBRARY' | 'WIZARD';

export interface KeyboardEnabledKeys {
  arrows: boolean;
  all: boolean;
}

export interface BookshelfState {
  opened: boolean;
  view: BookshelfView;
}

export interface UIState {
  appMenu: AppMenuState;
  bookshelf: BookshelfState;
  keyboard: {
    enabledKeys: KeyboardEnabledKeys;
  };
  snackBar: SnackbarState;
}

const initialState: UIState = {
  snackBar: {
    opened: false,
    message: '',
    type: 'success',
  },
  appMenu: {
    savedWordsOpened: false,
    savedQuotesOpened: false,
    opened: queryParams?.login === null,
    userSettingsOpened: false,
    readerSettingsOpened: false,
  },
  bookshelf: {
    opened: false,
    view: 'LIBRARY',
  },
  keyboard: {
    enabledKeys: {
      all: true,
      arrows: true,
    },
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },

    openAppMenu(state) {
      state.appMenu.opened = true;
      state.keyboard.enabledKeys.arrows = false;
    },
    closeAppMenu(state) {
      state.appMenu.opened = false;
      state.keyboard.enabledKeys.arrows = true;
    },
    toggleAppMenu(state) {
      const { opened } = state.appMenu;
      if (opened) {
        state.appMenu.opened = false;
        state.keyboard.enabledKeys.arrows = true;
      } else {
        state.appMenu.opened = true;
        state.keyboard.enabledKeys.arrows = false;
      }
    },

    openBookshelf(state) {
      state.bookshelf.opened = true;
    },
    closeBookshelf(state) {
      state.bookshelf.opened = false;
    },
    toggleBookshelf(state) {
      state.bookshelf.opened = !state.bookshelf.opened;
    },

    openSnackbar(
      state,
      {
        payload: { type, message },
      }: PayloadAction<Pick<SnackbarState, 'type' | 'message'>>
    ) {
      state.snackBar.opened = true;
      state.snackBar.type = type;
      state.snackBar.message = message;
    },
    closeSnackbar(state) {
      state.snackBar.opened = false;
    },

    setBookshelfView(state, action: PayloadAction<BookshelfView>) {
      state.bookshelf.view = action.payload;
    },

    openUserSettings(state) {
      state.appMenu.userSettingsOpened = true;
    },
    closeUserSettings(state) {
      state.appMenu.userSettingsOpened = false;
    },

    openSavedWords(state) {
      state.appMenu.savedWordsOpened = true;
    },
    closeSavedWords(state) {
      state.appMenu.savedWordsOpened = false;
    },

    openSavedQuotes(state) {
      state.appMenu.savedQuotesOpened = true;
    },
    closeSavedQuotes(state) {
      state.appMenu.savedQuotesOpened = false;
    },

    openReaderSettings(state) {
      state.appMenu.readerSettingsOpened = true;
    },
    closeReaderSettings(state) {
      state.appMenu.readerSettingsOpened = false;
    },

    enableArrowsControls(state) {
      state.keyboard.enabledKeys.arrows = true;
    },
    disableArrowsControls(state) {
      state.keyboard.enabledKeys.arrows = false;
    },
  },
});

export const { actions: uiActions, reducer: uiReducer } = uiSlice;
