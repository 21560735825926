import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { useDiagnosticModal } from '../../redux/diagnostic/selectors';
import { ModalGeneric } from '../ModalGeneric';
import TestEmailConfirmation from './TestEmailConfirmation';

interface OwnProps {}

type Props = OwnProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: '800px',
      width: '800px',
      height: 'inherit',

      [theme.breakpoints.down('xs')]: {
        margin: '0 !important',
        maxHeight: 'inherit',
        fontSize: 16,
      },
    },

    readingTestButton: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  })
);

const ReadingTestDialog: FC<Props> = ({ children }) => {
  const { setModalOpenState, isModalOpen } = useDiagnosticModal();
  const { paper, readingTestButton } = useStyles();

  return (
    <>
      {children && (
        <Button
          className={readingTestButton}
          color="secondary"
          variant="contained"
          onClick={setModalOpenState}
        >
          {children}
        </Button>
      )}
      <ModalGeneric
        open={isModalOpen}
        classes={{ paper }}
        title="Reading test"
        onClose={() => {
          setModalOpenState();
        }}
      >
        <TestEmailConfirmation setModalOpenState={setModalOpenState} />
      </ModalGeneric>
    </>
  );
};

export default ReadingTestDialog;
