import { readerActions } from '../reader';
import { saveCurrentTextToReadingHistoryAction } from '../readingHistory';
import { AppThunk } from '../store';

export const readerPauseAction = (
  speechActive: boolean = false
): AppThunk => dispatch => {
  dispatch(saveCurrentTextToReadingHistoryAction());
  if (speechActive) {
    dispatch(readerActions.pauseSpeech());
  } else {
    dispatch(readerActions.pause());
  }
};
