import { pick } from 'lodash';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { ReaderPlaybackState } from '../readerSlice';

export const useReaderPlaybackState = (): ReaderPlaybackState => {
  return useSelector<RootState, ReaderPlaybackState>(state => {
    return pick(state.reader, ['playing', 'paused', 'speed']);
  });
};
