import { readerActions } from '../reader';
import { saveCurrentTextToReadingHistoryAction } from '../readingHistory';
import { AppThunk } from '../store';
import { textActions } from '../text';
import { getCollectionItems } from '../utils';
import { readerPlayAction } from './readerPlayAction';

export const readNextPageAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { pagesCount, pages: pagesCollection } = state.book;
  const { pageNumber } = state.text;

  dispatch(readerActions.pause());

  if (pagesCount < pageNumber + 1) {
    dispatch(readerActions.setIsFinished(true));
    dispatch(saveCurrentTextToReadingHistoryAction());
    return;
  }

  const pages = getCollectionItems(pagesCollection);
  const nextPage = pages.find(p => p.number === pageNumber + 1);

  if (!nextPage) throw new Error('unexpected empty nextPage');

  dispatch(textActions.acceptText(nextPage.text));
  dispatch(textActions.setPageNumber(nextPage.number));

  setTimeout(() => dispatch(readerActions.setPosition(0)), 100);
  setTimeout(() => {
    dispatch(readerPlayAction());
    dispatch(saveCurrentTextToReadingHistoryAction());
  }, 120);
};
