import { getFirebase } from '../../firebase';
import { AppThunk } from '../store';
import { authActions } from './authSlice';

export const signUpWithEmailAndPasswordAction = (
  email: string,
  password: string
): AppThunk => async dispatch => {
  try {
    const result = await getFirebase()
      .auth()
      .createUserWithEmailAndPassword(email, password);

    if (!result.user) {
      throw new Error('user object is empty');
    }

    await result.user.sendEmailVerification();
  } catch ({ code = '', message = '' }) {
    const error = {
      type: 'all',
      message: 'Something went wrong. Please try again.',
    };

    if (code === 'auth/email-already-in-use') {
      error.message = String(message);
      error.type = 'email';
    }
    if (code === 'auth/weak-password') {
      error.message = String(message);
      error.type = 'password';
    }
    if (code === 'auth/invalid-email') {
      error.message = 'The email address is invalid.';
      error.type = 'email';
    }

    dispatch(
      authActions.setAuthenticated({ isAuthenticated: false, user: null })
    );
    dispatch(authActions.setError(error));
  }
};
