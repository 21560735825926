import { firestoreToPlainObject } from '@read4speed/firebase-utils';
import { TextModel } from '@read4speed/models';
import urlJoin from 'url-join';

import { collections } from '../firebase';

export class ArticleService {
  private static API_URL = 'https://api.read4speed.com/api/v1';

  static async parseUrl(url: string): Promise<string> {
    const response = await fetch(urlJoin(this.API_URL, 'articles', 'parse'), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ url, type: 'html' }),
    });
    const { articleId } = await response.json();
    return articleId || '';
  }

  static async getArticleById(articleId: string): Promise<TextModel | null> {
    const snapshot = await collections.textsRef().doc(articleId).get();
    if (!snapshot.exists) return null;
    return firestoreToPlainObject(snapshot);
  }

  static async toPlainText(html: string): Promise<string> {
    const el = document.createElement('div');
    el.innerHTML = html;
    const text = el.innerText;
    el.remove();
    return text;
  }
}
