import React, { FC, ReactElement } from 'react';

interface PlusIconProps {
  color: string;
}

const PlusIcon: FC<PlusIconProps> = ({ color }): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 12H4M12 20V12V20ZM12 12V4V12ZM12 12H20H12Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default PlusIcon;
