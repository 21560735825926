import React, { FC, ReactElement } from 'react';

interface ReaderScrollEndProps {
  color?: string;
}

const ReaderPageScrollEnd: FC<ReaderScrollEndProps> = (): ReactElement => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#50B5CC" />
    <circle cx="15" cy="15" r="12" fill="white" />
    <path
      d="M8.6521 15.625V18.1869"
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6956 15.625V17.8125"
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6955 10H8.6521V15.6251H21.6955V10Z"
      fill="#50B5CC"
      fillOpacity="0.2"
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6521 11.875H21.6955"
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6521 13.75H21.6955"
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6521 10.3125V15.6251"
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2607 10V15.6251"
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8696 10V15.6251"
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4783 10V15.6251"
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0867 10V15.6251"
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d={`M8 19.9999C9.24157 19.168 10.7243 18.7308 12.2391 18.7499C13.6604 18.685 15.0557 19.1308 16.1521 19.9999`}
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d={`M15.5549 19.5767C16.645 19.0309 17.8572 18.7471 19.0871 18.7499C20.5084 18.685 21.9037 19.1308 23.0001 19.9999`}
      stroke="#50B5CC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReaderPageScrollEnd;
