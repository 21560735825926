import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';

import { CustomDrawer } from '../../../styles/customDrawer';

export const useQuotesStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: 'none !important',
      padding: '2rem 1.4rem !important',
    },
    divider: {
      '&:not(:last-of-type)': {
        width: '50%',
        background: theme.palette.grey['400'],
      },
    },
  })
);

export const QuotesDrawer = withStyles(theme => ({
  paper: {
    height: 'clamp(max-content, 600px)',
    [theme.breakpoints.up('sm')]: {
      width: 'clamp(400px, 80vw, 1000px)',
    },
  },
}))(CustomDrawer);
