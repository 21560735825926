import { ParagraphModel, WordModel } from '@read4speed/models';
import sum from 'lodash/sum';
import { duration } from 'moment';

export interface TextParseResults {
  paragraphs: ParagraphModel[];
  words: WordModel[];
  wordsCount: number;
  paragraphsCount: number;
  totalWeight: number;
  totalLength: number;
}

export class TextParserService {
  /**
   * Calculates what should be the delay factor, so that when used,
   * it will result in desired speed (in wpm)
   *
   * @returns {number} delay factor sec / WeightPt
   */
  static speedToDelayFactor(
    words: WordModel[],
    totalWeight: number,
    wpm: number
  ): number {
    // this needed to calculate the most realistic weight
    // of desired words count
    const weightsArray = this.makeFixedLengthWeightsArray(
      words.map(this.getWordWeight),
      wpm,
      totalWeight / words.length
    );

    const minute = 60 * 1000;
    const meanWeight = sum(weightsArray);

    return minute / meanWeight;
  }

  static calcDelayMS(wordWeight: number, delayFactor: number): number {
    return Math.round(wordWeight * delayFactor);
  }

  static calcEstimatedTotalTimeMS(
    allWords: WordModel[],
    totalWeight: number,
    speed: number
  ): number {
    return this.calcDelayMS(
      totalWeight,
      this.speedToDelayFactor(allWords, totalWeight, speed)
    );
  }

  static getTimeText(timeMs: number): string {
    const time = duration(timeMs);
    const hours = time.hours();
    const seconds = `${time.seconds()}`.padStart(2, '0');
    const minutes = time.minutes().toString();
    let text = `${minutes}:${seconds}`;

    if (hours) {
      text = `${hours}:${minutes.padStart(2, '0')}:${seconds}`;
    }
    return text;
  }

  static async parseText(text: string): Promise<TextParseResults> {
    const paragraphsStrings = text
      .split(/\n[\s\n]*/)
      .map(p => p.trim())
      .filter(Boolean);

    const paragraphsWordsStrings = paragraphsStrings.map(p => {
      return p
        .split(/\s+/)
        .map(w => w.trim())
        .filter(Boolean);
    });

    const paragraphsWords = paragraphsWordsStrings.map(paragraphWordStrings =>
      paragraphWordStrings.map((wordString, i) =>
        this.createWord(wordString, i, paragraphWordStrings.length - 1)
      )
    );

    const paragraphs = paragraphsWords.map(words =>
      this.createParagraph(words)
    );

    // fix start / end
    paragraphs.forEach((current, index) => {
      const prev = paragraphs[index - 1];

      if (!prev) {
        // TODO: this might be wrong conceptually
        current.end = current.wordsCount - 1;
      } else {
        // TODO: this might be wrong conceptually
        current.start = prev.end + 1;
        current.end = current.start + (current.wordsCount - 1);
      }
    });

    // collect all words
    const words = paragraphsWords.flat();
    let indexInText = 0;

    // fixup index
    words.forEach((w, i) => {
      indexInText = text.indexOf(w.text, indexInText);
      w.index = i;
      w.indexInText = indexInText;
      indexInText += w.length - 1;
    });

    return {
      paragraphs,
      words,
      paragraphsCount: paragraphs.length,
      wordsCount: words.length,
      totalLength: sum(paragraphs.map(p => p.charsCount)),
      totalWeight: sum(paragraphs.map(p => p.weight)),
    };
  }

  public static getWordWeight(word: WordModel): number {
    return word.weight;
  }

  private static createWord(
    wordString: string,
    index: number,
    maxIndex: number
  ): WordModel {
    return {
      index: 0,
      indexInText: 0,
      indexInParagraph: index,
      length: wordString.length,
      text: wordString,
      weight: this.weightWord(wordString, index),
      isFirst: index === 0,
      isLast: index === maxIndex,
    };
  }

  private static weightWord(
    wordString: string,
    indexInParagraph: number
  ): number {
    const len = wordString.length;

    const digitsCount = wordString.match(/\d/g)?.length || 0;

    // todo: count syllables better
    const syllablesCount =
      wordString.match(/[eyuioaуеыаоэёяию]/gi)?.length || 0;

    const firstWordAddition = indexInParagraph === 0 ? 30 : 0;

    return syllablesCount * 4 + digitsCount * 10 + len * 7 + firstWordAddition;
  }

  private static createParagraph(words: WordModel[]): ParagraphModel {
    return {
      start: 0,
      end: 0,
      charsCount: sum(words.map(w => w.length)),
      weight: sum(words.map(w => w.weight)),
      wordsCount: words.length,
    };
  }

  private static makeFixedLengthWeightsArray(
    weights: number[],
    length: number,
    defaultValue: number
  ): number[] {
    if (weights.length >= length) return weights.slice(0, length);

    // this creates an array from array-like object
    return Array.from(
      // this creates an array-like object
      Array.prototype.fill.call(
        // our weights array but with desired length
        {
          ...weights,
          length,
        },
        // this set the value to fill
        defaultValue,
        // the start for fill
        weights.length,
        // the end for fill
        length
      )
    );
  }
}
