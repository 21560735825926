import {
  Button,
  ButtonGroup,
  DialogContent,
  Divider,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { WithWidth } from '@material-ui/core/withWidth';
import { genericEmail } from '@read4speed/firebase-functions';
import React, { FC, useState } from 'react';

import { useDiagnosticReadStats } from '../../redux/diagnostic/selectors';

interface OwnProps {
  setModalOpenState: VoidFunction;
}

type Props = OwnProps & WithWidth;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',

      '& .MuiTypography-h6': {
        marginTop: '16px',
        fontWeight: 'normal',
      },
    },

    form: {
      margin: '24px auto 20px',
      '& .MuiButtonGroup-root': {
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column',
        },
      },
      '& .MuiButtonBase-root': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        [theme.breakpoints.down('sm')]: {
          borderRadius: 12,
          marginTop: '1rem',
        },
      },
      '& .MuiInputBase-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,

        [theme.breakpoints.down('sm')]: {
          borderRadius: 4,
        },
      },
      '& .MuiInputBase-input': {
        width: '350px',
        height: '48px',
        padding: '0 14px',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },

    // TEMP STYLES TO DELETE ---- START
    container: {
      ...theme.global.container,
      height: '100%',
      paddingBottom: '2rem',
    },

    readerControlsHolder: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      flexShrink: 0,
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
      },
    },

    divider: {
      margin: '10px auto',
    },

    heading: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
      },
      textAlign: 'center',
      paddingTop: '2rem',
      paddingBottom: theme.spacing(2),
    },

    buttons: {
      ...theme.global.buttons.flexCenter,
    },

    finishButton: {
      margin: 'auto',
      marginTop: '1rem',
    },

    textWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      flex: 'auto',
      overflow: 'scroll',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },

    buttonsHolder: {
      boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    paragraph: {
      whiteSpace: 'pre-line',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: 'inherit',
      fontFamily: 'inherit',
    },

    urlInput: {
      flex: 'auto',
    },

    articleButton: {
      marginTop: theme.spacing(2),
    },

    resultsModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      background: theme.reader.background,
      padding: '2rem',
    },
    // TEMP STYLES TO DELETE ---- END
  })
);

const TestEmailConfirmation: FC<Props> = ({ setModalOpenState, width }) => {
  const classes = useStyles();
  // TEMP DEMO CODE TO DELETE ---- START
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => {
    setOpen(false);
  };

  const { WPM, startTime, endTime, readingTime } = useDiagnosticReadStats();
  // TEMP DEMO CODE TO DELETE ---- END

  const handleSubmit = async (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    handleOpen();

    try {
      await genericEmail({
        wpm: WPM,
        email,
        readingTime,
        subject: 'Reading Test Results',
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error occurred', e);
    }
    // Uncomment after clearing demo code
    // handleCloseTest();
    // handleResetTest();
  };

  return (
    <>
      <DialogContent dividers className={classes.content}>
        <Typography variant="h4">Thank you for taking our test!</Typography>
        <Typography variant="h6">
          ⚡ ️Enter your email to see your results ⚡️
        </Typography>
        <form noValidate autoComplete="off" className={classes.form}>
          <ButtonGroup
            variant={isWidthDown('sm', width) ? 'outlined' : 'contained'}
            color="default"
            aria-placeholder="mail@example.com"
          >
            <TextField
              color="secondary"
              id="outlined-basic"
              placeholder="mail@example.com"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              variant={isWidthDown('sm', width) ? 'standard' : 'outlined'}
            />
            <Button variant="contained" onClick={handleSubmit}>
              See Results
            </Button>
          </ButtonGroup>
        </form>
        <Typography variant="body2">
          By entering your email, you agree to our privacy policy
        </Typography>
      </DialogContent>
      {/*  // TEMP DEMO JSX TO DELETE ---- START */}
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          setModalOpenState();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.resultsModal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reading test results
          </Typography>
          <Divider className={classes.divider} />
          <Typography>
            Read start time: {startTime.format('HH:mm:ss')}
            <br />
            Read end time: {endTime.format('HH:mm:ss')}
            <br />
            Reading time: {readingTime}
            <br />
            Word per minute: {Math.floor(WPM)}
          </Typography>
        </Box>
      </Modal>
      {/*  // TEMP DEMO JSX TO DELETE ---- END */}
    </>
  );
};

const TestEmailConfirmationWithHOC = withWidth()(TestEmailConfirmation);
TestEmailConfirmationWithHOC.displayName = 'TestEmailConfirmation';

export default TestEmailConfirmationWithHOC;
