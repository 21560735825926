import { ReadingHistoryService } from '../../services/ReadingHistoryService';
import { TextDataService } from '../../services/TextDataService';
import { AppThunk } from '../store';
import { textActions } from '../text';
import { continueReadingAction } from './continueReadingAction';

export const loadTextAction = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const uid = state.auth.user?.uid;

  if (uid) {
    const latestHistoryItem = await ReadingHistoryService.getLatestItem(uid);
    if (latestHistoryItem) {
      dispatch(continueReadingAction(latestHistoryItem));
      return;
    }
  }

  const { text, textId } = await TextDataService.loadTextUnauthenticated();

  dispatch(textActions.acceptText(text));
  dispatch(textActions.setTextId(textId ? textId : null));
};
