import { AbsenceEmailsModel } from '@read4speed/models/src/AbsenceEmailsModel';

import { ScheduledEmailTypes } from '../../hooks/useAbsenceEmailsUpdate';
import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateAbsenceEmails = (
  id: string,
  absenceEmails: AbsenceEmailsModel[],
  emailsType: ScheduledEmailTypes
): AppThunk => async dispatch => {
  await UserProfilesService.updateAbsenceEmails(id, absenceEmails, emailsType);
  dispatch(
    userProfilesActions.update({
      id,
      data: { [emailsType]: absenceEmails },
    })
  );
};
