import * as QueryString from 'querystring';
import urlJoin from 'url-join';

interface CallOptions {
  method: 'GET' | 'PUT' | 'POST' | 'DELETE';
  query?: Record<string, string | number>;
  body?: RequestInit['body'];
}

type FunctionName = 'bookUpload' | 'bookRemove';

export class FirebaseApi {
  private static API_URL =
    'https://us-central1-read4speed-74bbb.cloudfunctions.net';

  static async callFunction<TReturn>(
    name: FunctionName,
    params: CallOptions
  ): Promise<TReturn> {
    const { query, body, method } = params;
    const init: RequestInit = { method };
    if (body) init.body = body;

    const res = await fetch(this.makeURI(name, query), init);
    return await res.json();
  }

  private static makeURI(
    methodName: string,
    query: CallOptions['query'] = {}
  ): string {
    const qs = QueryString.encode(query);
    return urlJoin(this.API_URL, methodName, `?${qs}`);
  }
}
