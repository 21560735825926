import { UIEventHandler, useCallback, useRef, useState } from 'react';

const SCROLL_NODE_ID = 'reader-scroll-container';

export const useScrolled = (): [
  React.RefObject<HTMLElement>,
  string,
  boolean,
  UIEventHandler,
  VoidFunction,
  boolean
] => {
  const [isBottomDirty, setIsBottomDirty] = useState(false);
  const [isHeaderSticky, setHeaderSticky] = useState(false);
  const [isUserScroll, setUserScroll] = useState(true);
  const headerRef = useRef<HTMLElement>(null);

  const handleScroll = useCallback(() => {
    const scrollNode = document.getElementById(SCROLL_NODE_ID);
    const header = headerRef.current as HTMLElement;

    if (scrollNode && isUserScroll && header !== null) {
      const top = scrollNode.scrollTop;
      const isSticky = top >= header.clientHeight;
      const isBottom = scrollNode.scrollHeight - top <= scrollNode.clientHeight;

      setHeaderSticky(isSticky);
      setUserScroll(isSticky === isHeaderSticky);
      if (!isBottomDirty && isBottom) setIsBottomDirty(true);
    } else if (!isUserScroll) setUserScroll(true);
  }, [isUserScroll, isHeaderSticky]);

  const resetScrollState: VoidFunction = () => {
    setIsBottomDirty(false);
  };

  return [
    headerRef,
    SCROLL_NODE_ID,
    isBottomDirty,
    handleScroll,
    resetScrollState,
    isHeaderSticky,
  ];
};
