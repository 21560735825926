import { DialogProps } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: '800px',
    },
    closeIconButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    closeButton: {
      all: 'initial',
    },
  })
);

export interface DialogTitleProps {
  children: React.ReactNode;
  onClose?: VoidFunction;
}

export interface ModalGenericProps {
  title?: string;
  onClose?: VoidFunction;
}

const DialogTitle: FC<DialogTitleProps & Partial<DialogProps>> = props => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeIconButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export const ModalGeneric: FC<ModalGenericProps & DialogProps> = props => {
  const { title = '', children, ...rest } = props;
  const { paper } = useStyles();

  return (
    <Dialog
      classes={{ paper }}
      aria-labelledby="customized-dialog-title"
      {...rest}
    >
      <DialogTitle onClose={rest.onClose}>{title}</DialogTitle>
      {children}
    </Dialog>
  );
};
