import { Box, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import clsx from 'clsx';
import React, { CSSProperties, FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseIcon from 'src/components/Icons/close';
import { colors } from 'src/styles/colors';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import FirstStep from '../../components/ReadingTestDialog/FirstStep';
import ReadingTestControls from '../../components/ReadingTestDialog/ReadingTestControls';
import { useBackgroundWithRef, useScrolled } from '../../hooks';
import useStopWatch from '../../hooks/useStopWatch';
import { diagnosticActions } from '../../redux/diagnostic';
import { useDiagnosticPlaybackState } from '../../redux/diagnostic/selectors';
import { useAuthorizedUserProfile } from '../../redux/userProfiles/selectors';
import { ReaderFontService } from '../../services/ReaderFontService';
import { useStyles } from './styles/readerPage';

const ReadingTestPage: FC = () => {
  const classes = useStyles({});
  const history = useHistory();
  const { stopped } = useDiagnosticPlaybackState();
  const profile = useAuthorizedUserProfile();
  const [startWatch, stopWatch, watch] = useStopWatch();
  const dispatch = useDispatch();

  const [
    headerRef,
    SCROLL_NODE_ID,
    isBottomDirty,
    handleScroll,
    resetScrollState,
    isHeaderSticky,
  ] = useScrolled();

  const handleStartTest: VoidFunction = () => {
    dispatch(diagnosticActions.play());
    startWatch();
  };

  const containerRef = useBackgroundWithRef();
  const exitClickHandler = useCallback(() => {
    history.push(APP_ROUTES_CONFIG.HOME);
  }, []);

  const readerFontStyles = useMemo<CSSProperties>(() => {
    const familyStyles = ReaderFontService.makeFontFamilyStyles(
      profile?.fontName
    );
    const sizeStyles = ReaderFontService.makeFontSizeStyles(profile?.fontSize);
    return {
      ...familyStyles,
      ...sizeStyles,
    };
  }, [profile]);

  return (
    <div className={classes.root} ref={containerRef}>
      <Box
        onScroll={handleScroll}
        id={SCROLL_NODE_ID}
        className={classes.readerHolder}
        data-reader-holder={true}
        style={readerFontStyles}
      >
        <header
          className={clsx(classes.header, isHeaderSticky && 'sticky')}
          ref={headerRef}
        >
          <Box display="flex">
            <h1>Reading Test</h1>
            <IconButton onClick={exitClickHandler} aria-label="close">
              <CloseIcon color={colors.grey700} />
            </IconButton>
          </Box>
        </header>
        <FirstStep resetScrollState={resetScrollState} stopped={stopped} />
      </Box>
      {stopped && (
        <div className={classes.testStartButton}>
          <Button
            startIcon={<PlayArrowOutlinedIcon />}
            color="secondary"
            variant="contained"
            onClick={handleStartTest}
          >
            Start Test
          </Button>
        </div>
      )}
      <ReadingTestControls
        watch={watch}
        stopped={stopped}
        stopWatch={stopWatch}
        bottomDirty={isBottomDirty}
        resetScrollState={resetScrollState}
      />
    </div>
  );
};

export default ReadingTestPage;
