import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/firestore';

import firebase from 'firebase/app';

import { FIREBASE_CONFIG } from './firebaseConfig';

const app = firebase.initializeApp(FIREBASE_CONFIG);

firebase.database();
firebase.analytics();

export const getFirebase = (): firebase.app.App => {
  return app;
};

export const getPersistence = () => {
  return firebase.auth.Auth.Persistence;
};

export const getFirestore = (): firebase.firestore.Firestore => {
  return app.firestore();
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.firebase = firebase as unknown;
