import { useEffect, useState } from 'react';

import { useSpeechSupported } from './useSpeechSupported';

function prime(): void {
  const utterance = new SpeechSynthesisUtterance('e');
  utterance.volume = 0;
  window.speechSynthesis.speak(utterance);
}

export const useTtsEnabler = (): void => {
  const [primed, setPrimed] = useState(false);
  const speechSupported = useSpeechSupported();

  useEffect(() => {
    if (!speechSupported) return;
    if (primed) return;

    const buttonElement = document.createElement('button');
    buttonElement.hidden = true;
    buttonElement.onclick = prime;

    document.body.append(buttonElement);
    buttonElement.click();
    buttonElement.remove();

    setPrimed(true);
  }, [primed, speechSupported]);
};
