import Box from '@material-ui/core/Box';
import { FontNameEnum } from '@read4speed/models';
import React, { FC, useMemo } from 'react';

import { ReaderFontService } from '../../services/ReaderFontService';
import { FontPreview } from './FontPreview';
import { useStyles } from './styles/readerSettings';

interface ReaderSettingsFontSelectorProps {
  font?: FontNameEnum;
  onSelect: (font: FontNameEnum) => void;
}

export const ReaderSettingsFontSelector: FC<ReaderSettingsFontSelectorProps> = ({
  font,
  onSelect,
}) => {
  const classes = useStyles();
  const availableFonts = useMemo(
    () => ReaderFontService.getAvailableFontConfigs(),
    []
  );

  const selectedFont = font || ReaderFontService.DEFAULT_FONT;

  return (
    <label className={classes.row}>
      <span>Typeface</span>
      <Box display="flex">
        {availableFonts.map(({ name }) => (
          <FontPreview
            key={name}
            fontName={name}
            onClick={onSelect}
            selected={name === selectedFont}
          />
        ))}
      </Box>
    </label>
  );
};
