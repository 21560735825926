import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { ThemeNameEnum } from '@read4speed/models';
import { useMemo } from 'react';

import { useAuthorizedUserProfile } from './redux/userProfiles/selectors';
import { globalThemeClasses, themes } from './styles/classes';
import { overrides, props, typography } from './styles/overrides';

const DEFAULT_THEME: ThemeNameEnum = 'WHITE';
const LS_KEY = 'R4S_THEME';

export const useMuiTheme = (): Theme => {
  const authorizedUserProfile = useAuthorizedUserProfile();

  const localStorageSavedTheme = useMemo<ThemeNameEnum>(() => {
    const value =
      authorizedUserProfile?.theme ||
      localStorage.getItem(LS_KEY) ||
      DEFAULT_THEME;

    localStorage.setItem(LS_KEY, value);

    return value as ThemeNameEnum;
  }, [authorizedUserProfile?.theme]);

  return useMemo(() => {
    return createMuiTheme({
      typography,
      props,
      overrides,
      ...themes[localStorageSavedTheme],
      global: globalThemeClasses,
    });
  }, [localStorageSavedTheme]);
};

//  ------------------------------------
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    reader: typeof themes[typeof DEFAULT_THEME]['reader'];
    global: typeof globalThemeClasses;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    reader: {
      background: string;
      text: string;
    };
    global: typeof globalThemeClasses;
  }
}
