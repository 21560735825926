import { createStyles } from '@material-ui/core/styles';

export const buttons = createStyles({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
  },
});

export const globals = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  containerRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
  },
});
