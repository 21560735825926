import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  readNextPageAction,
  readPrevPageAction,
} from '../../redux/commonActions';
import { booksLoadAction } from '../../redux/library';
import { useBook } from '../../redux/library/selectors';
import { useTextId, useTextPageNumber } from '../../redux/text/selectors';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: -theme.spacing(6),
      width: '100%',
    },
    controlsHolder: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '30px',
      padding: theme.spacing(0.5, 0.5),
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
    },
    text: {
      margin: theme.spacing(0, 1),
    },
  })
);

interface BookPageControlProps {}

export const BookPageControl: FC<BookPageControlProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const textId = useTextId();
  const book = useBook(textId);
  const page = useTextPageNumber();

  useEffect(() => {
    dispatch(booksLoadAction());
  }, []);

  const prev = useCallback(() => {
    if (!book?.id) return;
    dispatch(readPrevPageAction());
  }, [book?.id]);

  const next = useCallback(() => {
    if (!book?.id) return;
    dispatch(readNextPageAction());
  }, [book?.id]);

  return (
    <Box className={classes.root}>
      <Box className={classes.controlsHolder}>
        <IconButton size={'small'} color={'secondary'} onClick={prev}>
          <ChevronLeft fontSize={'small'} />
        </IconButton>
        <Typography className={classes.text} variant={'caption'}>
          {page} of {book?.pagesCount || 0}
        </Typography>
        <IconButton size={'small'} color={'secondary'} onClick={next}>
          <ChevronRight fontSize={'small'} />
        </IconButton>
      </Box>
    </Box>
  );
};
