import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      textAlign: 'left',
      alignItems: 'center',
      height: '72px',
      padding: '12px 24px 12px 20px',
      position: 'relative',
      outline: 'none',
      cursor: 'pointer',
      border: 'none',
      background: 'white',
      color: colors.greyBase,
      boxShadow: 'inset 0 -1px 1px rgba(0,0,0,.1)',
      fontFamily: theme.typography.fontFamily,
      '&.started': {
        background: colors.grey50,
      },
      '& svg': {
        width: '32px',
      },
      '&:hover': {
        zIndex: 1,
        boxShadow: `0 2px 4px rgba(0,0,0,.06), 0 4px 6px rgba(0,0,0,.08), 0 -2px 6px rgba(0,0,0,.04)`,
        '& > span': {
          opacity: 1,
          color: colors.orange600,
        },
      },
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      width: '100%',
      paddingLeft: '16px',
      fontSize: '15px',
      lineHeight: '24px',
      '& span:first-of-type': {
        color: colors.grey800,
        fontSize: '17px',
        fontWeight: 500,
        '.started &': {
          fontWeight: 'normal',
        },
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginBottom: 0,
      },
      '& span': {
        opacity: 0.7,
      },
    },
    action: {
      flexShrink: 0,
      fontWeight: 500,
      fontSize: '17px',
      marginLeft: '25px',
      opacity: 0.5,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  })
);
