import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { BookModel } from '@read4speed/models';
import clsx from 'clsx';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { useIsAdmin } from '../../redux/auth/selectors';
import { continueReadingAction } from '../../redux/commonActions/continueReadingAction';
import { bookDeleteAction } from '../../redux/library';
import { uiActions } from '../../redux/ui';

interface BookCardProps {
  book: BookModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      padding: theme.spacing(1.4, 1),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.default,
    },

    actionsCentered: { justifyContent: 'center' },
    actionsRight: { justifyContent: 'flex-end' },

    progressIcon: {
      margin: theme.spacing(1),
    },

    redButton: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    redButtonInverted: {
      color: theme.palette.error.light,
      '&:hover': {
        color: theme.palette.error.dark,
      },
    },
  })
);

export const BookCard: FC<BookCardProps> = ({ book }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const readerRouteMatch = useRouteMatch(APP_ROUTES_CONFIG.READER);
  const history = useHistory();
  const isAdmin = useIsAdmin();

  const [removing, setRemoving] = useState<boolean>(false);

  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);

  const onDeleteClick = useCallback(() => {
    setConfirmingDelete(true);
  }, []);

  const cancelDelete = useCallback(() => {
    setConfirmingDelete(false);
  }, []);

  const confirmDelete = useCallback(() => {
    setConfirmingDelete(false);
    setRemoving(true);
    dispatch(bookDeleteAction(book.id as string));
  }, [book]);

  const onRead = useCallback(() => {
    if (!book.id) return;
    dispatch(
      continueReadingAction({
        isFinished: false,
        targetId: book.id,
        wordIndex: 0,
        lastSpeed: 300,
        lastSpeechSpeed: 1,
        type: 'BOOK',
        pageNumber: 1,
        previewText: '', // title plug for 'BOOK' type
      })
    );
    dispatch(uiActions.closeBookshelf());
    if (!readerRouteMatch) history.push(APP_ROUTES_CONFIG.READER);
  }, [readerRouteMatch]);

  return (
    <Card>
      <CardContent>
        <Typography variant={'subtitle1'}>&quot;{book.title}&quot;</Typography>
        <Typography variant={'subtitle2'}>{book.author}</Typography>
      </CardContent>

      <Divider />

      {removing && (
        <CardActions className={clsx(classes.actions, classes.actionsCentered)}>
          <CircularProgress
            color="secondary"
            size={18}
            className={classes.progressIcon}
          />
        </CardActions>
      )}

      {!removing && !confirmingDelete && (
        <CardActions className={classes.actions}>
          <Button
            size={'small'}
            variant={'text'}
            color={'secondary'}
            onClick={onRead}
          >
            Read
          </Button>

          {isAdmin && (
            <IconButton
              size={'small'}
              onClick={onDeleteClick}
              className={classes.redButtonInverted}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </CardActions>
      )}

      {!removing && confirmingDelete && (
        <CardActions className={clsx(classes.actions, classes.actionsRight)}>
          <Button
            size={'small'}
            variant={'text'}
            color={'primary'}
            onClick={cancelDelete}
          >
            Cancel
          </Button>
          <Button
            size={'small'}
            className={classes.redButton}
            variant={'contained'}
            color={'secondary'}
            onClick={confirmDelete}
          >
            Yes, Delete
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
