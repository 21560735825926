import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { FixationPointsEnum } from '@read4speed/models/src/FixationPointsEnum';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { useStyles } from './styles/readerSettings';

interface ReaderSettingsFixationPointsProps {
  value?: FixationPointsEnum[];
  onChange(theme: FixationPointsEnum[]): void;
}

export const ReaderSettingsFixationPoints: FC<ReaderSettingsFixationPointsProps> = ({
  value = ['Three'],
  onChange,
}) => {
  const classes = useStyles();
  const [localValue, setLocalValue] = useState<FixationPointsEnum[]>(value);
  const isFixationPoints = localValue.indexOf('Three') >= 0;

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    onChange(localValue);
  }, [localValue]);

  const toggleFixationPoints = useCallback(
    (mode: FixationPointsEnum) => {
      if (localValue.indexOf(mode) == -1) {
        setLocalValue(localValue.concat(mode));
      } else setLocalValue(localValue.filter(item => item !== mode));
    },
    [localValue]
  );

  return (
    <label className={classes.row}>
      <span>Fixation Points</span>
      <Box display="flex" alignItems="center">
        <Button
          onClick={() => toggleFixationPoints('Three')}
          className={clsx(classes.settingsBtn, isFixationPoints && 'active')}
        >
          {isFixationPoints ? 'Turned On' : 'Turned Off'}
        </Button>
      </Box>
    </label>
  );
};
