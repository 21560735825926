import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { colors } from 'src/styles/colors';

export const CustomDrawer = withStyles(theme => ({
  paper: {
    backgroundColor: 'white',
    borderRadius: '12px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    '&::before': {
      content: '""',
      display: 'block',
      width: '48px',
      height: '4px',
      borderRadius: '10px',
      backgroundColor: colors.grey400,
      position: 'absolute',
      top: '12px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '480px',
      bottom: 'auto',
      top: '50%',
      left: '50%',
      borderRadius: '20px',
      transform: 'translate(-50%, -50%) !important',
      '&::before': {
        display: 'none',
      },
    },
  },
}))(SwipeableDrawer);
