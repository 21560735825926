import { BookModel } from '@read4speed/models';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { getCollectionStateItem } from '../../utils';

export const useBook = (id: string | null): BookModel | null => {
  return useSelector<RootState, BookModel | null>(state =>
    getCollectionStateItem(state.library.books, id)
  );
};
