import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert/Alert';
import { BookImportResult } from '@read4speed/models';
import React, { FC, useCallback, useState } from 'react';

import { BookService } from '../../services/BookService';
import { FileLoader } from '../FileLoader';
import { BookImportConfirm } from './BookImportConfirm';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    progressHolder: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
    },
    progressText: { marginTop: theme.spacing(2) },
  })
);

interface BookshelfBookWizardProps {
  onDone: () => void;
}

export const BookshelfBookWizard: FC<BookshelfBookWizardProps> = ({
  onDone,
}) => {
  const classes = useStyles();
  const [step, setStep] = useState<'SELECT' | 'UPLOADING' | 'CONFIRM'>(
    'SELECT'
  );
  const [error, setError] = useState<unknown | null>(null);
  const [previewData, setPreviewData] = useState<BookImportResult | null>(null);

  const onAdded = useCallback((files: File[]) => {
    setError(null);
    setPreviewData(null);
    setStep('UPLOADING');
    BookService.uploadBook(files[0], 'pdf')
      .then(result => {
        setPreviewData(result);
        setStep('CONFIRM');
      })
      .catch(error => {
        setPreviewData(null);
        setError(error);
        setStep('SELECT');
      });
  }, []);

  const onDoneConfirm = useCallback(() => {
    setPreviewData(null);
    setError(null);
    setStep('SELECT');
    onDone();
  }, []);

  return (
    <Box className={classes.root}>
      {step === 'SELECT' && <FileLoader handleFiles={onAdded} />}
      {step === 'UPLOADING' && (
        <Box className={classes.progressHolder}>
          <CircularProgress color="secondary" />
          <Typography
            variant={'subtitle2'}
            color={'textSecondary'}
            className={classes.progressText}
          >
            PARSING THE BOOK
          </Typography>
        </Box>
      )}

      {step === 'CONFIRM' && previewData && (
        <BookImportConfirm importResult={previewData} onDone={onDoneConfirm} />
      )}

      {error && (
        <Alert severity={'error'}>
          <pre>${error}</pre>
        </Alert>
      )}
    </Box>
  );
};
