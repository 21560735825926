import { WordModel } from '@read4speed/models';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { readerActions } from 'src/redux/reader';

import { useSpeechSupported } from '../../components/SpeechEnabler';
import {
  useReaderPosition,
  useReaderSpeechState,
} from '../../redux/reader/selectors';
import { useText, useWords } from '../../redux/text/selectors';

export const useTextToSpeech = (): void => {
  const { speechPlaying, speechSpeed } = useReaderSpeechState();

  const speechSupported = useSpeechSupported();
  const position = useReaderPosition();
  const text = useText();
  const words = useWords();
  const dispatch = useDispatch();
  const [speechText, setSpeechText] = useState<string>('');
  const [nextWord, setNextWord] = useState<WordModel>();
  const [speechIndex, setSpeechIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const currentWord = words && words[position];

  const updateSpeechText = useCallback(() => {
    if (currentWord) {
      const index = currentWord.indexInText;
      setSpeechText(text.slice(index));
      setStartIndex(index);
    }
  }, [currentWord]);

  useEffect(() => {
    updateSpeechText();
  }, [speechSpeed]);

  useEffect(() => {
    if (!speechPlaying) return;
    if (nextWord && nextWord.indexInText <= speechIndex + startIndex) {
      dispatch(readerActions.setNextPosition());
    }
  }, [speechPlaying, speechIndex, startIndex]);

  useEffect(() => {
    if (!speechText) return;
    if (!speechSupported) return;

    let timeout: NodeJS.Timeout;
    const utterance = new window.SpeechSynthesisUtterance(speechText);
    utterance.rate = speechSpeed;
    utterance.volume = 0.8;
    utterance.onboundary = (e): void => setSpeechIndex(e.charIndex);
    utterance.onend = () => dispatch(readerActions.pauseSpeech());
    utterance.lang = 'en-US';

    if (speechPlaying) {
      timeout = setTimeout(() => window.speechSynthesis.speak(utterance), 200);
    }

    return () => {
      clearTimeout(timeout);
      utterance.onboundary = null;
      window.speechSynthesis.cancel();
    };
  }, [speechPlaying, speechSpeed, speechText, speechSupported]);

  useEffect(() => {
    if (speechPlaying && currentWord) {
      setNextWord(words[position + 1]);
    }

    if (!speechPlaying && currentWord) {
      const index = currentWord.indexInText;
      setSpeechText(text.slice(index));
      setStartIndex(index);
    }
  }, [speechPlaying, words, position]);
};
