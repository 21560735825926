import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { BookPageModel } from '@read4speed/models';
import React, { FC } from 'react';

import { PageSeparator } from '../../components/PageSeparator';

const useStyles = makeStyles(theme =>
  createStyles({
    paragraph: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontSize: 'inherit',
      fontFamily: 'inherit',
    },
  })
);

interface TextReaderPagePreviewProps {
  page: BookPageModel;
}

export const TextReaderPagePreview: FC<TextReaderPagePreviewProps> = ({
  page,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <PageSeparator pageNumber={page.number} />
      {page.text.split(/\n+/).map((p, i) => (
        <Typography
          key={i}
          variant={'body1'}
          component={'p'}
          className={classes.paragraph}
        >
          {p.trim()}
        </Typography>
      ))}
    </Box>
  );
};
