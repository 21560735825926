import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { UserProfileModel } from '@read4speed/models';
import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CloseIcon from 'src/components/Icons/close';
import { uiActions } from 'src/redux/ui';

import { useUserSettingsOpened } from '../../redux/ui/selectors';
import { updateUserProfileAction } from '../../redux/userProfiles';
import { useAuthorizedUserProfileId } from '../../redux/userProfiles/selectors';
import { CustomDrawer } from '../../styles/customDrawer';
import { CustomTextField } from '../../styles/customTextField';
import { SubmitButton } from '../../styles/submitButton';
import { useStyles } from './styles/userSettings';

interface ProfileSettingsForm {
  firstName?: string;
  lastName?: string;
}

interface UserSettingsProps {
  profile: UserProfileModel;
}

export const UserSettings: FC<UserSettingsProps> = ({ profile }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profileId = useAuthorizedUserProfileId();
  const isVisible = useUserSettingsOpened();

  const { register, formState, handleSubmit } = useForm<ProfileSettingsForm>({
    defaultValues: profile,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const inputProps = {
    fullWidth: true,
    disableUnderline: true,
  };

  const close = useCallback(() => {
    dispatch(uiActions.closeUserSettings());
  }, []);

  const onSubmit = handleSubmit(({ firstName, lastName }) => {
    if (!profileId) return;
    dispatch(updateUserProfileAction(profileId, { firstName, lastName }));
    close();
  });

  return (
    <CustomDrawer
      container={document.body}
      anchor="bottom"
      open={isVisible}
      onClose={close}
      onOpen={() => ({})}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box className={classes.header}>
        <span>Edit Profile</span>
        <IconButton aria-label="exit" onClick={close}>
          <CloseIcon color="black" />
        </IconButton>
      </Box>
      <form className={classes.form} onSubmit={onSubmit}>
        <label htmlFor="firstName">First Name</label>
        <CustomTextField
          autoFocus
          inputRef={register({ required: true })}
          InputProps={inputProps}
          id="firstName"
          name="firstName"
          placeholder="John"
          autoComplete="current-first-name"
        />
        <label htmlFor="lastName">Last Name</label>
        <CustomTextField
          inputRef={register}
          type="text"
          id="lastName"
          name="lastName"
          InputProps={inputProps}
          placeholder="Reader"
          autoComplete="current-last-name"
        />
        <SubmitButton
          disabled={!formState.isValid}
          type="submit"
          fullWidth
          variant="contained"
        >
          Save
        </SubmitButton>
      </form>
    </CustomDrawer>
  );
};
