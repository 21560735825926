import { ParagraphCommentsModal } from '@read4speed/models/src';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../redux/hooks';
import { selectAllTextIds } from '../../redux/readingHistory';
import { useReadingHistoryItems } from '../../redux/readingHistory/selectors';
import { useQuotesData } from '../../redux/textQuotes/selectors';
import { quotesActions } from '../../redux/textQuotes/textQuotesSlice';
import { ParagraphCommentsService } from '../../services/ParagraphCommentsService';
import { ReadingHistoryItem } from './ReadingHistoryItem';
import { useStyles } from './styles/readingHistoryList';

interface ReadingHistoryListProps {}

export const ReadingHistoryList: FC<ReadingHistoryListProps> = () => {
  const [comments, setComments] = useState<ParagraphCommentsModal[]>([]);
  const classes = useStyles();
  const history = useReadingHistoryItems();
  const { quotes } = useQuotesData();
  const readingItemsIds = useAppSelector(selectAllTextIds);
  const dispatch = useDispatch();

  useEffect(() => {
    if (readingItemsIds.length && !quotes.length) {
      dispatch(quotesActions.fetchQuotes(readingItemsIds));
    }
    const getParagraphComments = async () => {
      const comments = await ParagraphCommentsService.getByTextRefIds(
        readingItemsIds
      );
      if (comments) setComments(comments);
    };
    getParagraphComments();
  }, []);

  return (
    <div className={classes.root}>
      <ul>
        {history.map(item => (
          <li key={item.id}>
            <ReadingHistoryItem
              item={item}
              quotes={quotes.filter(quote => quote.textRefId === item.targetId)}
              paragraphComments={comments.find(
                comment => comment.textRefId === item.targetId
              )}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

ReadingHistoryList.displayName = 'ReadingHistoryList';
