import { common, grey, orange } from '@material-ui/core/colors';
import { colors } from 'src/styles/colors';

import { buttons, globals } from './customStyles';

export enum ThemeOptionsEnum {
  DARK = 'DARK',
  WHITE = 'WHITE',
  PAPERWHITE = 'PAPERWHITE',
}

export const globalThemeClasses = {
  buttons,
  ...globals,
};

const SECONDARY_COLOR_PALETTE = {
  light: colors.orange500,
  main: colors.orange600,
  dark: colors.orange700,
};

export const themes = {
  [ThemeOptionsEnum.DARK]: {
    reader: {
      background: grey['800'],
      text: grey['50'],
      borderColor: colors.grey400,
    },
    palette: {
      type: 'dark',
      primary: grey,
      secondary: orange,
    },
  },
  [ThemeOptionsEnum.WHITE]: {
    reader: {
      background: common.white,
      text: grey[800],
      borderColor: colors.grey200,
    },
    palette: {
      type: 'light',
      primary: grey,
      secondary: SECONDARY_COLOR_PALETTE,
    },
  },
  [ThemeOptionsEnum.PAPERWHITE]: {
    reader: {
      background: '#f1eadd',
      text: '#262523',
      borderColor: colors.grey200,
    },
    palette: {
      type: 'light',
      primary: grey,
      secondary: SECONDARY_COLOR_PALETTE,
    },
  },
} as const;
