import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import LoginIllustration from '../../components/Icons/loginIllustration';
import BigLogoIcon from '../../components/Icons/logoBig';
import { useAuthenticated } from '../../redux/auth/selectors';
import { SigninForm } from './SigninForm';
import { useStyles } from './styles/signinPage';

interface SigninPageProps {}

export const SigninPage: FC<SigninPageProps> = () => {
  const history = useHistory();
  const { search } = useLocation();
  const isAuthenticated = useAuthenticated();
  const classes = useStyles();

  if (isAuthenticated) {
    if (search === '?extension-auth') {
      window.close();
      return null;
    }
    history.push(APP_ROUTES_CONFIG.HOME);
  }

  return (
    <main className={classes.root}>
      <div className={classes.side}>
        <BigLogoIcon />
        <h2 className="desktop">Read Faster, Remember More</h2>
        <p className="desktop">
          Science-backed tools to make you a better reader on all your devices
        </p>
        <div className="desktop">
          <LoginIllustration />
        </div>
      </div>
      <div className={classes.main}>
        <h1>Unlock your brain’s reading potential</h1>
        <SigninForm />
      </div>
    </main>
  );
};
