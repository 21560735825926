import { ReadingHistoryService } from '../../services/ReadingHistoryService';
import { AppThunk } from '../store';
import { readingHistoryActions } from './readingHistorySlice';

export const saveCurrentTextToReadingHistoryAction = (
  positionOverride?: number,
  title?: string
): AppThunk => async (dispatch, getState) => {
  const state = getState();

  const uid = state.auth.user?.uid;

  const { position, speed, speechSpeed, isFinished } = state.reader;
  const {
    text,
    textId,
    textType,
    pageNumber,
    thumbSrc,
    paragraphs,
    totalWordsCount,
  } = state.text;

  if (!uid || !textId) return;

  // TODO: optimize
  const item = await ReadingHistoryService.saveCurrentText(uid, {
    text,
    position: positionOverride === undefined ? position : positionOverride,
    speed,
    speechSpeed,
    targetId: textId,
    type: textType,
    pageNumber,
    thumbSrc,
    isFinished,
    previewText: title,
    firstParagraph: paragraphs[0],
    totalWordsCount,
  });

  dispatch(readingHistoryActions.acceptHistoryItems([item]));
};
