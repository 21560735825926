import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

const BORDER_RADIUS = 12;

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '100%',
      borderRadius: BORDER_RADIUS,
      overflowY: 'auto',
      '& ul': {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        background: colors.grey50,
        border: `1px solid ${colors.grey200}`,
        boxShadow: '0 1px 2px rgba(0,0,0,.06), 0 1px 3px rgba(0,0,0,.1)',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
          borderRadius: BORDER_RADIUS,
          border: 'none',
          margin: '0 2px',
        },
      },
    },
  })
);
