import { Palette } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';

export const typography:
  | TypographyOptions
  | ((palette: Palette) => TypographyOptions)
  | undefined = {
  fontFamily: 'Euclid, sans-serif',
  fontWeightRegular: 'inherit',
};

export const props: ComponentsProps | undefined = {
  MuiButtonBase: {
    disableRipple: true,
    disableTouchRipple: true,
  },
};

export const overrides: Overrides = {
  MuiButton: {
    root: {
      borderRadius: 12,
      lineHeight: 1.3,
      textTransform: 'none',
      WebkitAppearance: 'none',
    },
  },
  MuiIconButton: {
    root: {
      padding: 0,
      borderRadius: 12,
      WebkitAppearance: 'none',
      WebkitBorderRadius: 'none',
      '&:focus, &:hover, &$active': {
        background: 'none',
      },
    },
  },
  MuiTextField: {
    root: {
      borderRadius: 12,
    },
  },
  MuiButtonGroup: {
    root: {
      borderRadius: 12,
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 20,
    },
  },
};
