import { Link, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import clsx from 'clsx';
import { addDays } from 'date-fns';
import React, { CSSProperties, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ClockIcon from 'src/components/Icons/clock';
import CloseIcon from 'src/components/Icons/close';
import { colors } from 'src/styles/colors';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { ReaderScroll } from '../../components/ReaderScroll';
import { useTtsEnabler } from '../../components/SpeechEnabler';
import { useAbsenceEmailsUpdate, useScrolled } from '../../hooks';
import {
  useReaderPlaybackState,
  useReaderTitleState,
} from '../../redux/reader/selectors';
import {
  saveCurrentTextToReadingHistoryAction,
  updateReadingHistoryItem,
} from '../../redux/readingHistory';
import { parseTextAction } from '../../redux/text';
import { useTextSlice } from '../../redux/text/selectors';
import { quotesActions } from '../../redux/textQuotes/textQuotesSlice';
import { uiActions } from '../../redux/ui';
import { useAuthorizedUserProfile } from '../../redux/userProfiles/selectors';
import { ReaderFontService } from '../../services/ReaderFontService';
import { TextParserService } from '../../services/TextParserService';
import { BookPageControl } from './BookPageControl';
import { useStyles } from './styles/readerPage';
import { TextReader } from './TextReader';
import { TextReaderControls } from './TextReaderControls';
import { TextReaderLowerPages } from './TextReaderLowerPages';
import { TextReaderUpperPages } from './TextReaderUpperPages';
import { useTextToSpeech } from './useTextToSpeech';

const timeoutConfigs = [
  {
    after: 1,
    data: {
      time: '1 day',
    },
  },
  {
    after: 3,
    data: {
      time: '3 days',
    },
  },
  {
    after: 7,
    data: {
      time: '7 days',
    },
  },
  {
    after: 14,
    data: {
      time: '14 days',
    },
  },
];

export const ReaderPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    text,
    parsed: textParsed,
    textType,
    pageNumber,
    loaded: textLoaded,
    totalWeight,
    words,
    sourceURL: textSourceURL,
  } = useTextSlice();
  const classes = useStyles({ textSourceURL });
  const history = useHistory();
  const profile = useAuthorizedUserProfile();
  const { currentTitle } = useReaderTitleState();
  const { speed } = useReaderPlaybackState();

  const [
    headerRef,
    SCROLL_NODE_ID,
    ,
    handleScroll,
    ,
    isHeaderSticky,
  ] = useScrolled();

  const onScroll = handleScroll;

  const totalTimeToRead = useMemo(() => {
    const ms = TextParserService.calcEstimatedTotalTimeMS(
      words,
      totalWeight,
      speed
    );
    return {
      ms,
      text: TextParserService.getTimeText(ms),
    };
  }, [words.length, totalWeight, speed]);

  useAbsenceEmailsUpdate(
    {
      additionalData: { currentTitle },
      timeoutConfigs: timeoutConfigs,
      dateCallback: addDays,
      emailsType: 'articleAbsenceEmails',
      templateId: 'd-bce05bcbae8148fca902fab492356218',
    },
    [currentTitle]
  );

  useTtsEnabler();
  useTextToSpeech();

  useEffect(() => {
    dispatch(uiActions.closeAppMenu());
    return () => {
      dispatch(saveCurrentTextToReadingHistoryAction());
      dispatch(quotesActions.setIsQuotesActive(false));
    };
  }, []);

  // embedded iframe check
  // useEffect(() => {
  //   const url =
  //     window.location != window.parent.location ? document.referrer : '';
  //   if (url) {
  //     dispatch(readArticleAction(url, history));
  //   }
  //
  //   dispatch(uiActions.closeAppMenu());
  //   return () => {
  //     dispatch(saveCurrentTextToReadingHistoryAction());
  //     dispatch(quotesActions.setIsQuotesActive(false));
  //   };
  // }, []);

  useEffect(() => {
    if (totalTimeToRead) {
      dispatch(
        updateReadingHistoryItem({ remainingReadingTime: totalTimeToRead.text })
      );
    }
  }, [totalTimeToRead]);

  useEffect(() => {
    if (!textLoaded || text === undefined || textParsed) return;

    dispatch(parseTextAction(text));
  }, [textLoaded, text, textParsed]);

  const exitClickHandler = useCallback(() => {
    history.push(APP_ROUTES_CONFIG.HOME);
  }, []);

  const readerFontStyles = useMemo<CSSProperties>(() => {
    const familyStyles = ReaderFontService.makeFontFamilyStyles(
      profile?.fontName
    );
    const sizeStyles = ReaderFontService.makeFontSizeStyles(profile?.fontSize);
    return {
      ...familyStyles,
      ...sizeStyles,
    };
  }, [profile]);
  const title = text.split(/\.|\n/)[0];

  return (
    <div className={classes.root}>
      <ReaderScroll className={classes.readerScroll} />
      <Box
        id={SCROLL_NODE_ID}
        className={classes.readerHolder}
        data-reader-holder={true}
        style={readerFontStyles}
        onScroll={onScroll}
      >
        {totalTimeToRead && !isNaN(totalTimeToRead.ms) ? (
          <>
            {textSourceURL && (
              <Link
                target="_blank"
                rel="noreferrer"
                href={textSourceURL}
                color="secondary"
                className={classes.sourceLink}
              >
                <LinkIcon />
                <span>{textSourceURL}</span>
              </Link>
            )}
            <header
              className={clsx(classes.header, isHeaderSticky && 'sticky')}
              ref={headerRef}
            >
              <Box display="flex">
                <Tooltip title={<span style={{ fontSize: 12 }}>{title}</span>}>
                  <h1>{title}</h1>
                </Tooltip>
                <IconButton onClick={exitClickHandler} aria-label="close">
                  <CloseIcon color={colors.grey700} />
                </IconButton>
              </Box>
              <Box
                className={classes.time}
                display={isHeaderSticky ? 'none' : 'flex'}
              >
                <ClockIcon color={colors.greyBase} />
                <span>{totalTimeToRead.text} to read</span>
              </Box>
            </header>
          </>
        ) : null}

        {textType === 'BOOK' && pageNumber && <TextReaderUpperPages />}
        {textParsed && <TextReader totalTimeToRead={totalTimeToRead.text} />}
        {textType === 'BOOK' && pageNumber && <TextReaderLowerPages />}
      </Box>

      <Box className={classes.readerControlsHolder}>
        {textType === 'BOOK' && <BookPageControl />}
        <TextReaderControls totalTimeToRead={totalTimeToRead.ms} />
      </Box>
    </div>
  );
};
