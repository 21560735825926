import { firestoreToPlainObjectMap } from '@read4speed/firebase-utils';
import { TextQuotesModal } from '@read4speed/models/src';
import * as uuid from 'uuid';

import { collections, firestoreTimeStampNow } from '../firebase';

export class QuotesService {
  static async create(
    data: Omit<TextQuotesModal, 'timestamp' | 'uid'>
  ): Promise<FirebaseFirestore.DocumentReference<TextQuotesModal>> {
    return await collections.textQuotesRef().add({
      ...data,
      uid: uuid.v4(),
      timestamp: firestoreTimeStampNow(),
    });
  }

  static async getByTextRefIds(
    textRefIds: string[]
  ): Promise<TextQuotesModal[] | null> {
    const snapshot = await collections
      .textQuotesRef()
      .where('textRefId', 'in', textRefIds)
      .get();

    if (snapshot.empty) return null;

    return firestoreToPlainObjectMap(snapshot.docs);
  }

  static async update(
    id: string,
    data: Partial<TextQuotesModal>
  ): Promise<void> {
    await collections
      .textQuotesRef()
      .doc(id)
      .update({
        ...data,
        timestamp: firestoreTimeStampNow(),
      });
  }

  static async delete(id: string): Promise<void> {
    await collections.textQuotesRef().doc(id).delete();
  }
}
