import { FirestoreModelBase } from '@read4speed/models';
import orderBy from 'lodash/orderBy';
import transform from 'lodash/transform';
import uniqBy from 'lodash/uniqBy';

import { ICollectionState } from './ICollectionState';

export const acceptCollectionStateData = <TData extends FirestoreModelBase>(
  state: ICollectionState<TData>,
  data: TData[],
  sorting?: { key: keyof TData; order: 'asc' | 'desc' }
): void => {
  state.loaded = true;
  state.loading = false;

  if (sorting) {
    const allData = uniqBy(data.concat(Object.values(state.byId)), 'id');
    const sortedData = orderBy(allData, sorting.key, sorting.order);

    state.ids = [];

    transform(
      sortedData,
      (state, item) => {
        if (!item.id) return;
        state.ids.push(item.id);
        state.byId[item.id] = item;
      },
      state
    );
  } else {
    data.forEach(d => {
      if (d.id && !state.ids.includes(d.id)) {
        state.byId[d.id] = d;
        if (!state.ids.includes(d.id)) state.ids.unshift(d.id);
      }
    });
  }
};
