import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserSettings } from 'src/components/AppMenuDrawer/UserSettings';
import FullLogoIcon from 'src/components/Icons/fullLogo';
import LightningIcon from 'src/components/Icons/lightning';
import SettingsIcon from 'src/components/Icons/settings';
import { ReaderSettings } from 'src/components/ReaderSettings/ReaderSettings';
import { uiActions } from 'src/redux/ui';
import { colors } from 'src/styles/colors';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { useAuthenticated } from '../../redux/auth/selectors';
import { useSavedQuotesListOpened } from '../../redux/ui/selectors';
import { useAuthorizedUserProfile } from '../../redux/userProfiles/selectors';
import { SavedQuotesList } from '../AppMenuDrawer/SavedQuotesList';
import { SavedWordsList } from '../AppMenuDrawer/SavedWordsList';
import { useStyles } from './styles/topBar';

interface TopBarProps {}

export const TopBar: FC<TopBarProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useAuthorizedUserProfile();
  const isAuthenticated = useAuthenticated();

  const isSavedQuotesOpened = useSavedQuotesListOpened();

  const exitClickHandler = useCallback(() => {
    history.push(APP_ROUTES_CONFIG.HOME);
  }, []);

  const openReaderSettings = useCallback(() => {
    dispatch(uiActions.openReaderSettings());
  }, []);

  return (
    <Box className={classes.root}>
      {isAuthenticated && profile ? (
        <Box position="relative">
          <UserSettings profile={profile} />
          <SavedWordsList profile={profile} />
          {isSavedQuotesOpened && <SavedQuotesList />}
        </Box>
      ) : (
        <div />
      )}

      <IconButton
        onClick={exitClickHandler}
        className={classes.logo}
        aria-label="exit"
      >
        <FullLogoIcon />
        <LightningIcon />
      </IconButton>

      {isAuthenticated && (
        <>
          <IconButton aria-label="open settings" onClick={openReaderSettings}>
            <SettingsIcon color={colors.greyBase} />
          </IconButton>
          <ReaderSettings />
        </>
      )}
    </Box>
  );
};
