import { useEffect, useState } from 'react';

type HTMLNullableElement = HTMLElement | null;

const useScrollIntoView = (
  selector: string,
  dependencies: unknown[]
): HTMLNullableElement => {
  const [element, setElement] = useState<HTMLNullableElement>(null);
  useEffect(() => {
    if (!element) {
      const el: HTMLNullableElement = document.querySelector(selector);
      if (el) {
        setElement(el);
      }
    }
    if (element) {
      element.scrollIntoView({ block: 'center' });
    }
  }, dependencies);

  return element;
};

export default useScrollIntoView;
