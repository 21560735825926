import { combineReducers } from '@reduxjs/toolkit';

import { authReducer } from './auth';
import { bookSlice } from './book';
import { diagnosticReducer } from './diagnostic';
import { libraryReducer } from './library';
import { readerReducer } from './reader';
import { readingHistoryReducer } from './readingHistory';
import { textReducer } from './text';
import { quotesReducer } from './textQuotes/textQuotesSlice';
import { uiReducer } from './ui';
import { userProfilesReducer } from './userProfiles';

const rootReducer = combineReducers({
  book: bookSlice.reducer,
  text: textReducer,
  reader: readerReducer,
  ui: uiReducer,
  auth: authReducer,
  userProfiles: userProfilesReducer,
  readingHistory: readingHistoryReducer,
  library: libraryReducer,
  diagnostic: diagnosticReducer,
  quotes: quotesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
