import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ReadingHistoryItemModel } from '@read4speed/models';
import React, { FC } from 'react';
import BookIcon from 'src/components/Icons/book';
import LightningIcon from 'src/components/Icons/lightning';

interface ReadingHistoryIconProps {
  item: ReadingHistoryItemModel;
}

const useStyles = makeStyles(theme =>
  createStyles({
    image: { width: 26, height: 26, borderRadius: 4 },
  })
);

export const ReadingHistoryItemIcon: FC<ReadingHistoryIconProps> = ({
  item,
}) => {
  const { type, thumbSrc } = item;
  const classes = useStyles();

  switch (type) {
    case 'TEXT':
      return <BookIcon />;
    case 'ARTICLE':
      return thumbSrc ? (
        <img className={classes.image} src={thumbSrc} />
      ) : (
        <LightningIcon />
      );
    case 'BOOK':
    default:
      return <LightningIcon />;
  }
};
