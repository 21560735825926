import { ReadingHistoryItemModel } from '@read4speed/models';
import { omit } from 'lodash';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { ICollectionState } from '../../utils';

type ReadingHistoryState = Omit<
  ICollectionState<ReadingHistoryItemModel>,
  'ids' | 'byId'
>;

export const useReadingHistoryState = (): ReadingHistoryState => {
  return useSelector<RootState, ReadingHistoryState>(state =>
    omit(state.readingHistory.collection, ['ids', 'byId'])
  );
};
