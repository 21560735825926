import { ThemeProvider } from '@material-ui/core';
import React, { FC } from 'react';

import { useMuiTheme } from './useMuiThemes';

interface AppThemeProps {}

export const AppTheme: FC<AppThemeProps> = ({ children }) => {
  const theme = useMuiTheme();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
