/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

interface LoginIllustrationProps {}

const LoginIllustration: FC<LoginIllustrationProps> = (): ReactElement => (
  <svg
    width="417"
    height="364"
    viewBox="0 0 417 364"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_0_96)">
      <path
        d="M245.006 339.663H95.6224C92.8893 339.663 90.183 339.125 87.6579 338.079C85.1329 337.033 82.8386 335.5 80.906 333.568C78.9734 331.635 77.4404 329.341 76.3945 326.816C75.3486 324.291 74.8103 321.584 74.8103 318.851V317.194C74.8103 312.01 76.8694 307.039 80.5345 303.374C84.1997 299.709 89.1707 297.65 94.3541 297.65H245.017V339.663H245.006Z"
        fill="#263D88"
      />
      <path
        d="M407.333 339.663H237.187C231.797 339.663 226.628 337.522 222.817 333.711C219.006 329.9 216.865 324.731 216.865 319.341V317.519C216.865 312.253 218.957 307.201 222.681 303.477C226.406 299.753 231.457 297.661 236.724 297.661H407.333V339.663Z"
        fill="white"
      />
      <path
        d="M205.571 339.663H413.149C414.817 339.663 416.168 338.312 416.168 336.645V336.399C416.168 334.732 414.817 333.38 413.149 333.38H205.571C203.904 333.38 202.553 334.732 202.553 336.399V336.645C202.553 338.312 203.904 339.663 205.571 339.663Z"
        fill="#263D88"
      />
      <path
        d="M205.571 303.933H413.149C414.817 303.933 416.168 302.582 416.168 300.915V300.669C416.168 299.001 414.817 297.65 413.149 297.65H205.571C203.904 297.65 202.553 299.001 202.553 300.669V300.915C202.553 302.582 203.904 303.933 205.571 303.933Z"
        fill="#263D88"
      />
      <path
        d="M407.332 318.655L384.24 319.362L407.332 319.789V318.655Z"
        fill="#E1E1E1"
      />
      <path
        d="M407.332 308.988L352.397 309.692L407.332 310.119V308.988Z"
        fill="#E1E1E1"
      />
      <path
        d="M352.961 309.555C352.961 309.555 355.318 311.358 356.096 314.321C356.241 314.863 356.322 315.42 356.34 315.981C356.47 319.924 356.34 328.847 356.34 328.847L363.291 324.385L370.231 328.842V316.746C370.231 314.461 369.635 312.245 368.531 310.781C368.001 310.074 367.345 309.544 366.611 309.544L352.961 309.555Z"
        fill="#E85A24"
      />
      <path
        d="M352.961 309.555C352.961 309.555 355.318 311.358 356.096 314.321L370.027 314.498C370.027 314.498 369.479 310.249 366.606 309.549L352.961 309.555Z"
        fill="#D14A27"
      />
      <path
        d="M60.1226 235.9H191.607C197.45 235.9 203.054 238.221 207.186 242.353C211.318 246.485 213.639 252.089 213.639 257.932V273.2C213.639 276.159 213.057 279.089 211.924 281.823C210.792 284.557 209.132 287.041 207.04 289.133C204.947 291.226 202.463 292.885 199.729 294.018C196.996 295.15 194.065 295.733 191.106 295.733H60.1226V235.892V235.9Z"
        fill="white"
      />
      <path
        d="M328.13 260.159V269.795C328.161 274.645 327.203 279.451 325.315 283.918C321.719 292.264 315.031 297.875 307.371 297.875H56.0133C54.3478 297.875 53 296.051 53 293.805V292.36C53 290.114 54.3478 288.29 56.0133 288.29H204.944C206.327 288.299 207.68 287.891 208.828 287.12C209.975 286.348 210.864 285.25 211.378 283.966C211.383 283.95 211.39 283.934 211.397 283.918C212.901 280.009 213.662 275.853 213.642 271.664V259.415C213.646 257.226 213.437 255.042 213.02 252.893C213.02 252.856 213.007 252.822 213.002 252.788C212.46 250.125 211.012 247.732 208.905 246.016C206.798 244.3 204.161 243.368 201.444 243.377H56.0133C54.3478 243.377 53 241.553 53 239.302V237.862C53 235.611 54.3478 233.789 56.0133 233.789H308.647C317.552 233.789 325.064 241.863 327.391 252.893C327.89 255.283 328.137 257.718 328.13 260.159Z"
        fill="#693996"
      />
      <path
        d="M60.1226 265.836L78.7343 266.914L60.1226 267.563V265.836Z"
        fill="#E1E1E1"
      />
      <path
        d="M60.1226 275.893L104.403 276.97L60.1226 277.619V275.893Z"
        fill="#E1E1E1"
      />
      <path
        d="M401.499 233.535L233.019 233.535C227.042 233.535 221.309 231.16 217.082 226.934C212.855 222.707 210.481 216.974 210.481 210.997V204.038C210.481 198.193 212.803 192.588 216.935 188.455C221.068 184.322 226.674 182 232.519 182L401.499 182V233.535Z"
        fill="white"
      />
      <path
        d="M210.396 202.01V213.102C210.413 214.769 210.813 216.41 211.566 217.897C212.89 220.545 215.236 222.732 218.186 224.141C220.59 225.263 223.212 225.841 225.865 225.832H406.525C408.643 225.832 410.359 227.265 410.359 229.031V230.355C410.359 232.121 408.64 233.554 406.525 233.554H94.4599C85.5049 233.554 77.5931 229.847 72.8138 224.154C69.7979 220.569 68.0317 216.21 68.0317 211.489V202.751C68.0393 199.585 68.8902 196.477 70.4969 193.749C74.5084 186.819 83.008 182.042 92.8447 182.042H406.525C408.643 182.042 410.359 183.472 410.359 185.238V186.546C410.359 188.315 408.64 189.748 406.525 189.748H225.327C220.998 189.748 217.079 191.289 214.341 193.749C213.178 194.784 212.219 196.028 211.513 197.416C210.798 198.843 210.416 200.414 210.396 202.01Z"
        fill="#15A44A"
      />
      <path
        d="M401.496 207.769L378.338 208.635L401.496 209.156V207.769Z"
        fill="#E1E1E1"
      />
      <path
        d="M401.496 195.907L346.405 196.775L401.496 197.297V195.907Z"
        fill="#E1E1E1"
      />
      <path
        d="M346.972 196.603C346.972 196.603 350.608 198.237 351.812 200.922C352.031 201.395 352.158 201.905 352.188 202.426C352.39 205.995 352.188 214.076 352.188 214.076L362.925 210.038L373.631 214.076V203.13C373.636 202.089 373.402 201.06 372.947 200.124C372.492 199.187 371.828 198.368 371.007 197.728C370.171 197.034 369.127 196.639 368.041 196.608L346.972 196.603Z"
        fill="#E85A24"
      />
      <path
        d="M346.972 196.603C346.972 196.603 350.608 198.237 351.812 200.922L373.324 201.083C373.324 201.083 372.482 197.236 368.041 196.603H346.972Z"
        fill="#D14A27"
      />
    </g>
    <g clipPath="url(#clip1_0_96)">
      <path
        d="M15.6646 125.097C15.6646 125.097 19.1379 140.168 29.2634 140.168C37.9408 140.168 49.8373 120.551 49.8373 120.551L15.6646 125.097Z"
        fill="#E85A24"
      />
      <path
        d="M131.354 349.074L125.541 352.16C125.541 352.16 94.1276 318.846 86.5718 286.88C85.2284 281.172 84.6798 275.54 85.2567 270.199C85.2567 270.199 84.8008 268.755 84.0021 266.193C83.9658 266.08 83.9295 265.967 83.8972 265.85C83.5462 264.729 83.1266 263.406 82.6587 261.913C81.1459 257.072 79.0603 250.472 76.6842 242.953C71.218 225.635 58.7124 186.577 53.3672 170.339L91.7758 156.446C91.7758 156.446 96.1406 166.394 102.41 193.435C103.374 196.964 104.342 200.865 105.233 205.133C106.258 209.974 107.214 215.219 108.009 220.907C109.5 231.637 110.269 242.455 110.312 253.288C110.312 258.129 110.216 263.144 109.909 268.319C109.699 272.115 110.264 277.194 111.321 282.927C116.194 309.201 131.354 349.074 131.354 349.074Z"
        fill="#374151"
      />
      <path
        d="M152.383 363.794H121.321C121.321 363.794 120.159 358.84 122.2 355.468C124.004 352.486 123.314 350.788 121.974 348.319C123.627 349.036 125.419 349.372 127.219 349.304C128.764 349.215 130.402 348.497 131.838 347.69C133.173 349.215 138.897 355.698 140.685 356.968C142.71 358.441 151.758 357.916 152.383 363.794Z"
        fill="#1E2836"
      />
      <path
        d="M108.021 220.878L83.8972 265.858C83.5462 264.737 83.1266 263.414 82.6587 261.921C81.1459 257.08 79.0603 250.481 76.6842 242.961C71.218 225.643 58.7124 186.585 53.3672 170.347L91.7758 156.454C91.7758 156.454 96.1406 166.402 102.41 193.443C103.374 196.972 104.342 200.873 105.233 205.141C106.27 209.918 107.238 215.182 108.021 220.878Z"
        fill="#1E2836"
      />
      <path
        d="M52.7419 354.072C52.7419 354.072 50.5917 359.344 52.6168 363.794H69.5075C69.5075 363.794 69.8585 359.78 63.7267 355.133C59.0875 351.611 52.7419 354.072 52.7419 354.072Z"
        fill="#1E2836"
      />
      <path
        d="M71.1252 10.7024C71.1252 9.86335 71.5649 0.794717 85.3413 -0.0121002C85.3413 -0.0121002 78.5802 0.960113 77.4829 3.96954C77.4829 3.96954 82.0092 0.177499 85.7972 1.27074C89.5852 2.36397 91.9532 8.89517 92.0258 11.0615C92.0984 13.2278 90.1782 20.2632 87.8102 20.9127C86.8607 21.1307 85.877 21.1554 84.9178 20.9853C85.6238 22.0353 86.2689 23.125 86.8501 24.2489C92.6228 35.4072 87.6569 48.5543 82.9572 47.4449C79.9518 46.7349 78.1163 51.9469 78.1163 51.9469L49.208 27.0889C49.208 27.0889 59.3416 4.78846 71.1252 10.7024Z"
        fill="#263D88"
      />
      <path
        d="M52.9517 86.2488C56.1789 88.4716 60.1767 90.3071 65.2193 91.4931C84.1311 95.862 91.3804 79.879 94.0994 68.9022C92.1246 67.8065 89.9425 67.1359 87.6932 66.9336C86.3257 66.7722 84.8694 66.6633 83.6995 66.6068C81.5775 64.9932 78.4108 58.3248 77.7129 47.9855L76.8213 48.2275L61.9194 52.306C61.9194 52.306 62.6012 57.042 62.936 61.5198C63.1942 65.0255 63.2345 68.3778 62.5729 69.1846C61.4071 69.4387 60.7092 69.588 60.7092 69.588C57.5978 74.8814 55.0001 80.4604 52.9517 86.2488Z"
        fill="#FAB17E"
      />
      <path
        d="M61.915 52.2898C61.915 52.2898 62.6008 57.0259 62.9357 61.5077C71.379 60.6606 75.2961 52.6327 76.821 48.1952L61.915 52.2898Z"
        fill="#EE9960"
      />
      <path
        d="M61.7458 59.4019C61.7458 59.4019 81.1861 54.4077 77.8822 42.3458C74.5783 30.2839 74.8728 21.5219 62.3227 24.1924C49.7727 26.863 48.6068 32.1315 48.748 36.4238C48.8892 40.716 56.6024 60.3903 61.7458 59.4019Z"
        fill="#FAB17E"
      />
      <path
        d="M47.4893 29.5699C49.4821 25.1324 51.9631 25.2978 53.5404 26.04C62.5203 17.3224 71.6938 24.0472 71.6938 24.0472C75.3648 32.3413 82.965 35.5847 82.965 35.5847C82.965 35.5847 80.0363 38.7313 71.3267 38.8725C68.6682 38.9168 65.9654 37.7591 63.4925 36.1373C66.6016 39.0489 70.0921 41.5243 73.8682 43.4955C62.9237 41.6721 55.3114 29.231 54.5328 27.9119C53.6412 29.2955 51.9469 32.2404 51.8541 34.6932C51.7331 38.0293 53.0362 44.4678 51.1361 44.7582C51.128 44.7582 44.496 36.2422 47.4893 29.5699Z"
        fill="#263D88"
      />
      <path
        d="M56.1545 68.5916C56.1545 68.5916 88.8023 60.6646 102.998 72.3756L95.9064 171.324L32.0508 155.897C32.0508 155.897 33.846 149.636 46.0894 132.984C58.3329 116.331 53.0079 91.9126 56.1545 68.5916Z"
        fill="#FAB17E"
      />
      <path
        d="M75.1875 160.307L75.0826 161.283C75.053 161.552 75.0167 161.883 74.9737 162.275C74.6993 164.841 74.1668 169.762 73.5093 176.161C71.7585 192.942 69.0072 219.894 67.1354 241.17C65.667 257.802 64.7392 270.97 65.2394 273.084C66.7522 279.441 65.2394 355.484 65.2394 355.484C59.8136 358.09 51.584 355.484 51.584 355.484C51.584 355.484 37.1299 286.961 39.1509 277.436C40.3275 271.539 39.86 265.432 37.7995 259.783C26.5565 207.509 27.8031 178.52 30.0339 164.667C30.4602 161.799 31.1021 158.967 31.9541 156.196C32.1656 155.487 32.4216 154.791 32.7206 154.114C57.4819 147.458 75.1875 160.307 75.1875 160.307Z"
        fill="#374151"
      />
      <path
        d="M107.403 100.594C105.826 112.499 99.5209 127.066 97.2658 134.908C97.2658 134.908 101.034 162.13 97.4393 169.601C97.1504 170.338 96.6064 170.946 95.9063 171.316C82.5939 177.117 30.7397 171.485 28.5371 154.776C27.7303 148.696 48.3041 127.32 49.4861 124.726C49.9823 123.653 50.5108 120.175 48.1024 112.967C44.6896 123.491 25.8423 120.466 25.8423 120.466C28.053 116.682 33.5635 90.4967 39.7196 78.6768C44.3426 69.8018 56.695 68.5069 56.695 68.5069C56.695 68.5069 61.8385 76.6598 74.2675 75.502C86.6965 74.3442 88.9758 66.7359 88.9758 66.7359C88.9758 66.7359 100.001 68.2003 103.402 70.9435C104.011 71.4033 109.469 84.9982 107.403 100.594Z"
        fill="#E85A24"
      />
      <path
        d="M97.266 134.908C97.266 134.908 101.034 162.13 97.4394 169.601C87.7576 164.732 75.0946 154.219 78.9432 137.796C84.5304 113.939 107.404 100.59 107.404 100.59C105.826 112.499 99.5211 127.066 97.266 134.908Z"
        fill="#D14A27"
      />
      <path
        opacity="0.8"
        d="M69.5395 86.2755C69.65 86.1478 69.7982 86.0585 69.9628 86.0205C70.1274 85.9824 70.2998 85.9976 70.4551 86.0639L79.0128 89.7149C79.1322 89.7659 79.2374 89.8453 79.3192 89.9462C79.401 90.0471 79.4569 90.1664 79.482 90.2938C79.5072 90.4212 79.5009 90.5529 79.4636 90.6773C79.4263 90.8017 79.3593 90.9151 79.2682 91.0077L74.4784 95.873L79.5152 98.0219C79.6578 98.0827 79.7795 98.1836 79.8656 98.3125C79.9517 98.4413 79.9984 98.5924 80 98.7474C80.0015 98.9023 79.958 99.0544 79.8745 99.185C79.7911 99.3155 79.6715 99.419 79.5302 99.4827L59.1264 108.697C58.9648 108.77 58.7834 108.787 58.6108 108.746C58.4383 108.704 58.2846 108.606 58.174 108.468C58.0633 108.329 58.0021 108.157 58.0001 107.98C57.998 107.802 58.0551 107.629 58.1625 107.488L64.164 99.5651L60.7019 98.088C60.5864 98.0387 60.4842 97.9628 60.4036 97.8666C60.3229 97.7703 60.2662 97.6563 60.2379 97.534C60.2097 97.4116 60.2107 97.2843 60.241 97.1624C60.2712 97.0405 60.3298 96.9275 60.412 96.8326L69.5395 86.2755Z"
        fill="white"
      />
      <path
        d="M56.7719 102.716L79.0038 138.381H41.1762L19.9932 103.486L56.7719 102.716Z"
        fill="#263D88"
      />
      <path
        d="M77.8501 136.59L78.2333 135.348L57.1956 101.59L56.772 102.716L77.8501 136.59Z"
        fill="#EFDECE"
      />
      <path
        d="M57.1955 101.59L21.5584 102.607L19.9932 103.486L56.7719 102.716L57.1955 101.59Z"
        fill="#FBEBDB"
      />
      <path
        d="M19.9929 103.486L0 92.663L23.3049 129.26L41.1759 138.381L19.9929 103.486Z"
        fill="#263979"
      />
      <path
        d="M125.795 128.498C123.653 129.478 118.158 135.953 116.194 136.61C107.375 139.559 69.834 131.705 69.834 131.705L70.9958 125.129C70.9958 125.129 107.843 126.11 111.893 125.024C112.656 124.819 116.637 118.594 118.166 117.699L125.795 128.498Z"
        fill="#FAB17E"
      />
      <path
        d="M70.9918 125.113C70.9918 125.113 66.5543 121.483 63.5449 122.314C60.5355 123.145 56.1262 126.929 54.4843 127.828C52.8425 128.728 48.3888 131.943 50.6479 133.879C52.907 135.816 59.9263 134.158 62.726 132.669C65.5257 131.18 69.83 131.689 69.83 131.689L70.9918 125.113Z"
        fill="#FAB17E"
      />
      <path
        d="M64.3961 122.197C64.3961 122.197 58.9985 121.555 57.5381 121.583C56.0778 121.612 56.4651 121.934 57.2759 123.08C58.0868 124.226 60.9066 124.661 61.0316 125.061C61.1567 125.46 64.3961 122.197 64.3961 122.197Z"
        fill="#FAB17E"
      />
      <path
        d="M88.9877 66.7238C88.9877 66.7238 105.003 68.6239 109.412 75.4899C114.987 84.1753 128.143 118.755 127.719 127.469C127.719 127.469 118.102 143.843 85.111 141.221C85.111 141.221 81.6779 129.119 88.6206 125.085C88.6206 125.085 105.564 122.071 107.512 120.353L101.865 96.8262L88.9877 66.7238Z"
        fill="#E85A24"
      />
      <path
        d="M25.1161 116.525C24.6199 114.508 20.2187 115.77 18.7382 116.928C18.3003 117.314 17.9199 117.761 17.6086 118.255C17.5642 117.916 17.5239 117.594 17.5038 117.344C17.419 116.396 16.2935 114.697 16.0394 115.968C15.7852 117.239 15.3132 122.58 15.3132 122.58C15.2608 126.88 18.0282 128.655 19.8032 127.255C21.5782 125.855 25.6123 118.542 25.1161 116.525Z"
        fill="#FAB17E"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_96">
        <rect
          width="363.168"
          height="157.663"
          fill="white"
          transform="translate(53 182)"
        />
      </clipPath>
      <clipPath id="clip1_0_96">
        <rect width="152.384" height="363.79" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LoginIllustration;
