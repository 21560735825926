import { readerActions } from '../reader';
import { AppThunk } from '../store';
import { textActions } from '../text';
import { getCollectionItems } from '../utils';

export const readPrevPageAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { pages: pagesCollection } = state.book;
  const { pageNumber } = state.text;

  if (pageNumber < 2) return;

  const pages = getCollectionItems(pagesCollection);
  const prevPage = pages.find(p => p.number === pageNumber - 1);

  if (!prevPage) throw new Error('unexpected empty nextPage');

  dispatch(textActions.acceptText(prevPage.text));
  dispatch(textActions.setPageNumber(prevPage.number));
  setTimeout(() => dispatch(readerActions.setPosition(0)), 0);
};
