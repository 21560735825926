import { authActions } from '../auth';
import { readingHistoryActions } from '../readingHistory';
import { AppThunk } from '../store';

export const resetStoreAction = (): AppThunk => dispatch => {
  dispatch(authActions.reset());
  dispatch(readingHistoryActions.reset());
  // dispatch(textActions.reset());
  // dispatch(readerActions.reset());
};
