import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles(theme =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '28px',
    },
    textInput: {
      margin: '16px 0 12px',
      width: '100%',
      '& > div': {
        padding: '16px',
        border: `1px solid ${colors.grey300}`,
        borderRadius: '12px',
      },
    },
  })
);
