import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { colors } from 'src/styles/colors';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import EnvelopeIcon from '../../components/Icons/envelope';
import GoogleIcon from '../../components/Icons/google';
import LockIcon from '../../components/Icons/lock';
import {
  authActions,
  signInWithEmailAndPasswordAction,
  signInWithGoogle,
} from '../../redux/auth';
import { useAuthError } from '../../redux/auth/selectors';
import { CustomInput } from '../../styles/customInput';
import { SubmitButton } from '../../styles/submitButton';
import { useStyles } from './styles/signinForm';

interface SigninFormFields {
  email: string;
  password: string;
}

interface SigninFormProps {}

export const SigninForm: FC<SigninFormProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authError = useAuthError();
  const classes = useStyles();
  const [isPersist, setPersist] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { register, handleSubmit, formState } = useForm<SigninFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { red1, red2 } = colors;
  const isAuthError = authError && authError.type === 'all';
  const isEmailError = authError && authError.type === 'email';
  const isPasswordError = authError && authError.type === 'password';

  const authErrorMessage = isAuthError ? authError?.message : '';
  const emailErrorMessage = isEmailError ? authError?.message : emailError;
  const passwordErrorMessage = isPasswordError
    ? authError?.message
    : passwordError;
  const isEmailInvalid = emailErrorMessage || isAuthError;
  const isPasswordInvalid = passwordErrorMessage || isAuthError;
  const isError = !!(isEmailInvalid || isPasswordInvalid);

  useEffect(() => {
    return () => {
      dispatch(authActions.setError(null));
    };
  }, []);

  const submitHandler = handleSubmit(({ email, password }) => {
    if (!email || !password) return;
    dispatch(signInWithEmailAndPasswordAction(email, password, isPersist));
  });

  const handleGoogleSignIn = (): void => {
    dispatch(signInWithGoogle(isPersist));
  };

  const goSignup = useCallback(() => {
    history.push(APP_ROUTES_CONFIG.SIGNUP);
  }, []);

  const goResetPassword = useCallback(() => {
    history.push(APP_ROUTES_CONFIG.PASSWORD_RESET);
  }, []);

  const onEmailChange = useCallback((): void => {
    if (isEmailInvalid) {
      setEmailError('');
      dispatch(authActions.setError(null));
    }
  }, [isEmailInvalid]);

  const onPasswordChange = useCallback((): void => {
    if (isPasswordInvalid) {
      setPasswordError('');
      dispatch(authActions.setError(null));
    }
  }, [isPasswordInvalid]);

  const togglePersist = (e: any): void => {
    setPersist(e.target.checked);
  };

  return (
    <div className={classes.root}>
      <form onSubmit={submitHandler}>
        <CustomInput
          onChange={onEmailChange}
          className={isEmailInvalid ? 'invalid' : ''}
          inputRef={register({
            required: true,
            validate: value => {
              if (!/^.+\@.+\..+$/.test(value)) {
                setEmailError('The email address is invalid.');
              } else {
                setEmailError('');
                return true;
              }
            },
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EnvelopeIcon color={isEmailInvalid ? red2 : red1} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          required
          fullWidth
          id="email"
          placeholder="Email Address *"
          name="email"
          autoComplete="email"
          autoFocus
        />
        {emailErrorMessage ? (
          <Alert className={classes.alert} severity="error">
            {emailErrorMessage}
          </Alert>
        ) : null}

        <CustomInput
          onChange={onPasswordChange}
          className={isPasswordInvalid ? 'invalid' : ''}
          inputRef={register({ required: true })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color={isPasswordInvalid ? red2 : red1} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          required
          fullWidth
          name="password"
          placeholder="Password *"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        {isAuthError || passwordErrorMessage ? (
          <Alert className={classes.alert} severity="error">
            {isAuthError ? authErrorMessage : passwordErrorMessage}
          </Alert>
        ) : null}
        <div className={classes.controls}>
          <FormGroup>
            <FormControlLabel
              classes={{ label: 'label' }}
              control={
                <Checkbox
                  className="checkbox"
                  onChange={togglePersist}
                  checked={isPersist}
                />
              }
              label="Remember Me"
            />
          </FormGroup>
          <a className={classes.link} onClick={goResetPassword}>
            Forgot Password?
          </a>
        </div>

        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={!formState.isValid || isError}
          className={classes.submitBtn}
        >
          Sign In
        </SubmitButton>
        <div className={classes.divider}>
          <hr />
          <span>or</span>
          <hr />
        </div>
      </form>
      <Button
        type="submit"
        fullWidth
        className={clsx(classes.submitBtn, classes.googleSignIn)}
        onClick={handleGoogleSignIn}
      >
        <GoogleIcon />
        Sign In With Google
      </Button>
      <div className={classes.signup}>
        <span>Don&#39;t have an account?</span>
        <a className={classes.link} onClick={goSignup}>
          Create Account
        </a>
      </div>
    </div>
  );
};
