import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { uiActions } from 'src/redux/ui';

import { useAuthenticated, useIsAdmin } from '../../redux/auth/selectors';
import { readArticleAction } from '../../redux/commonActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },

    busy: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(8),
      fontSize: '2rem',
    },

    buttonsHolder: {
      boxShadow: '0 5px 30px rgb(0 0 0 / 10%)',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);

interface HomePageProps {}

export const ArticleHookPage: FC<HomePageProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const authenticated = useAuthenticated();
  const isAdmin = useIsAdmin();

  const toggleBookshelf = useCallback(() => {
    dispatch(uiActions.toggleBookshelf());
  }, []);

  const toggleAppMenu = useCallback(() => {
    dispatch(uiActions.toggleAppMenu());
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const articleId = params.get('articleId') || '';
    const articleUri = params.get('article') || '';
    if (articleUri || articleId) {
      dispatch(
        readArticleAction(decodeURI(articleUri), history, decodeURI(articleId))
      );
    }
  }, [location.search]);

  return (
    <Container maxWidth={'md'} className={classes.container}>
      <Box className={classes.buttonsHolder}>
        {!isAdmin && <div />}
        {authenticated && isAdmin && (
          <IconButton
            aria-label="exit"
            color={'secondary'}
            onClick={toggleBookshelf}
          >
            <MenuBookIcon fontSize={'default'} />
          </IconButton>
        )}
        <IconButton
          aria-label="stop text to speech"
          color={'secondary'}
          onClick={toggleAppMenu}
        >
          <MenuIcon fontSize={'default'} />
        </IconButton>
      </Box>

      <Box className={classes.busy}>
        <Typography variant={'h4'} color={'textSecondary'}>
          Loading...
        </Typography>
      </Box>
    </Container>
  );
};
