import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateSavedWords = (
  id: string,
  savedWords: string[]
): AppThunk => async dispatch => {
  await UserProfilesService.updateSavedWords(id, savedWords);
  dispatch(
    userProfilesActions.update({
      id,
      data: { savedWords },
    })
  );
};
