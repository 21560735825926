import { Button } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { colors } from 'src/styles/colors';

import useOutsideClick from '../../hooks/useOutsideClick';
import { useStyles } from './styles/readerSettings';

interface ReaderSettingsColorPickerProps {
  color?: string;
  onChange: (color: string) => void;
}

const DEFAULT_COLOR = colors.cursorColor;

const fromHash = (value: string): string => value.replace(/#/, '');

export const ReaderSettingsColorPicker: FC<ReaderSettingsColorPickerProps> = ({
  color = DEFAULT_COLOR,
  onChange,
}) => {
  const classes = useStyles();
  const [localValue, setLocalValue] = useState(color);
  const [isVisible, setVisible] = useState(false);
  const previewBtn = useRef(null);
  const textBtn = useRef(null);

  function closePicker(): void {
    setVisible(false);
  }

  useOutsideClick(previewBtn, textBtn, closePicker);

  useEffect(() => {
    onChange(localValue);
  }, [localValue]);

  const onClick = useCallback(() => {
    setVisible(!isVisible);
  }, [isVisible]);

  return (
    <label className={clsx(classes.row, classes.colorPicker)}>
      <span>Highlight Color</span>
      <Button
        ref={textBtn}
        className={clsx(classes.settingsBtn, classes.colorText)}
        onClick={onClick}
      >
        <span>
          <span className="color-hash">#</span> {fromHash(localValue)}
        </span>
      </Button>
      <Button
        ref={previewBtn}
        className={classes.colorPreview}
        style={{ backgroundColor: color }}
        onClick={onClick}
      />

      {isVisible && (
        <HexColorPicker color={localValue} onChange={setLocalValue} />
      )}
    </label>
  );
};
