import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ChromeIcon from 'src/components/Icons/chrome';
import PlusIcon from 'src/components/Icons/plus';
import { uiActions } from 'src/redux/ui';
import { colors } from 'src/styles/colors';
import { CustomButton } from 'src/styles/customButton';
import { CustomIconButton } from 'src/styles/customIconButton';
import { SubmitButton } from 'src/styles/submitButton';
import { openExtensionUrl } from 'src/utils';

import { ReadingHistoryList } from '../../components/ReadingHistoryList';
import { TopBar } from '../../components/TopBar';
import { useAuthenticated } from '../../redux/auth/selectors';
import { loadReadingHistoryAction } from '../../redux/readingHistory';
import { useReadingHistoryItems } from '../../redux/readingHistory/selectors';
import { useReadingHistoryState } from '../../redux/readingHistory/selectors/useReadingHistoryState';
import { useStyles } from './styles/homePage';

interface HomePageProps {}

export const HomePage: FC<HomePageProps> = () => {
  const dispatch = useDispatch();
  const historyLen = useReadingHistoryItems().length;
  const { loaded: historyLoaded } = useReadingHistoryState();
  const isAuthenticated = useAuthenticated();
  const classes = useStyles();

  useEffect(() => {
    dispatch(loadReadingHistoryAction());
    const mainNode = document.querySelector('main');
    if (mainNode) mainNode.style.background = colors.grey100;

    return () => {
      if (mainNode) mainNode.style.background = '';
    };
  }, []);

  const toggleAppMenu = useCallback(() => {
    dispatch(uiActions.toggleAppMenu());
  }, []);

  return (
    <Container className={classes.root}>
      <TopBar />

      {isAuthenticated && historyLen > 0 && (
        <>
          <header className={classes.header}>
            <h1>Your Reading History</h1>

            <CustomIconButton
              onClick={toggleAppMenu}
              className={classes.addTextBtn}
            >
              <PlusIcon color="white" />
            </CustomIconButton>
          </header>
          {historyLen > 0 && <ReadingHistoryList />}
        </>
      )}

      {isAuthenticated && historyLoaded && historyLen == 0 && (
        <Box className={classes.emptyHistory}>
          <Box>
            <h1>Start to read different</h1>
            <p>
              Quisque quis sagittis ipsum. Aliquam convallis ex neque, non
              efficitur dui ultricies at. Proin et elit lectus
            </p>
            <Box className={classes.emptyHistoryBtns}>
              <SubmitButton variant="contained" onClick={openExtensionUrl}>
                <ChromeIcon color="white" />
                <span>Download Chrome Extention</span>
              </SubmitButton>
              <CustomButton variant="contained" onClick={toggleAppMenu}>
                <span>Add Text</span>
              </CustomButton>
            </Box>
          </Box>
          <img alt="Desk Lamp" src="/DeskLamp.png" />
        </Box>
      )}
    </Container>
  );
};
