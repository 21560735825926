/* eslint-disable max-len */
import React, { ReactElement } from 'react';

const LightningIcon = (): ReactElement => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4711 0.246588C10.5705 0.131847 10.7036 0.0516054 10.8515 0.0174292C10.9993 -0.016747 11.1542 -0.00309311 11.2938 0.0564228L18.9824 3.33667C19.0897 3.38248 19.1842 3.45381 19.2577 3.54446C19.3312 3.6351 19.3814 3.74232 19.404 3.8568C19.4267 3.97127 19.421 4.08954 19.3875 4.20132C19.354 4.3131 19.2937 4.41501 19.2119 4.49819L14.9086 8.86943L19.4339 10.8001C19.5619 10.8547 19.6713 10.9454 19.7487 11.0612C19.826 11.1769 19.868 11.3127 19.8694 11.4519C19.8708 11.5911 19.8317 11.7277 19.7567 11.845C19.6817 11.9624 19.5742 12.0553 19.4473 12.1125L1.11557 20.3912C0.970344 20.4569 0.80733 20.4724 0.652332 20.4351C0.497334 20.3978 0.359206 20.3098 0.259812 20.1852C0.160418 20.0605 0.105434 19.9063 0.103565 19.7468C0.101696 19.5874 0.153046 19.4319 0.24949 19.305L5.6416 12.1866L2.53101 10.8595C2.42725 10.8152 2.33542 10.747 2.26298 10.6605C2.19055 10.574 2.13957 10.4716 2.11419 10.3617C2.08881 10.2518 2.08975 10.1374 2.11693 10.0279C2.14411 9.91839 2.19677 9.81685 2.27061 9.73154L10.4711 0.246588Z"
      fill="#EA580C"
    />
  </svg>
);

export default LightningIcon;
