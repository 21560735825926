import clsx from 'clsx';
import queryString from 'query-string';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LoginIllustration from '../../components/Icons/loginIllustration';
import BigLogoIcon from '../../components/Icons/logoBig';
import { authActions, checkAuthCode } from '../../redux/auth';
import { useAuthCodeValid, useAuthError } from '../../redux/auth/selectors';
import { useStyles } from '../Signin/styles/signinPage';
import { PasswordRecoveryForm } from './PasswordRecoveryForm';

const queryParams = queryString.parse(location?.search);

interface PasswordRecoveryProps {}

export const PasswordRecoveryPage: FC<PasswordRecoveryProps> = () => {
  const dispatch = useDispatch();
  const authError = useAuthError();
  const isCodeValid = useAuthCodeValid();
  const { email } = queryParams;
  const oobCode = location?.pathname.split('/').pop();
  const classes = useStyles();

  useEffect(() => {
    if (oobCode) dispatch(checkAuthCode(oobCode));
    else {
      dispatch(
        authActions.setError({
          type: 'all',
          message: 'Something went wrong. Please try again.',
        })
      );
    }

    return () => {
      dispatch(authActions.setError(null));
    };
  }, []);

  return (
    <main className={classes.root}>
      <div className={classes.side}>
        <BigLogoIcon />
        <h2 className="desktop">Read Faster, Remember More</h2>
        <p className="desktop">
          Science-backed tools to make you a better reader on all your devices
        </p>
        <div className="desktop">
          <LoginIllustration />
        </div>
      </div>
      <div className={clsx('forgot-password', classes.main)}>
        {isCodeValid ? (
          <>
            <h1>Forgot password?</h1>
            <p>Enter a new password for your {email} account.</p>
            <PasswordRecoveryForm oobCode={oobCode as string} />
          </>
        ) : null}
        {!isCodeValid && authError ? (
          <div className={classes.error}>{authError.message}</div>
        ) : null}
      </div>
    </main>
  );
};
