import { sum } from 'lodash';
import { useCallback, useMemo } from 'react';

import {
  useReaderPlaybackState,
  useReaderPosition,
} from '../redux/reader/selectors';
import { useTotalWeight, useWords } from '../redux/text/selectors';
import { TextParserService } from '../services/TextParserService';

interface UseReadTimeReturn {
  totalTimeToRead: { ms: number; text: string };
  elapsedTime: CallableFunction;
  delayFactor: number;
  remainingReadTime: number;
}

export const useReadTime = (): UseReadTimeReturn => {
  const { speed } = useReaderPlaybackState();
  const position = useReaderPosition();
  const words = useWords();

  const totalWeight = useTotalWeight();
  const totalTimeToRead = useMemo(() => {
    const ms = TextParserService.calcEstimatedTotalTimeMS(
      words,
      totalWeight,
      speed
    );
    return {
      ms,
      text: TextParserService.getTimeText(ms),
    };
  }, [speed, words, totalWeight]);

  const delayFactor = useMemo(
    () => TextParserService.speedToDelayFactor(words, totalWeight, speed),
    [words, totalWeight, speed]
  );

  const elapsedTime = useCallback(() => {
    const weight = sum(
      words.slice(0, position).map(TextParserService.getWordWeight)
    );
    const elapsedMs = TextParserService.calcDelayMS(weight, delayFactor);

    return {
      ms: elapsedMs,
      text: TextParserService.getTimeText(elapsedMs),
    };
  }, [position, delayFactor]);

  const remainingReadTime = useMemo(() => {
    const timeToFinish = elapsedTime();
    return totalTimeToRead.ms - timeToFinish.ms;
  }, [totalTimeToRead, elapsedTime]);

  return { totalTimeToRead, elapsedTime, delayFactor, remainingReadTime };
};
