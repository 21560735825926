import { History, LocationState } from 'history';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { ArticleService } from '../../services/ArticleService';
import { TextDataService } from '../../services/TextDataService';
import { readerActions } from '../reader';
import { saveCurrentTextToReadingHistoryAction } from '../readingHistory';
import { AppThunk } from '../store';
import { textActions } from '../text';

export const readArticleAction = (
  url: string = '',
  history: History<LocationState>,
  articleId: string = ''
): AppThunk => async dispatch => {
  dispatch(textActions.setBusy(true));

  try {
    if (url && !articleId) {
      articleId = await ArticleService.parseUrl(url);
    }

    if (!articleId) throw new Error('text id is empty');

    const { text, favicon, title } =
      (await ArticleService.getArticleById(articleId)) || {};

    if (text) {
      TextDataService.setLSText(text);
      dispatch(textActions.acceptText(text));
      dispatch(textActions.setTextType('ARTICLE'));
      dispatch(textActions.setTextId(articleId));
      dispatch(textActions.setTextThumbSrc(favicon?.src));
      dispatch(readerActions.setPosition(-1));
      await dispatch(
        saveCurrentTextToReadingHistoryAction(undefined, title || undefined)
      );
    }

    dispatch(textActions.setBusy(false));
    history.push(APP_ROUTES_CONFIG.READER);
  } catch (error) {
    dispatch(textActions.setBusy(false));
  }
};
