export const APP_ROUTES_CONFIG = {
  HOME: '/home',
  SIGNIN: '/login',
  SIGNUP: '/signup',
  READER: '/reader',
  PASSWORD_RESET: '/password-reset',
  PASSWORD_RECOVERY: '/password-recovery',
  CHALLENGE: '/challenge',
  PRIVACY_POLICY: '/privacy-policy',
  ARTICLE_HOOK: '/hooks/article',
  AUTH_VERIFY: '/auth/verify',
  EMAIL_VERIFY: '/email-verify',
  READER_TEST: '/test',
  STATISTICS: '/statistics',
};
