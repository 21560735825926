import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ParagraphModel, WordModel } from '@read4speed/models';
import React, { FC, memo } from 'react';
import { colors } from 'src/styles/colors';

import { useAuthorizedUserProfile } from '../../redux/userProfiles/selectors';
import { OnWordClick, OnWordDoubleClick } from './OnWordClickInterface';
import { TextRendererParagraph } from './TextRendererParagraph';

const DEFAULT_CURSOR_COLOR = colors.cursorColor;

const useStyles = makeStyles(theme =>
  createStyles({
    textWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

interface TextRenderProps {
  paragraphs: ParagraphModel[];
  words: WordModel[];
  onWordClick: OnWordClick;
  onWordDoubleClick: OnWordDoubleClick;
  readerPosition: number;
}

export const TextRenderer: FC<TextRenderProps> = memo(
  ({ paragraphs, words, onWordClick, onWordDoubleClick, readerPosition }) => {
    const classes = useStyles();
    const profile = useAuthorizedUserProfile();
    const cursorColor = profile?.cursorColor ?? DEFAULT_CURSOR_COLOR;
    return (
      <Box className={classes.textWrapper}>
        {paragraphs.map((p, i) => (
          <TextRendererParagraph
            cursorColor={cursorColor}
            paragraph={p}
            readerPosition={readerPosition}
            onWordClick={onWordClick}
            onWordDoubleClick={onWordDoubleClick}
            key={i}
            allWords={words}
          />
        ))}
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.readerPosition === nextProps.readerPosition &&
      prevProps.words.length === nextProps.words.length &&
      prevProps.paragraphs.length === nextProps.paragraphs.length &&
      prevProps.onWordDoubleClick === nextProps.onWordDoubleClick
    );
  }
);

TextRenderer.displayName = 'TextRenderer';
