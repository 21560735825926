import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { UnderlineHighlightNameEnum } from '@read4speed/models/src/UnderlineHighlightNameEnum';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { useStyles } from './styles/readerSettings';

interface ReaderSettingsUnderlineHighlightProps {
  value?: UnderlineHighlightNameEnum[];
  onChange(theme: UnderlineHighlightNameEnum[]): void;
}

export const ReaderSettingsUnderlineHighlight: FC<ReaderSettingsUnderlineHighlightProps> = ({
  value = [],
  onChange,
}) => {
  const classes = useStyles();
  const [localValue, setLocalValue] = useState<UnderlineHighlightNameEnum[]>(
    value
  );
  const isUnderlineWordMode = localValue.indexOf('UnderlineWord') >= 0;
  const isUnderlineLineMode = localValue.indexOf('UnderlineLine') >= 0;

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    onChange(localValue);
  }, [localValue]);

  const toggleHighlight = useCallback(
    (mode: UnderlineHighlightNameEnum) => {
      if (localValue.indexOf(mode) == -1) {
        setLocalValue(localValue.concat(mode));
      } else setLocalValue(localValue.filter(item => item !== mode));
    },
    [localValue]
  );

  return (
    <label className={classes.row}>
      <span>Underline</span>
      <Box display="flex" alignItems="center">
        <Button
          onClick={() => toggleHighlight('UnderlineWord')}
          className={clsx(classes.settingsBtn, isUnderlineWordMode && 'active')}
        >
          Word
        </Button>
        <Button
          onClick={() => toggleHighlight('UnderlineLine')}
          className={clsx(classes.settingsBtn, isUnderlineLineMode && 'active')}
        >
          Line
        </Button>
      </Box>
    </label>
  );
};
