import firebase from 'firebase/app';

import { getFirebase, getPersistence } from '../../firebase';
import { AppThunk } from '../store';

export const signInWithGoogle = (isPersist: boolean): AppThunk => async _ => {
  try {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    const persistence = getPersistence();
    const auth = getFirebase().auth();
    await auth.setPersistence(
      isPersist ? persistence.LOCAL : persistence.SESSION
    );

    await auth.signInWithPopup(googleProvider);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};
