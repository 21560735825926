import { FontNameEnum } from '@read4speed/models';

import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateFontAction = (
  id: string,
  fontName: FontNameEnum
): AppThunk => async dispatch => {
  await UserProfilesService.updateFont(id, fontName);

  dispatch(
    userProfilesActions.update({
      id,
      data: { fontName },
    })
  );
};
