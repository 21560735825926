import { TextParserService } from '../../services/TextParserService';
import { AppThunk } from '../store';
import { textActions } from './textSlice';

export const parseTextAction = (text: string): AppThunk => async dispatch => {
  // eslint-disable-next-line no-console
  console.debug('parsing text');

  const results = await TextParserService.parseText(text);

  dispatch(textActions.acceptParseResults(results));
};
