import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px',
      fontSize: '28px',
      lineHeight: '32px',
      color: colors.grey800,
      borderBottom: `1px solid ${colors.greyLight}`,
    },
    form: {
      padding: '24px',
      display: 'flex',
      flexDirection: 'column',
      '& label': {
        fontSize: '20px',
        lineHeight: '24px',
        color: colors.grey700,
        marginBottom: '16px',
      },
      '& button': {
        marginTop: '24px',
      },
    },
  })
);
