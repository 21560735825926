import { firestoreToPlainObject } from '@read4speed/firebase-utils';
import { ScheduledEmailsModel } from '@read4speed/models/src';
import { SendGridEmailConfig } from '@read4speed/models/src/ScheduledEmailsModel';

import { collections } from '../firebase';

export class ScheduledEmailsService {
  static async create(
    data: ScheduledEmailsModel
  ): Promise<FirebaseFirestore.DocumentReference<ScheduledEmailsModel>> {
    return await collections.scheduledEmailsRef().add(data);
  }

  static async getById(id: string): Promise<ScheduledEmailsModel | null> {
    const snapshot = await collections.scheduledEmailsRef().doc(id).get();
    if (!snapshot.exists) return null;
    return firestoreToPlainObject(snapshot);
  }

  static async update(
    id: string,
    data: Partial<Omit<ScheduledEmailsModel, 'sendgridEmail'>> & {
      sendgridEmail: Partial<SendGridEmailConfig>;
    }
  ): Promise<void> {
    await collections.scheduledEmailsRef().doc(id).update(data);
  }
}
