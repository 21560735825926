import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { colors } from 'src/styles/colors';

import EnvelopeIcon from '../../components/Icons/envelope';
import GoogleIcon from '../../components/Icons/google';
import LockIcon from '../../components/Icons/lock';
import {
  authActions,
  signInWithGoogle,
  signUpWithEmailAndPasswordAction,
} from '../../redux/auth';
import { useAuthError } from '../../redux/auth/selectors';
import { CustomInput } from '../../styles/customInput';
import { SubmitButton } from '../../styles/submitButton';
import { useStyles } from '../Signin/styles/signinForm';

interface SignupFormFields {
  email: string;
  password: string;
  passwordConfirm: string;
}

interface SignupFormProps {}

export const SignupForm: FC<SignupFormProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const authError = useAuthError();
  const [isChecked, setIsChecked] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const {
    register,
    handleSubmit,
    formState,
    watch,
  } = useForm<SignupFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { red1, red2 } = colors;
  const passwordRef = useRef({});
  passwordRef.current = watch('password', '');

  useEffect(() => {
    return () => {
      dispatch(authActions.setError(null));
    };
  }, []);

  const isAuthError = authError && authError.type === 'all';
  const isEmailError = authError && authError.type === 'email';
  const isPasswordError = authError && authError.type === 'password';
  const isConfirmPasswordError = authError && authError.type === 'confirm-pass';

  const authErrorMessage = isAuthError ? authError?.message : '';
  const emailErrorMessage = isEmailError ? authError?.message : emailError;
  const passwordErrorMessage = isPasswordError
    ? authError?.message
    : passwordError;
  const confirmPasswordErrorMessage = isConfirmPasswordError
    ? authError?.message
    : confirmPasswordError;

  const isEmailInvalid = emailErrorMessage || isAuthError;
  const isPasswordInvalid = passwordErrorMessage || isAuthError;
  const isConfirmPasswordInvalid = confirmPasswordErrorMessage || isAuthError;

  const submitHandler = handleSubmit(({ email, password, passwordConfirm }) => {
    if (email && password && password === passwordConfirm) {
      dispatch(signUpWithEmailAndPasswordAction(email, password));
    }
  });

  const isError = !!(
    isEmailInvalid ||
    isPasswordInvalid ||
    isConfirmPasswordInvalid
  );

  const onEmailChange = useCallback((): void => {
    if (emailErrorMessage) {
      setEmailError('');
      dispatch(authActions.setError(null));
    }
  }, [emailErrorMessage]);

  const onPasswordChange = useCallback((): void => {
    if (passwordErrorMessage) {
      setPasswordError('');
      dispatch(authActions.setError(null));
    }
  }, [passwordErrorMessage]);

  const onConfirmPasswordChange = useCallback((): void => {
    if (confirmPasswordErrorMessage) {
      setConfirmPasswordError('');
      dispatch(authActions.setError(null));
    }
  }, [confirmPasswordErrorMessage]);

  const onCheckboxChange = useCallback((): void => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  const handleGoogleSignUp = (): void => {
    dispatch(signInWithGoogle(false));
  };

  return (
    <div className={classes.root}>
      <form onSubmit={submitHandler}>
        <CustomInput
          onChange={onEmailChange}
          className={isEmailInvalid ? 'invalid' : ''}
          inputRef={register({
            required: true,
            validate: value => {
              if (!/^.+\@.+\..+$/.test(value)) {
                setEmailError('The email address is invalid.');
              } else {
                setEmailError('');
                return true;
              }
            },
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EnvelopeIcon color={isEmailInvalid ? red2 : red1} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          required
          fullWidth
          id="email"
          placeholder="Email Address *"
          name="email"
          autoComplete="new-email"
          autoFocus
        />

        {emailErrorMessage ? (
          <Alert className={classes.alert} severity="error">
            {emailErrorMessage}
          </Alert>
        ) : null}

        <CustomInput
          onChange={onPasswordChange}
          className={isPasswordInvalid ? 'invalid' : ''}
          inputRef={register({
            required: true,
            validate: (value: string) => {
              if (!/\d/g.test(value)) {
                setPasswordError('Password should include a digit.');
              } else if (!/\D/g.test(value)) {
                setPasswordError(
                  'Password should include at least one non-digit.'
                );
              } else if (value.length < 6) {
                setPasswordError('Password should be at least 6 letters long.');
              } else {
                setPasswordError('');
                return true;
              }
            },
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color={isPasswordInvalid ? red2 : red1} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          required
          fullWidth
          name="password"
          placeholder="Password *"
          type="password"
          id="password"
          autoComplete="new-password"
        />

        {passwordErrorMessage && (
          <Alert className={classes.alert} severity="error">
            {passwordErrorMessage}
          </Alert>
        )}

        <CustomInput
          onChange={onConfirmPasswordChange}
          className={isConfirmPasswordInvalid ? 'invalid' : ''}
          inputRef={register({
            required: true,
            validate: value => {
              if (value !== passwordRef.current) {
                setConfirmPasswordError('Passwords do not match.');
              } else {
                setConfirmPasswordError('');
                return true;
              }
            },
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color={isConfirmPasswordInvalid ? red2 : red1} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          required
          fullWidth
          name="passwordConfirm"
          placeholder="Confirm Password *"
          type="password"
          id="passwordConfirm"
          autoComplete="new-password"
        />

        {isAuthError || confirmPasswordErrorMessage ? (
          <Alert className={classes.alert} severity="error">
            {isAuthError ? authErrorMessage : confirmPasswordErrorMessage}
          </Alert>
        ) : null}

        <div className={clsx(classes.controls, 'signup')}>
          <FormGroup>
            <FormControlLabel
              classes={{ root: 'signup' }}
              control={
                <Checkbox
                  className="checkbox"
                  required
                  checked={isChecked}
                  onChange={onCheckboxChange}
                />
              }
              label={
                <div className="label">
                  I agree to the{' '}
                  <a className={classes.link}>Terms of Service</a>
                </div>
              }
            />
          </FormGroup>
        </div>

        <SubmitButton
          disabled={!formState.isValid || isError || !isChecked}
          type="submit"
          fullWidth
          variant="contained"
          className={classes.submitBtn}
        >
          Sign up
        </SubmitButton>
        <div className={classes.divider}>
          <hr />
          <span>or</span>
          <hr />
        </div>
      </form>
      <Button
        type="submit"
        fullWidth
        className={clsx(classes.submitBtn, classes.googleSignIn)}
        onClick={handleGoogleSignUp}
      >
        <GoogleIcon />
        Sign Up With Google
      </Button>
    </div>
  );
};
