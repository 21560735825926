import { BookPageModel } from '@read4speed/models';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { getCollectionItems } from '../../utils';

export const useBookPages = (): BookPageModel[] => {
  return useSelector<RootState, BookPageModel[]>(state =>
    getCollectionItems(state.book.pages)
  );
};
