import { FirestoreModelBase } from '@read4speed/models';

import { ICollectionState } from './ICollectionState';

export const getCollectionStateItem = <T extends FirestoreModelBase>(
  collection: ICollectionState<T>,
  id: string | null
): T | null => {
  if (id === null) return null;
  return collection.byId[id] || null;
};
