import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { KeyboardEnabledKeys } from '../uiSlice';

export const useEnabledKeyboardKeys = (): KeyboardEnabledKeys => {
  return useSelector<RootState, KeyboardEnabledKeys>(
    state => state.ui.keyboard.enabledKeys
  );
};
