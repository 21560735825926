import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { CommentModal } from '@read4speed/models/src/ParagraphCommentsModal';
import React, { useState } from 'react';
import * as uuid from 'uuid';

import { firestoreTimeStampNow } from '../../firebase';
import { useTextId } from '../../redux/text/selectors';

interface ICommentsBox {
  comments: CommentModal[];
  handleClose: () => void;
  updateParagraphComments: (arg: CommentModal[]) => Promise<void>;
}

const useCardCommentsStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 600,
      minWidth: 600,
    },
    inline: {
      display: 'inline',
    },
    commentField: {
      width: '100%',
    },
    saveCommentBtn: {
      marginTop: '16px',
    },
  })
);

const useCommentsListStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: '16px',
      maxHeight: 300,
      overflowY: 'scroll',
      padding: '0 1rem',
      rowGap: 16,
    },
    commentActions: {
      display: 'flex',
      flexDirection: 'column',
    },
    deleteIcon: {
      marginTop: 6,
      marginLeft: 10,
      '&:hover': {
        fill: theme.palette.secondary.main,
      },
    },
    editIcon: {
      '&:hover': {
        fill: theme.palette.secondary.main,
      },
    },
    listItem: {
      borderRadius: 8,
      background: theme.palette.grey['200'],
    },
    listItemText: {
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
    },
  })
);

export const CommentsBox = ({
  comments,
  handleClose,
  updateParagraphComments,
}: ICommentsBox): JSX.Element => {
  const [content, setContent] = useState('');
  const cardClasses = useCardCommentsStyles();
  const listClasses = useCommentsListStyles();
  const textId = useTextId();

  const handleSaveComment: VoidFunction = async () => {
    if (textId) {
      const newComment = {
        comment: content,
        uid: uuid.v4(),
        timestamp: firestoreTimeStampNow(),
      };
      await updateParagraphComments([...comments, newComment]);
    }
    setContent('');
  };

  const handleDeleteComment = async (uid: string): Promise<void> => {
    if (textId) {
      const newComments = comments.filter(comment => comment.uid !== uid);
      await updateParagraphComments(newComments);
    }
  };

  return (
    <Card className={cardClasses.root}>
      <CardHeader
        action={
          <IconButton aria-label="settings">
            <CloseIcon onClick={handleClose} />
          </IconButton>
        }
        title="Remember what you read"
      />
      <CardContent>
        <TextField
          className={cardClasses.commentField}
          id="outlined-multiline-flexible"
          label="Type your note here"
          multiline
          value={content}
          onChange={({ target }) => {
            setContent(target.value);
          }}
          variant="standard"
        />
        <Button
          className={cardClasses.saveCommentBtn}
          color="secondary"
          variant="contained"
          onClick={handleSaveComment}
        >
          Save note
        </Button>
      </CardContent>
      <List className={listClasses.root}>
        {comments.length ? (
          comments.map(({ comment, uid }) => {
            return (
              <ListItem
                key={uid}
                alignItems="flex-start"
                className={listClasses.listItem}
              >
                <ListItemText
                  primary={comment}
                  primaryTypographyProps={{
                    className: listClasses.listItemText,
                  }}
                />
                <div className={listClasses.commentActions}>
                  <IconButton
                    aria-label="settings"
                    onClick={() => handleDeleteComment(uid)}
                  >
                    <DeleteIcon className={listClasses.deleteIcon} />
                  </IconButton>
                </div>
              </ListItem>
            );
          })
        ) : (
          <Typography color="textSecondary">No notes yet 😔</Typography>
        )}
      </List>
    </Card>
  );
};
