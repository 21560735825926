import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@material-ui/core/Box/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FC, InputHTMLAttributes, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      padding: theme.spacing(2),
    },
    fileIcon: {
      marginBottom: theme.spacing(2),
    },
    dropzone: {
      display: 'flex',
      color: theme.palette.grey['400'],
      border: `2px dashed ${theme.palette.grey['400']}`,
      padding: theme.spacing(6),
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

interface ImageLoaderDropZoneProps {
  onAdded: (files: File[]) => void;
  visible: boolean;
}

export const FileLoaderDropZone: FC<ImageLoaderDropZoneProps> = ({
  onAdded,
  visible,
}) => {
  const classes = useStyles();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    accept: 'application/pdf',
  });

  useEffect(() => {
    if (acceptedFiles.length) onAdded(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <Box {...getRootProps()} className={classes.root}>
      <input {...(getInputProps() as InputHTMLAttributes<HTMLInputElement>)} />
      {visible && (
        <div className={classes.dropzone}>
          <FontAwesomeIcon
            icon={faFileUpload}
            size={'3x'}
            className={classes.fileIcon}
          />
          <Typography variant={'caption'} align={'center'}>
            Drop files here or click to open the file browser
          </Typography>
        </div>
      )}
    </Box>
  );
};
