import { BookService } from '../../services/BookService';
import { AppThunk } from '../store';
import { bookSlice } from './bookSlice';

export const preloadPrevBookPagesAction = (
  bookId: string,
  currentPageNumber: number,
  count: number
): AppThunk => async dispatch => {
  const pages = await BookService.getPagesBefore(
    bookId,
    currentPageNumber,
    count
  );
  dispatch(bookSlice.actions.acceptPages(pages));
};
