import React, { FC } from 'react';

import { useStyles } from './styles';

interface SideNavElements<T> extends FC<T> {
  Top: typeof Top;
  Bottom: typeof Bottom;
  Main: typeof Main;
}

interface SideNavProps {
  className?: string;
}

const Top: FC = ({ children }) => {
  return <div>{children}</div>;
};

const Bottom: FC = ({ children }) => {
  return <div>{children}</div>;
};

const Main: FC = ({ children }) => {
  const styles = useStyles();

  return <div className={styles.main}>{children}</div>;
};

export const SideNav: SideNavElements<SideNavProps> = ({
  children,
  className,
}) => {
  const styles = useStyles();
  return <div className={`${styles.root} ${className}`}>{children}</div>;
};

SideNav.Top = Top;
SideNav.Bottom = Bottom;
SideNav.Main = Main;
