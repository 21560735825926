import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { TextAlignEnum } from '@read4speed/models';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';
import ChevronIcon from 'src/components/Icons/chevronBig';
import { colors } from 'src/styles/colors';
import { CustomMenu } from 'src/styles/customMenu';

import { useStyles } from './styles/readerSettings';

interface ReaderSettingsTextAlignProps {
  value?: TextAlignEnum;
  onChange: (textAlign: TextAlignEnum) => void;
}

function getTextAlignName(str: TextAlignEnum): string {
  switch (str) {
    case 'JUSTIFY':
      return 'Justify';
    case 'LEFT':
      return 'Left';
    case 'CENTER':
      return 'Center';
  }
}

export const ReaderSettingsTextAlign: FC<ReaderSettingsTextAlignProps> = ({
  value = 'JUSTIFY',
  onChange,
}) => {
  const classes = useStyles();
  const [localValue, setLocalValue] = useState<TextAlignEnum>(value);
  const [themeMenu, setThemeMenu] = useState(null);
  const isThemeMenuOpen = Boolean(themeMenu);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    onChange(localValue);
  }, [localValue]);

  const toggleThemeMenu = useCallback(event => {
    setThemeMenu(event.currentTarget);
  }, []);

  const closeThemeMenu = useCallback(() => {
    setThemeMenu(null);
  }, []);

  const handleChange = useCallback((value: TextAlignEnum) => {
    setLocalValue(value);
    closeThemeMenu();
  }, []);

  return (
    <label className={classes.row}>
      <span>Text Align</span>
      <Button
        className={clsx(classes.settingsBtn, classes.dropdownBtn)}
        aria-haspopup="true"
        aria-label="user menu"
        aria-expanded={isThemeMenuOpen ? 'true' : undefined}
        onClick={toggleThemeMenu}
      >
        {getTextAlignName(localValue)}
        <ChevronIcon color={colors.greyBase} />
      </Button>

      <CustomMenu
        autoFocus={false}
        anchorEl={themeMenu}
        open={isThemeMenuOpen}
        onClose={closeThemeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ style: { transform: 'translateY(4px)' } }}
      >
        <MenuItem disableRipple onClick={() => handleChange('LEFT')}>
          Left
        </MenuItem>
        <MenuItem disableRipple onClick={() => handleChange('CENTER')}>
          Center
        </MenuItem>
        <MenuItem disableRipple onClick={() => handleChange('JUSTIFY')}>
          Justify
        </MenuItem>
      </CustomMenu>
    </label>
  );
};
