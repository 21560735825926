import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { useReadTime } from '../../hooks';
import ReaderPageScrollEnd from '../Icons/readerPageScrollEnd';
import ReaderPageScrollStart from '../Icons/readerPageScrollStart';

export const useStyles = makeStyles<Theme, { scrollHeight: number }>(theme =>
  createStyles({
    scroll: {
      margin: '40px auto',
      width: '6px',
      height: 'calc(100% - 80px)',
      background: 'linear-gradient(180deg, #EA580C 0%, #50B5CC 100%)',
    },
    start: {
      position: 'absolute',
      top: '-29px',
      left: '-11.5px',
    },
    end: {
      position: 'absolute',
      bottom: '-39px',
      left: '-11.5px',
    },
    scrollerWrapper: {
      position: 'relative',
      height: '100%',
    },
    scroller: {
      width: '6px',
      position: 'absolute',
      bottom: 0,
      height: ({ scrollHeight }) => `${scrollHeight || 100}%`,
      background: theme.reader.borderColor,

      '&::before': {
        display: 'block',
        content: '""',
        width: '16px',
        height: '16px',
        border: 'solid 4px #50B5CB',
        background: '#fff',
        borderRadius: '50%',
        marginLeft: '-5px',
      },
    },
  })
);

export const ReaderScroll: FC<{ className?: string }> = ({ className }) => {
  const { totalTimeToRead, remainingReadTime } = useReadTime();
  const scrollHeight = (100 * remainingReadTime) / totalTimeToRead.ms;
  const classes = useStyles({ scrollHeight });

  return (
    <div className={`${classes.scroll} ${className}`}>
      <div className={classes.start}>
        <ReaderPageScrollStart />
      </div>
      <div className={classes.scrollerWrapper} aria-label="scrollerWrapper">
        <div className={classes.scroller}></div>
      </div>
      <div className={classes.end}>
        <ReaderPageScrollEnd />
      </div>
    </div>
  );
};
