import { readerActions } from '../reader';
import { saveCurrentTextToReadingHistoryAction } from '../readingHistory';
import { AppThunk } from '../store';

export const readerPlayAction = (
  speechActive: boolean = false
): AppThunk => dispatch => {
  dispatch(saveCurrentTextToReadingHistoryAction());
  if (speechActive) {
    dispatch(readerActions.playSpeech());
  } else {
    dispatch(readerActions.play());
  }
};
