import { FixationPointsEnum } from '@read4speed/models/src/FixationPointsEnum';

import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from '../userProfiles';

export const updateFixationPointsAction = (
  id: string,
  fixationPoints: FixationPointsEnum[]
): AppThunk => async dispatch => {
  dispatch(
    userProfilesActions.update({
      id,
      data: { fixationPoints },
    })
  );
  try {
    await UserProfilesService.updateFixationPoints(id, fixationPoints);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Can't save the fixation points settings`, error);
  }
};
