import { pick } from 'lodash';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { DiagnosticPlaybackState } from '../diagnosticSlice';

export const useDiagnosticPlaybackState = (): DiagnosticPlaybackState => {
  return useSelector<RootState, DiagnosticPlaybackState>(state => {
    return pick(state.diagnostic, ['stopped', 'playing', 'paused']);
  });
};
