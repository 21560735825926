import { History, LocationState } from 'history';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { AppThunk } from '../store';
import { textActions } from '../text';
import { saveTextAction } from './saveTextAction';

export const readCustomInputTextAction = (
  text: string,
  history: History<LocationState>
): AppThunk => async dispatch => {
  dispatch(textActions.setBusy(true));
  await dispatch(saveTextAction({ text, type: 'TEXT' }));
  dispatch(textActions.setBusy(false));
  history.push(APP_ROUTES_CONFIG.READER);
};
