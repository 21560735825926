import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    background: theme.reader.background,
    padding:
      'clamp(8px, 10vh, 60px) clamp(6px, 10vh, 20px) clamp(8px, 10vh, 35px)',
    height: '100vh',
    rowGap: '30px',
  },
  main: {
    height: '100%',
    display: 'grid',
    overflowY: 'scroll',
    marginRight: '-20px',
    paddingRight: '20px',
    alignContent: 'start',
    rowGap: '1rem',

    '& button': {
      fontSize: '1rem',
      height: 'fit-content',
      justifyContent: 'start',
      padding: '0.9375rem 1.125rem',
      transition: 'color 300ms, background 300ms',

      '&:hover': {
        transition: 'color 300ms, background 300ms',
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        background: theme.palette.secondary.main,
      },

      '& svg': {
        marginRight: '0.875rem',
      },
    },
  },
}));
