import { UserProfileModel } from '@read4speed/models';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { getCollectionStateItem } from '../../utils';

export const useAuthorizedUserProfile = (): UserProfileModel | null => {
  return useSelector<RootState, UserProfileModel | null>(state => {
    const { collection, loggedInProfileId } = state.userProfiles;
    if (!loggedInProfileId) return null;
    return getCollectionStateItem(collection, loggedInProfileId);
  });
};
