import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexShrink: 0,
      margin: '24px 0',
      padding: '0 16px',
    },
    userMenuBtn: {
      padding: '8px',
      height: '40px',
      width: '40px',
    },
    logo: {
      '& svg': {
        display: 'none',
      },
      '& svg:last-child': {
        display: 'block',
      },
      [theme.breakpoints.up('sm')]: {
        '& svg:last-child': {
          display: 'none',
        },
        '& svg:first-child': {
          display: 'block',
        },
      },
    },
  })
);
