import { pick } from 'lodash';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { ReaderTitleState } from '../readerSlice';

type ReaderIsFinished = Pick<ReaderTitleState, 'currentTitle'>;

export const useReaderTitleState = (): ReaderIsFinished => {
  return useSelector<RootState, ReaderIsFinished>(state => {
    return pick(state.reader, ['currentTitle']);
  });
};
