import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minWidth: theme.spacing(40),
      height: '100vh',
      width: '32vw',
      maxWidth: '100vw',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px',
      '& h2': {
        width: '100%',
        margin: 0,
        padding: 0,
        fontWeight: 'normal',
        fontSize: '28px',
        color: colors.grey800,
      },
    },
    main: {
      padding: '24px',
      marginBottom: 'auto',
      '& label': {
        fontSize: '20px',
        lineHeight: '24px',
        color: colors.grey700,
      },
    },
    footer: {
      padding: '24px',
      textAlign: 'center',
      '& button': {
        height: '64px',
      },
      '& button span': {
        fontSize: '20px',
        lineHeight: '24px',
      },
      '& button svg': {
        marginRight: '10px',
      },
    },
    urlInput: {
      margin: '16px 0 12px',
      width: '100%',
      '& > div': {
        padding: '12px',
        border: `1px solid ${colors.grey300}`,
        borderRadius: '12px',
        '& > div': {
          marginRight: '12px',
        },
      },
      '& input': {
        padding: 0,
      },
    },
  })
);
