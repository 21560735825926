/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

interface SignupIllustrationProps {}

const SignupIllustration: FC<SignupIllustrationProps> = (): ReactElement => (
  <svg
    width="405"
    height="380"
    viewBox="0 0 405 380"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_0_267)">
      <path
        d="M229.989 368.068L81.8422 368.068C76.1068 368.068 70.6062 365.795 66.5506 361.748C62.4951 357.702 60.2167 352.214 60.2167 346.492C60.2167 341.121 62.3549 335.971 66.161 332.173C69.9671 328.376 75.1294 326.242 80.512 326.242H229.977V368.068H229.989Z"
        fill="#263D88"
      />
      <path
        d="M391.898 365.719H211.958C209.299 365.719 206.749 364.665 204.869 362.789C202.989 360.913 201.933 358.369 201.933 355.716V338.166C201.931 336.914 202.177 335.673 202.656 334.516C203.135 333.358 203.838 332.306 204.725 331.42C205.611 330.533 206.664 329.829 207.823 329.349C208.983 328.869 210.225 328.621 211.481 328.62H391.906V365.719H391.898Z"
        fill="white"
      />
      <path
        d="M190.777 368.068H397.578C399.309 368.068 400.712 366.668 400.712 364.941C400.712 363.215 399.309 361.815 397.578 361.815H190.777C189.047 361.815 187.644 363.215 187.644 364.941C187.644 366.668 189.047 368.068 190.777 368.068Z"
        fill="#263D88"
      />
      <path
        d="M190.777 332.512H397.578C399.309 332.512 400.712 331.112 400.712 329.385C400.712 327.658 399.309 326.259 397.578 326.259H190.777C189.047 326.259 187.644 327.658 187.644 329.385C187.644 331.112 189.047 332.512 190.777 332.512Z"
        fill="#263D88"
      />
      <path
        d="M391.898 347.159L368.865 347.863L391.898 348.287V347.159Z"
        fill="#E1E1E1"
      />
      <path
        d="M391.898 337.539L337.1 338.244L391.898 338.667V337.539Z"
        fill="#E1E1E1"
      />
      <path
        d="M337.663 338.101C339.157 339.338 340.246 340.993 340.789 342.852C340.935 343.392 341.017 343.946 341.034 344.505C341.164 348.429 341.034 357.308 341.034 357.308L347.97 352.867L354.906 357.308V345.295C354.906 343.019 354.311 340.817 353.209 339.355C352.679 338.651 352.03 338.134 351.295 338.134L337.663 338.101Z"
        fill="#E85A24"
      />
      <path
        d="M337.663 338.101C339.157 339.338 340.246 340.993 340.789 342.852L354.662 343.027C354.662 343.027 354.119 338.801 351.25 338.101H337.663Z"
        fill="#D14A27"
      />
      <path
        d="M45.5767 264.803H193.813C195.112 264.803 196.357 265.317 197.275 266.234C198.194 267.15 198.709 268.392 198.709 269.688V307.87C198.709 312.246 196.967 316.443 193.866 319.537C190.764 322.631 186.558 324.37 182.172 324.37H45.5767V264.803Z"
        fill="white"
      />
      <path
        d="M312.908 288.948V298.543C312.937 303.371 311.981 308.154 310.097 312.6C306.51 320.905 299.839 326.487 292.201 326.487H41.476C39.8154 326.487 38.4729 324.675 38.4729 322.436V321.003C38.4729 318.764 39.8154 316.952 41.476 316.952H190.022C191.402 316.96 192.752 316.553 193.896 315.785C195.041 315.017 195.927 313.923 196.441 312.645L196.461 312.6C197.963 308.704 198.723 304.562 198.701 300.387V288.174C198.703 285.995 198.496 283.821 198.081 281.681C198.081 281.649 198.081 281.612 198.081 281.579C197.54 278.93 196.096 276.55 193.994 274.843C191.893 273.136 189.264 272.208 186.554 272.216H41.4963C39.8357 272.216 38.4933 270.4 38.4933 268.145V266.712C38.4933 264.473 39.8357 262.641 41.4963 262.641H293.482C302.365 262.641 309.852 270.677 312.178 281.653C312.678 284.052 312.923 286.497 312.908 288.948Z"
        fill="#693996"
      />
      <path
        d="M45.5767 294.594L64.1378 295.669L45.5767 296.312V294.594Z"
        fill="#E1E1E1"
      />
      <path
        d="M45.5767 304.605L89.7416 305.676L45.5767 306.323V304.605Z"
        fill="#E1E1E1"
      />
      <path
        d="M45.4951 282.035L126.693 283.106L45.4951 283.753V282.035Z"
        fill="#E1E1E1"
      />
      <path
        d="M386.076 262.45H216.202C209.308 262.45 202.697 259.718 197.823 254.854C192.949 249.991 190.21 243.395 190.21 236.517C190.21 229.793 192.887 223.344 197.653 218.589C202.419 213.834 208.882 211.163 215.622 211.163L386.072 211.163V262.458L386.076 262.45Z"
        fill="white"
      />
      <path
        d="M195.457 231.066V242.103C195.475 243.764 195.874 245.399 196.624 246.882C198.076 249.627 200.401 251.813 203.234 253.094C205.635 254.217 208.255 254.792 210.905 254.78H391.086C393.196 254.78 394.909 256.205 394.909 257.963V259.266C394.909 261.025 393.196 262.454 391.086 262.454H79.8225C70.8908 262.454 63.0035 258.753 58.2337 253.09C55.1934 249.591 53.5023 245.126 53.4639 240.495V231.787C53.4707 228.638 54.3155 225.547 55.912 222.831C59.9148 215.934 68.3935 211.179 78.2025 211.179H391.082C393.192 211.179 394.905 212.6 394.905 214.363V215.662C394.905 217.424 393.192 218.849 391.082 218.849H210.35C206.029 218.849 202.12 220.38 199.391 222.831C198.229 223.865 197.272 225.108 196.571 226.495C195.86 227.915 195.479 229.478 195.457 231.066Z"
        fill="#15A44A"
      />
      <path
        d="M386.076 236.811L362.977 237.669L386.076 238.191V236.811Z"
        fill="#E1E1E1"
      />
      <path
        d="M386.076 225.005L331.123 225.868L386.076 226.389V225.005Z"
        fill="#E1E1E1"
      />
      <path
        d="M155.589 46.267C153.551 52.2717 153.621 58.7899 155.789 64.7494C155.975 65.1683 156.081 65.6186 156.099 66.0765C156.067 66.8907 155.463 67.538 154.924 68.1446C148.946 74.8007 146.613 83.8872 142.039 91.5732C140.814 93.6087 139.378 95.6442 137.265 96.6945C137.134 86.517 136.326 71.3647 133.654 61.5292"
        fill="#724A48"
      />
      <path
        d="M153.99 48.9254C154.088 48.0794 154.454 47.2867 155.034 46.6619L133.654 61.5414C134.931 66.2394 135.779 72.126 136.334 78.1471C140.096 63.2309 153.337 62.9174 153.99 48.9254Z"
        fill="#603C3B"
      />
      <path
        d="M130.903 21.3768C129.271 23.5182 127.582 25.7206 126.75 28.2976C125.501 32.165 125.142 35.4259 125.089 39.4847C125.04 43.3603 124.505 45.9739 124.628 49.8454C124.718 52.7277 126.203 57.7513 127.325 60.4056C127.978 61.9485 128.855 63.4873 130.275 64.383C131.981 65.4577 134.172 65.413 136.151 65.0221C141.863 63.8904 146.865 60.1695 150.325 55.4919C153.786 50.8143 155.834 45.2207 157.127 39.5498"
        fill="#724A48"
      />
      <path
        d="M155.034 45.1393C149.754 57.9752 158.633 55.6832 160.461 55.1092C162.289 54.5352 163.77 53.1633 164.896 51.6163C166.464 49.5278 167.158 46.9142 166.834 44.3251C166.561 42.4728 166.226 43.173 166.598 42.827C167.234 42.2367 167.663 41.8215 168.421 41.1497C170.339 39.4562 171.8 36.635 172.861 34.3064C173.44 33.0281 173.877 31.5829 173.489 30.2354C173.102 28.8878 171.588 27.7927 170.278 28.2976C172.012 25.7532 172.543 22.2643 171.094 19.5612C169.646 16.858 165.937 15.3925 163.223 16.8499C161.053 7.77154 153.663 2.56472 144.36 0.464082C140.896 -0.317551 133.152 -0.203523 130.051 1.52258C126.95 3.24869 125.089 7.49881 126.954 10.5154C123.375 10.3648 120.172 12.7993 117.977 15.6123C116.386 17.6478 115.096 20.0497 115.076 22.6226C115.047 26.9338 118.748 30.618 122.918 31.7335C127.088 32.849 131.552 31.8678 135.567 30.2924C135.685 30.2232 135.817 30.1827 135.953 30.1742C136.089 30.1657 136.225 30.1894 136.35 30.2435C136.442 30.3276 136.516 30.4295 136.567 30.5428C136.618 30.6562 136.646 30.7787 136.648 30.903C137.142 33.6306 139.745 35.5847 142.455 36.1953C145.164 36.806 147.983 36.2889 150.709 35.7882C150.56 37.5374 150.99 39.2871 151.933 40.7689C152.877 42.2507 154.281 43.3828 155.932 43.9913"
        fill="#263D88"
      />
      <path
        d="M137.465 7.93442C140.541 7.68855 143.604 8.54283 146.107 10.3444C148.722 12.1479 150.656 14.7696 152.015 17.6112C152.777 19.2421 153.387 20.9403 153.835 22.6837C153.866 22.7987 153.942 22.8965 154.046 22.9557C154.149 23.0148 154.272 23.0304 154.387 22.9991C154.503 22.9678 154.601 22.8921 154.66 22.7887C154.719 22.6852 154.735 22.5625 154.704 22.4475C153.035 16.1537 149.367 9.95769 142.903 7.7105C141.161 7.09463 139.305 6.86538 137.465 7.03877C136.893 7.09577 136.889 7.99142 137.465 7.93442Z"
        fill="#243871"
      />
      <path
        d="M125.983 14.847C124.599 18.0387 126.774 21.3891 129.749 22.6429C133.572 24.2713 137.717 23.05 141.63 22.5127L141.059 22.0812C141.271 25.399 143.43 28.318 146.327 29.8649C149.648 31.6399 153.594 31.705 157.201 30.903C159.442 30.3584 161.627 29.6074 163.729 28.6599C164.26 28.4319 163.803 27.6584 163.276 27.8864C159.939 29.3194 156.397 30.5488 152.717 30.5041C149.428 30.4634 146.115 29.3561 143.936 26.8035C142.786 25.4821 142.093 23.8261 141.961 22.0812C141.959 22.0127 141.941 21.9456 141.91 21.8847C141.878 21.8239 141.833 21.7708 141.778 21.7295C141.724 21.6881 141.66 21.6595 141.593 21.6457C141.526 21.6319 141.456 21.6332 141.39 21.6496C137.921 22.1178 134.294 23.1722 130.834 22.0893C129.33 21.67 128.034 20.7119 127.194 19.3983C126.804 18.7972 126.561 18.1126 126.486 17.4003C126.411 16.6879 126.506 15.9679 126.762 15.2989C126.986 14.7778 126.215 14.3178 125.983 14.847Z"
        fill="#243871"
      />
      <path
        d="M165.178 22.6674C166.152 23.2353 166.92 24.0985 167.369 25.1315C167.818 26.1646 167.925 27.3136 167.675 28.4116C167.536 28.9734 168.401 29.2258 168.54 28.6517C168.838 27.3631 168.717 26.0131 168.193 24.7979C167.67 23.5827 166.773 22.5654 165.631 21.8939C165.528 21.834 165.405 21.8173 165.29 21.8474C165.175 21.8776 165.077 21.9521 165.017 22.0547C164.956 22.1573 164.94 22.2795 164.97 22.3944C165 22.5093 165.075 22.6075 165.178 22.6674Z"
        fill="#243871"
      />
      <path
        d="M152.945 43.2544C153.965 41.626 155.21 40.0343 156.968 39.2648C158.727 38.4954 161.106 38.8577 162.097 40.4861C162.95 41.9029 162.534 43.7429 161.734 45.2004C160.581 47.2759 158.747 48.8918 156.54 49.7762C155.724 50.11 154.728 50.3176 153.937 49.8942C153.605 49.6882 153.327 49.406 153.127 49.0709C152.927 48.7359 152.81 48.358 152.786 47.9687C152.745 47.1909 152.829 46.4116 153.035 45.6604"
        fill="#724A48"
      />
      <path
        d="M162.097 40.4862C161.909 40.1889 161.67 39.927 161.391 39.7127C161.979 42.9695 160.122 46.7636 155.83 46.7636C154.824 46.7808 153.832 46.5367 152.949 46.0553C152.796 46.6808 152.741 47.3263 152.786 47.9687C152.81 48.358 152.927 48.736 153.127 49.071C153.327 49.406 153.605 49.6883 153.937 49.8943C154.728 50.3014 155.703 50.11 156.54 49.7762C158.747 48.8918 160.581 47.2759 161.734 45.2004C162.534 43.7552 162.95 41.9029 162.097 40.4862Z"
        fill="#603C3B"
      />
      <path
        d="M191.573 80.5041C190.692 77.5649 187.403 74.3569 185.293 72.1301C183.368 70.0946 178.957 69.1623 178.483 69.0239C171.624 66.9884 168.76 67.3955 162.599 65.36L155.034 62.3475C151.676 73.2496 143.034 81.9331 139.529 92.7864C138.656 84.3309 137.081 80.1663 135.118 68.4214C134.868 68.5546 134.609 68.6703 134.343 68.7674C133.282 69.0931 132.123 69.4432 130.924 69.8177C130.271 70.0091 129.651 70.1963 129.141 70.3795C125.383 71.6008 121.474 73.0217 118.973 74.6378C117.722 75.3675 116.596 76.2925 115.639 77.3776C114.443 78.8676 113.885 80.7565 113.387 82.5966C105.036 113.838 106.568 146.892 117.773 177.231C118.299 178.636 118.891 180.105 120.074 181.034C121.005 181.766 137.052 165.218 138.207 165.495C154.593 169.423 156.613 188.17 173.469 187.967C177.064 187.922 181.054 187.616 183.527 185.011C184.751 183.712 189.998 180.126 190.622 178.449C202.455 146.63 201.317 113.027 191.573 80.5041Z"
        fill="#E85A24"
      />
      <path
        d="M117.696 83.3783C109.241 97.0691 108.523 114.18 101.709 128.75C99.971 132.465 97.8486 135.99 95.3765 139.265C92.8793 142.567 85.2043 154.067 82.3196 157.027C85.4573 159.38 93.6709 154.434 96.9841 156.534C99.0568 157.841 101.134 157.841 103.582 157.951C107.662 158.134 111.098 155.036 113.889 152.048C118.568 147.051 122.929 141.766 126.946 136.224"
        fill="#F1592F"
      />
      <path
        d="M107.625 112.804L116.708 124.646C116.708 124.646 110.865 137.042 109.674 139.916C108.482 142.791 106.34 129.531 107.168 124.276C107.997 119.02 107.625 112.804 107.625 112.804Z"
        fill="#D14A27"
      />
      <path
        d="M178.728 118.071C178.728 118.071 189.443 103.872 190.643 94.3578C191.842 84.8438 194.919 117.97 190.643 132.011C186.367 146.051 184.302 147.399 184.302 147.399C184.302 147.399 181.283 131.624 178.728 118.071Z"
        fill="#D14A27"
      />
      <path
        d="M34.8578 267.677C35.4984 273.051 32.6055 278.261 28.8598 282.198C25.1141 286.135 20.5075 289.09 16.5251 292.783C27.5637 291.969 38.5655 290.783 49.5306 289.225C50.061 289.151 50.6771 289.013 50.9015 288.529C51.1259 288.044 50.9014 287.588 50.677 287.148C49.2549 284.079 48.4869 280.749 48.4216 277.369C48.3563 273.988 48.9951 270.631 50.2976 267.51"
        fill="#724A48"
      />
      <path
        d="M25.6323 285.394C26.2878 286.517 26.942 287.641 27.5949 288.765C27.7634 289.109 28.0058 289.412 28.3049 289.652C28.6912 289.876 29.1311 289.992 29.5779 289.986C36.1241 290.425 42.6185 288.569 47.9391 284.738C48.3066 284.403 48.7706 284.193 49.2652 284.136C50.0812 284.136 50.624 284.95 50.9545 285.711C52.4678 289.168 52.8781 293.006 52.1295 296.703C52.0933 297.051 51.9409 297.376 51.697 297.627C51.3779 297.829 50.9987 297.914 50.6239 297.867C46.6141 297.787 42.6065 298.118 38.6646 298.857C28.8148 300.835 19.4465 306.372 9.44982 305.391C7.13845 305.107 4.85235 304.647 2.61137 304.015C1.46073 303.722 0.0733749 303.16 -0.00415039 301.979C0.000742541 301.454 0.180138 300.946 0.505884 300.534C1.81565 298.6 3.97008 297.436 6.0551 296.377C10.8739 293.935 20.7441 287.698 25.6404 285.386"
        fill="#12171D"
      />
      <path
        d="M145.65 168.76C125.571 160.345 96.8904 153.017 75.1343 153.97C68.2795 154.271 59.1804 154.149 49.6856 155.549C42.9001 156.551 36.9674 157.328 33.2666 163.093C29.884 168.365 28.7457 174.752 28.2071 181.005C27.3013 191.5 27.8847 202.06 28.4682 212.58C29.4393 230.126 30.4185 247.696 32.83 265.096C32.9401 265.877 33.0749 266.724 33.6216 267.282C34.1684 267.84 35.1394 268.055 35.9922 268.165C40.9048 268.811 45.8297 269.354 50.7669 269.794C51.6604 269.871 52.6438 269.924 53.3497 269.387C53.9046 268.955 54.1658 268.247 54.3657 267.575C61.1308 246.263 62.2325 223.547 61.2451 201.218C61.1635 199.374 61.3756 197.041 63.1179 196.426C63.8403 196.239 64.5988 196.239 65.3213 196.426C87.0732 200.123 124.13 211.432 142.904 210.76"
        fill="#374151"
      />
      <path
        d="M107.581 154.149C96.5352 160.027 80.0918 198.315 99.1997 203.795C105.124 205.492 115.464 207.805 123.955 209.693C129.973 211.029 141.386 211.102 147.551 211.008C160.849 210.805 173.718 213.569 182.486 203.591C186.428 199.113 187.982 188.838 190.622 178.461C190.622 178.461 147.755 164.049 132.238 160.89"
        fill="#18212B"
      />
      <path
        d="M100.473 181.905C99.7096 187.262 95.5764 191.582 90.9453 194.407C86.3141 197.232 81.0995 198.934 76.3052 201.466C87.1859 203.499 98.1251 205.167 109.123 206.469C109.653 206.534 110.286 206.559 110.624 206.148C110.963 205.736 110.849 205.232 110.763 204.759C110.178 201.428 110.292 198.012 111.098 194.727C111.903 191.442 113.383 188.36 115.443 185.675"
        fill="#724A48"
      />
      <path
        d="M87.0036 196.67C87.3463 197.924 87.6904 199.178 88.0359 200.432C88.1084 200.807 88.2631 201.162 88.4889 201.47C88.8073 201.784 89.2026 202.008 89.6353 202.121C95.8524 204.222 102.61 204.09 108.739 201.747C109.182 201.517 109.685 201.43 110.18 201.499C110.967 201.71 111.277 202.651 111.404 203.457C111.987 207.153 111.689 210.198 110.02 213.549C109.865 213.858 109.466 214.77 109.147 214.917C108.769 215.058 108.368 215.127 107.964 215.12C104.002 215.242 102.513 214.713 98.514 214.485C88.4847 213.883 75.6645 215.364 66.2309 211.859C64.0694 210.994 61.9782 209.964 59.9758 208.778C58.9395 208.199 57.744 207.304 57.9725 206.14C58.1123 205.634 58.4165 205.188 58.8374 204.873C60.6001 203.339 62.983 202.765 65.268 202.276C70.5723 201.14 81.6829 197.639 87.0036 196.67Z"
        fill="#12171D"
      />
      <path
        d="M176.235 108.081C173.461 97.5536 169.707 86.0407 158.727 83.7325C154.589 82.8653 150.325 83.6429 146.453 85.5319C137.566 89.8634 131.14 98.4613 126.309 107.625C120.866 117.949 115.443 123.095 102.076 159.327C101.232 161.627 94.5237 180.407 96.5353 181.31C104.879 185.088 119.499 190.218 122.274 190.218C124.199 190.218 126.558 182.442 127.517 180.549C132.287 171.137 134.576 166.113 135.461 164.77C136.236 163.602 136.889 162.633 137.456 161.798C134.976 172.33 135.416 183.94 137.416 194.842C138.256 199.418 141.753 206.583 145.229 209.01C147.314 210.467 151.978 210.984 154.414 211.188C170.772 212.551 180.528 207.931 183.886 199.789C190.969 181.355 184.073 137.816 176.235 108.081Z"
        fill="#374151"
      />
      <path
        d="M136.346 163.899L155.034 117.632C155.034 117.632 161.154 106.571 155.034 134.905C148.914 163.239 136.946 171.951 136.546 189.29C136.546 189.29 133.437 180.887 136.346 163.899Z"
        fill="#1E2836"
      />
      <path
        d="M109.294 88.7479C107.679 84.9337 107.457 80.6751 108.666 76.7141C109.482 74.0557 112.11 71.8289 110.951 69.3211C109.413 69.7282 105.802 71.4258 103.668 70.5424C101.15 69.488 99.6159 65.4781 98.045 63.2512C94.8093 58.6551 95.152 55.4675 91.3084 53.0208C89.101 51.6123 80.1244 56.6399 81.9238 67.9899C82.1074 69.1583 83.3152 71.5927 83.6865 72.236C88.7297 81.0171 97.3799 82.7066 103.451 90.812"
        fill="#724A48"
      />
      <path
        d="M96.878 90.8038C99.3057 88.8293 104.716 84.0785 107.144 82.104C107.744 81.6155 108.368 81.1107 109.147 81.0049C110.086 80.8787 110.983 81.3753 111.795 81.8598C117.434 85.194 122.119 90.698 126.925 95.1435C131.556 99.4303 136.873 103.334 140.537 108.468L144.825 114.481C151.672 101.356 160.465 89.326 170.078 78.0656C171.771 76.083 173.514 74.1045 175.676 72.6512C177.839 71.1978 180.503 70.3144 183.07 70.7663C187.774 71.5805 190.659 76.5349 191.471 81.2328C193.103 90.7712 188.941 100.301 184.253 108.773C175.599 124.406 164.435 139.216 149.163 148.506C148.008 149.211 146.78 149.894 145.433 149.968C143.634 150.07 141.977 149.076 140.484 148.071C122.49 135.923 93.0466 95.7094 96.8861 90.8038"
        fill="#F1592F"
      />
      <path
        d="M158.135 93.1691C158.135 93.1691 153.777 103.098 153.647 107.996C153.516 112.893 155.324 123.083 148.036 121.43C141.969 120.046 136.779 119.598 135.996 116.423C135.615 115.007 134.862 113.719 133.817 112.69C133.31 112.18 132.918 111.569 132.666 110.896C132.415 110.224 132.311 109.506 132.36 108.79C132.56 104.719 128.182 96.2793 128.182 96.2793C128.182 96.2793 141.202 106.55 144.837 114.485C144.825 114.469 152.88 98.7504 158.135 93.1691Z"
        fill="#F1592F"
      />
      <path
        d="M226.606 194.686C226.606 190.668 224.827 185.913 216.581 185.649L216.744 180.943C216.75 180.801 216.726 180.659 216.676 180.527C216.625 180.394 216.548 180.273 216.45 180.171C216.351 180.068 216.232 179.987 216.101 179.931C215.971 179.876 215.83 179.847 215.687 179.847H196.51C196.368 179.847 196.226 179.876 196.095 179.932C195.964 179.988 195.845 180.069 195.747 180.172C195.648 180.275 195.571 180.396 195.52 180.529C195.47 180.663 195.447 180.804 195.453 180.947L196.677 210.616C196.689 210.888 196.805 211.146 197.002 211.334C197.199 211.523 197.461 211.629 197.734 211.63H214.635C214.909 211.63 215.173 211.524 215.371 211.334C215.569 211.145 215.685 210.886 215.696 210.612L215.904 204.709C222.51 204.689 226.606 200.854 226.606 194.686ZM215.998 202.523L216.512 187.831C223.571 188.051 224.423 191.82 224.423 194.686C224.427 199.629 221.347 202.478 215.989 202.523H215.998Z"
        fill="#F1592F"
      />
      <path
        d="M216.581 185.649L216.744 180.943C216.751 180.763 216.711 180.584 216.629 180.424C216.547 180.263 216.425 180.127 216.275 180.027C216.26 180.056 216.244 180.084 216.226 180.112C214.239 183.499 213.982 186.626 213.443 190.444C212.972 193.666 212.117 196.82 210.897 199.84C208.347 206.215 203.251 208.764 196.588 208.923L196.657 210.616C196.669 210.888 196.785 211.146 196.982 211.334C197.179 211.523 197.441 211.629 197.714 211.63H214.635C214.909 211.63 215.173 211.524 215.371 211.334C215.569 211.145 215.685 210.886 215.696 210.612L215.904 204.709C222.502 204.689 226.598 200.854 226.598 194.686C226.606 190.668 224.835 185.897 216.581 185.649ZM215.989 202.523L216.503 187.831C223.562 188.051 224.415 191.82 224.415 194.686C224.427 199.629 221.347 202.478 215.989 202.523Z"
        fill="#D14A27"
      />
      <path
        d="M203.132 148.624C203.132 148.624 204.263 153.045 202.345 158.94C200.427 164.835 204.312 174.459 204.312 174.459C204.312 174.459 203.132 167.518 204.312 161.542C205.25 156.876 206.474 152.357 203.132 148.624Z"
        fill="#374151"
      />
      <path
        d="M210.697 139.542C210.697 139.542 211.534 142.799 210.114 147.171C208.694 151.543 211.57 158.651 211.57 158.651C211.57 158.651 210.697 153.518 211.57 149.097C212.252 145.644 213.174 142.302 210.697 139.542Z"
        fill="#374151"
      />
      <path
        d="M357.663 225C359.157 226.237 360.246 227.891 360.789 229.751C360.935 230.29 361.017 230.845 361.034 231.404C361.164 235.328 361.034 244.207 361.034 244.207L367.97 239.766L374.906 244.207V232.193C374.906 229.918 374.311 227.715 373.209 226.254C372.679 225.55 372.03 225.033 371.295 225.033L357.663 225Z"
        fill="#E85A24"
      />
      <path
        d="M357.663 225C359.157 226.237 360.246 227.891 360.789 229.751L374.662 229.926C374.662 229.926 374.119 225.7 371.25 225H357.663Z"
        fill="#D14A27"
      />
      <path
        d="M103.876 282.809C103.876 282.809 100.248 284.437 99.0447 287.108C98.8266 287.578 98.6993 288.085 98.6693 288.602C98.4694 292.152 98.6693 300.192 98.6693 300.192L87.9627 296.174L77.2845 300.192V289.298C77.2792 288.262 77.5122 287.238 77.9656 286.306C78.419 285.373 79.0808 284.557 79.9 283.92C80.7346 283.231 81.7756 282.84 82.8582 282.809H103.876Z"
        fill="#E85A24"
      />
      <path
        d="M103.876 282.809C103.876 282.809 100.248 284.437 99.0447 287.108L77.5906 287.267C77.9177 286.079 78.5949 285.018 79.5342 284.219C80.4735 283.421 81.6316 282.922 82.8583 282.788L103.876 282.809Z"
        fill="#D14A27"
      />
      <path
        d="M77 47L81 62L81.5 64L82.5 60.5L83 59L84.5 56.5L87 54L89 53H89.5H90L91.5 53.5L93.5 55L97 62L101 68L102 69.5L103.5 70.5L105.5 71L111 69.5L111.5 71L108.5 76L117.5 72.5L119.5 71V68L108.5 32L106 29L102.5 28.5L80.5 35L77 37L75.5 40L77 47Z"
        fill="#D9D9D9"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_267">
        <rect width="405" height="380" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SignupIllustration;
