import { WordModel } from '@read4speed/models';
import clsx from 'clsx';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  useReaderPlaybackState,
  useReaderSpeechState,
} from '../../redux/reader/selectors';
import { useQuotesData } from '../../redux/textQuotes/selectors';
import { quotesActions } from '../../redux/textQuotes/textQuotesSlice';
import { useAuthorizedUserProfile } from '../../redux/userProfiles/selectors';
import { WORD_FORMAT } from '../ReaderSettings/ReaderSettingsWordFormat';
import { hex2rgba } from '../TextCursor/utils';
import { OnWordClick, OnWordDoubleClick } from './OnWordClickInterface';
import { useStyles } from './styles/textRendererWord';

interface TextRendererWordProps {
  word: WordModel;
  onClick: OnWordClick;
  onDoubleClick: OnWordDoubleClick;
  read: boolean;
  cursorColor: string;
}

export const TextRendererWord: FC<TextRendererWordProps> = memo(
  ({ word, onClick, onDoubleClick, read, cursorColor }) => {
    const classes = useStyles();
    const profile = useAuthorizedUserProfile();
    const { edges, isQuotesActive } = useQuotesData();
    const dispatch = useDispatch();
    const [isHover, setHover] = useState(false);
    const { playing } = useReaderPlaybackState();
    const { speechActive, speechPlaying } = useReaderSpeechState();
    const isPlaying = speechActive ? speechPlaying : playing;
    const clickHandler: VoidFunction = () => {
      if (!isPlaying && !isQuotesActive) {
        onClick(word.index);
      }
      if (isQuotesActive) {
        dispatch(quotesActions.setEdges(word.index));
      }
    };
    const checkIsWordInQuotes = useMemo(() => {
      const [start, end] = edges;
      return word.index === start || (word.index >= start && word.index <= end);
    }, [edges, isQuotesActive]);
    const doubleClickHandler: VoidFunction = () => onDoubleClick(word.text);
    const toggleHover = useCallback(() => setHover(!isHover), [isHover]);
    const style = {
      backgroundColor:
        isHover && isQuotesActive
          ? cursorColor
          : checkIsWordInQuotes
          ? hex2rgba(cursorColor, 0.4)
          : '',
    };

    const formattedWord = useMemo(() => {
      if (profile?.wordFormat === WORD_FORMAT.DEFAULT) return word.text;
      const firstHalf = word.text.slice(0, word.text.length / 2);
      const secondHalf = word.text.slice(word.text.length / 2);
      return (
        <>
          <span style={{ fontWeight: 'bold' }}>{firstHalf}</span>
          {secondHalf}
        </>
      );
    }, [profile?.wordFormat]);

    return (
      <>
        {!word.isFirst && <span>&nbsp;</span>}
        <span
          className={clsx(classes.word, read && 'read')}
          data-word-index={word.index}
          onClick={clickHandler}
          onDoubleClick={doubleClickHandler}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          style={style}
        >
          {formattedWord}
        </span>
      </>
    );
  },

  (prevProps, nextProps) => {
    return (
      prevProps.word.index === nextProps.word.index &&
      prevProps.word.text === nextProps.word.text &&
      prevProps.read === nextProps.read &&
      prevProps.onDoubleClick === nextProps.onDoubleClick
    );
  }
);

TextRendererWord.displayName = 'TextRendererWord';
