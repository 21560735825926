import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { PRIVACY_POLICY_HTML } from './privacyPolicyHTML';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

interface PrivacyPolicyProps {}

export const PrivacyPolicy: FC<PrivacyPolicyProps> = () => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY_HTML }}
    />
  );
};
