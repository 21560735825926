import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';
import Typography from '@material-ui/core/Typography';
import { ParsedBookBlock, ParsedBookBlockType } from '@read4speed/models';
import React, { FC } from 'react';

interface BookContentBlockPreviewProps {
  block: ParsedBookBlock;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
  })
);

const getVariant = (type: ParsedBookBlockType): Variant => {
  switch (type) {
    case 'HEADING':
      return 'h5';
    default:
      return 'body1';
  }
};

export const BookContentBlockPreview: FC<BookContentBlockPreviewProps> = ({
  block,
}) => {
  const classes = useStyles();
  return (
    <Typography className={classes.root} variant={getVariant(block.type)}>
      {block.text}
    </Typography>
  );
};
