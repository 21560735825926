import { ReadingHistoryService } from '../../services/ReadingHistoryService';
import { AppThunk } from '../store';
import { readingHistoryActions } from './readingHistorySlice';

export const loadReadingHistoryAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();
  const uid = state.auth.user?.uid;

  if (!uid) return;

  const history = await ReadingHistoryService.loadHistory(uid);

  dispatch(readingHistoryActions.acceptHistoryItems(history));
};
