import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    word: {
      display: 'inline-block',
      cursor: 'pointer',
      position: 'relative',
      borderRadius: '8px',
      transition: 'background-color .1s',
      '&.read': {
        color: theme.palette.type == 'dark' ? '#BDBDBD' : '#4D4D4D',
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-4px',
        right: '-4px',
        top: 0,
        bottom: 0,
        borderRadius: '8px',
        zIndex: -1,
        background: 'transparent',
      },
      '&:hover': {
        '&:before': {
          background: 'inherit',
        },
      },
    },
  })
);
