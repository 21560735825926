import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  LineHeightEnum,
  ParagraphModel,
  TextAlignEnum,
  WordModel,
} from '@read4speed/models';
import React, { FC, memo, useMemo } from 'react';

import { useAuthorizedUserProfile } from '../../redux/userProfiles/selectors';
import { Comments } from '../ParagraphComments';
import { OnWordClick, OnWordDoubleClick } from './OnWordClickInterface';
import { TextRendererWord } from './TextRendererWord';

const useStyles = makeStyles(theme =>
  createStyles({
    paragraph: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: 'inherit',
      fontFamily: 'inherit',
      lineHeight: '2.5em',
      [theme.breakpoints.up('sm')]: {
        lineHeight: '1.3em',
      },
    },
    lastSpace: {
      flex: 'auto',
    },
  })
);

function getTextAlign(str: TextAlignEnum): string {
  switch (str) {
    case 'JUSTIFY':
      return 'space-between';
    case 'LEFT':
      return 'flex-start';
    case 'CENTER':
      return 'center';
  }
}

function getLineHeight(str: LineHeightEnum): string {
  switch (str) {
    case 'SMALL':
      return '110%';
    case 'MEDIUM':
      return '180%';
    case 'LARGE':
      return '220%';
  }
}

interface TextRendererParagraphProps {
  paragraph: ParagraphModel;
  allWords: WordModel[];
  onWordClick: OnWordClick;
  onWordDoubleClick: OnWordDoubleClick;
  readerPosition: number;
  cursorColor: string;
}

export const TextRendererParagraph: FC<TextRendererParagraphProps> = memo(
  ({
    allWords,
    paragraph,
    onWordClick,
    onWordDoubleClick,
    readerPosition,
    cursorColor,
  }) => {
    const profile = useAuthorizedUserProfile();
    const textAlign = profile && profile.textAlign;
    const userLineHeight = profile && profile.lineHeight;
    const classes = useStyles();
    const words = useMemo(
      () => allWords.slice(paragraph.start, paragraph.end + 1),
      [paragraph.start, paragraph.end, allWords.length]
    );

    const justifyContent = getTextAlign(textAlign || 'JUSTIFY');
    const lineHeight = getLineHeight(userLineHeight || 'MEDIUM');

    return (
      <Typography
        component={'p'}
        variant={'body1'}
        className={classes.paragraph}
        style={{ justifyContent, lineHeight }}
      >
        {words.map(w => (
          <React.Fragment key={w.index}>
            <TextRendererWord
              cursorColor={cursorColor}
              word={w}
              read={w.index < readerPosition}
              key={w.indexInParagraph}
              onClick={onWordClick}
              onDoubleClick={onWordDoubleClick}
            />
            {w.isLast && (
              <>
                <Comments paragraph={paragraph} />
                <span className={classes.lastSpace}>&nbsp;</span>
              </>
            )}
          </React.Fragment>
        ))}
      </Typography>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.readerPosition === nextProps.readerPosition &&
      prevProps.paragraph.charsCount === nextProps.paragraph.charsCount &&
      prevProps.paragraph.start === nextProps.paragraph.start &&
      prevProps.allWords.length === nextProps.allWords.length &&
      prevProps.onWordDoubleClick === nextProps.onWordDoubleClick
    );
  }
);

TextRendererParagraph.displayName = 'TextRendererParagraph';
