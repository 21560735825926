import { UserProfileModel } from '@read4speed/models';

import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from './userProfilesSlice';

export const updateUserProfileAction = (
  id: string,
  data: Partial<UserProfileModel>
): AppThunk => async dispatch => {
  await UserProfilesService.update(id, data);
  dispatch(userProfilesActions.update({ id, data }));
};
