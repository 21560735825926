import { BookModel, BookPageModel } from '@read4speed/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  acceptCollectionStateData,
  createCollectionState,
  ICollectionState,
  removeFromCollectionState,
} from '../utils';

export interface BooksState {
  books: ICollectionState<BookModel>;
  pages: ICollectionState<BookPageModel>;
}

const initialState: BooksState = {
  books: createCollectionState(),
  pages: createCollectionState(),
};

const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    acceptBooks(state, action: PayloadAction<BookModel[]>) {
      acceptCollectionStateData(state.books, action.payload, {
        key: 'timestamp',
        order: 'desc',
      });
    },
    removeBook(state, action: PayloadAction<string>) {
      removeFromCollectionState(state.books, action.payload);
    },
  },
});

export const {
  actions: libraryActions,
  reducer: libraryReducer,
} = librarySlice;
