import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TextField from '@material-ui/core/TextField';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChromeIcon from 'src/components/Icons/chrome';
import CloseIcon from 'src/components/Icons/close';
import GlobeIcon from 'src/components/Icons/globe';
import { uiActions } from 'src/redux/ui';
import { colors } from 'src/styles/colors';
import { SubmitButton } from 'src/styles/submitButton';
import { openExtensionUrl } from 'src/utils';

import { TextInput } from '../../components/TextInput';
import { readArticleAction } from '../../redux/commonActions';
import { useTextBusy } from '../../redux/text/selectors';
import { useAppMenuOpened } from '../../redux/ui/selectors';
import { useStyles } from './styles/appMenuDrawer';

interface AppMenuDrawerProps {}

export const AppMenuDrawer: FC<AppMenuDrawerProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const textBusy = useTextBusy();
  const menuOpened = useAppMenuOpened();

  const [isArticleBtnPressed, setArticleBtnPressed] = useState(false);
  const [articleUrl, setArticleUrl] = useState<string>('');

  const onOpen = useCallback(() => {
    dispatch(uiActions.openAppMenu());
  }, []);

  const onClose = useCallback(() => {
    dispatch(uiActions.closeAppMenu());
  }, []);

  const openArticle = useCallback(() => {
    if (!articleUrl) return;
    setArticleBtnPressed(true);
    dispatch(readArticleAction(articleUrl, history));
  }, [articleUrl]);

  return (
    <SwipeableDrawer
      open={menuOpened}
      anchor={'right'}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Box className={classes.root}>
        <header className={classes.header}>
          <h2>Add or Create</h2>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon color="black" />
          </IconButton>
        </header>

        <Divider />

        <Box className={classes.main}>
          <TextInput />
          <label>
            <span>Or the article url here:</span>
            <TextField
              fullWidth
              value={articleUrl}
              onChange={e => setArticleUrl(e.target.value)}
              name="article url"
              className={classes.urlInput}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <GlobeIcon color={colors.greyBase} />
                  </InputAdornment>
                ),
              }}
            />

            <SubmitButton
              fullWidth
              disabled={
                textBusy ||
                !articleUrl ||
                !/(https?:\/\/|www\.)[^\n\s\t]+/.test(articleUrl)
              }
              onClick={openArticle}
              variant="contained"
            >
              {isArticleBtnPressed && textBusy ? 'Parsing...' : 'Load Article'}
            </SubmitButton>
          </label>
        </Box>

        <Divider />

        <Box className={classes.footer}>
          <SubmitButton variant="contained" onClick={openExtensionUrl}>
            <ChromeIcon color="white" />
            <span>Download Chrome Extention</span>
          </SubmitButton>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
