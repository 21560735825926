import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles(theme => {
  return createStyles({
    root: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 24px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 24px 24px',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 16px 24px',
      '& h1': {
        width: '100%',
        margin: 0,
        padding: 0,
        fontFamily: 'Euclid',
        fontSize: '28px',
        letterSpacing: '1px',
        color: colors.grey800,
      },
      [theme.breakpoints.up('sm')]: {
        padding: '16px',
      },
    },
    addTextBtn: {
      position: 'absolute',
      bottom: '19px',
      right: '26px',
      zIndex: 10,
      width: '64px',
      height: '64px',
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
        width: '48px',
        height: '48px',
        bottom: 0,
        right: 0,
      },
    },
    emptyHistory: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      padding: '20px',
      [theme.breakpoints.up('sm')]: {
        padding: '40px',
      },
      '& h1': {
        fontFamily: 'Euclid',
        fontSize: '40px',
        lineHeight: '40px',
        letterSpacing: '1px',
        margin: 0,
        padding: 0,
        color: colors.grey800,
        [theme.breakpoints.up('sm')]: {
          fontSize: '48px',
          lineHeight: '48px',
          whiteSpace: 'nowrap',
        },
      },
      '& p': {
        fontSize: '20px',
        lineHeight: '28px',
        margin: '24px 0',
        color: colors.greyBase,
        [theme.breakpoints.up('sm')]: {
          fontSize: '24px',
          lineHeight: '32px',
          maxWidth: '600px',
        },
      },
      '& button': {
        height: '64px',
        marginBottom: '24px',
      },
      '& button:first-of-type': {
        marginRight: '16px',
        '& svg': {
          marginRight: '10px',
        },
      },
      '& button:last-of-type': {
        padding: '0 34px',
      },
      '& button span': {
        fontSize: '20px',
        lineHeight: '24px',
      },
      '& img': {
        height: '400px',
        width: '400px',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
    },
    emptyHistoryBtns: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
      },
    },
  });
});
