import { firestoreToPlainObjectMap } from '@read4speed/firebase-utils';
import { AnalyticsEventsModel } from '@read4speed/models/dist/AnalyticsEventsModel';

import { collections, firestoreTimeStampNow } from '../../firebase';

export class AnalyticsEventsService {
  private static instance: AnalyticsEventsService;
  constructor(private readonly userId: string) {
    if (!AnalyticsEventsService.instance) {
      AnalyticsEventsService.instance = this;
    }
    return AnalyticsEventsService.instance;
  }

  async create(
    data: Omit<AnalyticsEventsModel, 'timestamp' | 'id' | 'userId'>
  ): Promise<FirebaseFirestore.DocumentReference<AnalyticsEventsModel>> {
    return await collections.analyticsEventsRef().add({
      ...data,
      userId: this.userId,
      timestamp: firestoreTimeStampNow(),
    });
  }

  async getEventsByUserId(): Promise<AnalyticsEventsModel[]> {
    const snapshot = await collections
      .analyticsEventsRef()
      .where('userId', '==', this.userId)
      .get();

    return firestoreToPlainObjectMap(snapshot.docs);
  }

  async update(id: string, data: Partial<AnalyticsEventsModel>): Promise<void> {
    await collections.analyticsEventsRef().doc(id).update(data);
  }
}
