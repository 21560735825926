import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { AppTheme } from './AppTheme';
import { StoreProvider } from './redux';

ReactDOM.render(
  <StoreProvider>
    <BrowserRouter>
      <AppTheme>
        <App />
      </AppTheme>
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById('root')
);
