import { TextQuotesModal } from '@read4speed/models/src';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { QuotesService } from '../../services/QuotesService';

export const sliceIdentifier = 'quotes';

export const createQuote = createAsyncThunk(
  `${sliceIdentifier}/createQuote`,
  async (data: Omit<TextQuotesModal, 'timestamp' | 'uid'>) => {
    const snapshot = await QuotesService.create(data);
    const doc = await snapshot.get();
    return doc.data();
  }
);

export const fetchQuotes = createAsyncThunk(
  `${sliceIdentifier}/fetchQuotes`,
  async (textRefIds: string[]) => {
    return await QuotesService.getByTextRefIds(textRefIds);
  }
);

export const removeQuote = createAsyncThunk(
  `${sliceIdentifier}/removeQuote`,
  async ({ docId }: { docId: string; textRefId: string }) => {
    await QuotesService.delete(docId);
    return docId;
  }
);
