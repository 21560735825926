import { BookPageModel } from '@read4speed/models';
import { useMemo } from 'react';

import { useBookPages } from './useBookPages';

export const useBookPagesBefore = (pageNumber: number): BookPageModel[] => {
  const pages = useBookPages();
  return useMemo(() => pages.filter(p => p.number < pageNumber), [
    pageNumber,
    pages.map(p => p.id).join(''),
  ]);
};
