import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'src/styles/colors';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      background: theme.reader.background,
      padding: '0 30px',
      height: '49px',
      flexShrink: 0,
      borderTop: `${theme.reader.borderColor} 1px solid`,
      filter:
        'drop-shadow(0 2px 4px rgba(0,0,0,.06)) drop-shadow(0 4px 6px rgba(0,0,0,.1))',
      [theme.breakpoints.up('sm')]: {
        padding: '0 64px',
      },
    },
    playIcon: {
      marginLeft: '-11px',
      padding: '11px',
      [theme.breakpoints.up('sm')]: {
        marginRight: '8px',
      },
    },
    speechIcon: {
      padding: '12px',
      fontSize: '16px',
      lineHeight: '20px',
      color: theme.reader.text,
      '& svg': {
        marginRight: '6px',
        width: '20px',
        [theme.breakpoints.up('sm')]: {
          width: '25px',
          marginRight: '8px',
        },

        '& path': {
          stroke: theme.reader.text,
        },
      },
      '& .active': {
        color: colors.orange600,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
      },
    },
    speed: {
      color: theme.reader.text,
      fontSize: '16px',
      padding: '12px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
      },
    },
    speedText: {
      fontSize: '16px',
      lineHeight: '20px',
      color: theme.reader.text,
      minWidth: '54px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
      },
    },
    speedInput: {
      all: 'unset',
      textAlign: 'center',
      width: '100%',
      fontSize: '16px',
      minWidth: '60px',
      color: theme.reader.text,
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
      },
    },
    speedBtns: {
      backgroundColor: theme.reader.background,
      border: `1px solid ${colors.grey5}`,
      borderRadius: '10px',
      boxShadow: '0 0 6px rgba(0,0,0,.05)',
      position: 'absolute',
      opacity: 0,
      height: '56px',
      right: '50%',
      top: '-70%',
      transform: 'translateX(50%)',
      zIndex: -1,
      display: 'flex',
      transition: 'top .2s, opacity .1s',
      '&.active': {
        top: '-64px',
        opacity: 1,
      },
      '& > *': {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& svg': {
        width: '18px',
        height: '18px',
        '& path': {
          stroke: theme.reader.text,
        },
      },
      '& button': {
        minWidth: '54px',
      },
    },
    time: {
      fontSize: '16px',
      lineHeight: '20px',
      color: theme.reader.text,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
      },
    },
    watch: {
      fontSize: '22px',
      width: '100%',
      color: colors.grey6,
    },
    settingsBtn: {
      padding: '12px',
      '& svg': {
        width: '21px',

        '& path': {
          stroke: theme.reader.text,
        },
      },
      [theme.breakpoints.up('sm')]: {
        '& svg': {
          width: '25px',
        },
      },
    },
    readerTestBtn: {
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      fontSize: '14px',
      lineHeight: '32px',
      color: theme.reader.text,
      '& button': {
        marginLeft: '16px',
      },
    },
  })
);
