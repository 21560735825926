import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useCallback } from 'react';

import { FileLoaderDropZone } from './FileLoaderDropZone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 'auto',
      position: 'relative',
    },
  })
);

interface ImageLoaderProps {
  handleFiles: (files: File[]) => void;
}

export const FileLoader: FC<ImageLoaderProps> = ({ handleFiles }) => {
  const classes = useStyles();
  const onAdded = useCallback((files: File[]) => {
    handleFiles(files);
  }, []);

  return (
    <Box className={classes.root}>
      <FileLoaderDropZone onAdded={onAdded} visible={true} />
    </Box>
  );
};
