import { UserProfileModel } from '@read4speed/models';

import { UserProfilesService } from '../../services/UserProfilesService';
import { AppThunk } from '../store';
import { userProfilesActions } from './userProfilesSlice';

export const ensureAuthorizedUserProfileAction = (
  uid: string,
  userData: Partial<UserProfileModel>
): AppThunk => async dispatch => {
  const userProfile = await UserProfilesService.ensure(uid, userData);

  if (!userProfile) {
    throw new Error(`unexpected empty user profile for user ${uid}`);
  }

  dispatch(userProfilesActions.acceptData([userProfile]));
  dispatch(userProfilesActions.setLoggedInProfile(userProfile.id as string));
};
