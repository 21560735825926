import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { colors } from 'src/styles/colors';

export const CustomInput = withStyles({
  root: {
    width: '100%',
    padding: '0 22px',
    height: '50px',
    border: `1px solid ${colors.grey300}`,
    borderRadius: '12px',
    background: 'white',
    '& input:-webkit-autofill, input:-webkit-autofill:focus': {
      transition: 'background-color 600000s 0s, color 600000s 0s',
    },
    '& > div': {
      display: 'flex',
      height: '100%',
    },
    '& input': {
      fontSize: '17px',
      color: colors.grey69,
      background: 'transparent',
      '&::placeholder': {
        color: colors.greyBase,
        opacity: 1,
      },
    },
    '& + &': {
      marginTop: '20px',
    },
    '& svg': {
      marginRight: '8px',
    },
    '&.invalid': {
      borderColor: colors.red3,
      background: 'rgba(234,67,53,.05)',
      '& input': {
        color: colors.red2,
        '&::placeholder': {
          color: colors.red2,
          opacity: 1,
        },
      },
    },
  },
})(TextField);
