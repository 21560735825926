import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { colors } from 'src/styles/colors';

export const CustomTextField = withStyles({
  root: {
    width: '100%',
    padding: '0 18px',
    height: '48px',
    border: `1px solid ${colors.grey300}`,
    borderRadius: '12px',
    background: 'white',
    fontSize: '15px',
    color: colors.grey69,
    '& > div': {
      display: 'flex',
      height: '100%',
    },
    '& input': {
      fontSize: '15px',
      color: colors.grey69,
    },
    '&:not(:last-of-type)': {
      marginBottom: '16px',
    },
    '&::placeholder': {
      color: colors.greyBase,
    },
  },
})(TextField);
