import {
  AnalyticsEventsModel,
  EventTypes,
} from '@read4speed/models/dist/AnalyticsEventsModel';
import { useCallback, useEffect, useState } from 'react';

import { useAuthorizedUserProfileId } from '../redux/userProfiles/selectors';
import { AnalyticsEventsService } from '../services/AnalyticsEventsService';

const useAnalyticsEvents = (): [
  (eventName: EventTypes, value: Record<string, unknown>) => void,
  () => Promise<AnalyticsEventsModel[]>
] => {
  const profileId = useAuthorizedUserProfileId();
  const [
    analyticsEventsInstance,
    setAnalyticsEventsInstance,
  ] = useState<AnalyticsEventsService | null>(null);

  useEffect(() => {
    if (profileId) {
      setAnalyticsEventsInstance(new AnalyticsEventsService(profileId));
    }
  }, [profileId]);

  const pushEvent = useCallback(
    (eventName: EventTypes, value: Record<string, unknown>): void => {
      if (analyticsEventsInstance) {
        analyticsEventsInstance.create({
          eventName,
          value,
        });
      }
    },
    [analyticsEventsInstance]
  );

  const getUserEvents = useCallback((): Promise<AnalyticsEventsModel[]> => {
    if (analyticsEventsInstance) {
      return analyticsEventsInstance.getEventsByUserId();
    }
    return Promise.resolve([]);
  }, [analyticsEventsInstance]);

  return [pushEvent, getUserEvents];
};

export default useAnalyticsEvents;
