import { Box, Button } from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStyles } from 'src/Pages/Reader/styles/textReaderControls';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { diagnosticActions } from '../../redux/diagnostic';

interface Props {
  watch: string;
  stopWatch: VoidFunction;
  stopped: boolean;
  bottomDirty: boolean;
  resetScrollState: VoidFunction;
}

const ReadingTestControls: FC<Props> = props => {
  const { watch } = props;
  const history = useHistory();
  // TEMP DEMO CODE TO DELETE ---- START
  const dispatch = useDispatch();

  const stopClickHandler = useCallback(() => {
    dispatch(diagnosticActions.stop());
    dispatch(diagnosticActions.calculateWPM());
  }, []);
  // TEMP DEMO STYLES TO DELETE ---- END

  // const classes = useStylesActions();
  const classes = useStyles();

  const handleEndTest: VoidFunction = () => {
    dispatch(diagnosticActions.stop({ readingTime: watch }));
    dispatch(diagnosticActions.calculateWPM());
    dispatch(diagnosticActions.setModalOpenState());
    props.stopWatch();
    props.resetScrollState();
    stopClickHandler();
    history.push(APP_ROUTES_CONFIG.HOME);
  };

  return (
    <Box className={classes.root}>
      <span className={classes.watch}>{watch}</span>
      <Box className={classes.readerTestBtn}>
        <span>Finished reading?</span>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<CheckCircleOutlinedIcon />}
          disabled={props.stopped || !props.bottomDirty}
          onClick={handleEndTest}
        >
          End Test
        </Button>
      </Box>
    </Box>
  );
};

export default ReadingTestControls;
