import { ReadingHistoryItemModel } from '@read4speed/models';
import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { getCollectionItems } from '../../utils';

export const useReadingHistoryItems = (): ReadingHistoryItemModel[] => {
  return useSelector<RootState, ReadingHistoryItemModel[]>(state => {
    return getCollectionItems(state.readingHistory.collection);
  });
};
