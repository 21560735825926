/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

interface PauseIconProps {
  color: string;
}

const PauseIcon: FC<PauseIconProps> = ({ color }): ReactElement => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2999 12.9992C26.2999 16.394 24.9514 19.6497 22.5509 22.0502C20.1505 24.4507 16.8947 25.7992 13.5 25.7992C10.1052 25.7992 6.84945 24.4507 4.44898 22.0502C2.04852 19.6497 0.699951 16.394 0.699951 12.9992C0.699951 9.60445 2.04852 6.34872 4.44898 3.94825C6.84945 1.54779 10.1052 0.199219 13.5 0.199219C16.8947 0.199219 20.1505 1.54779 22.5509 3.94825C24.9514 6.34872 26.2999 9.60445 26.2999 12.9992V12.9992ZM8.69995 9.79922C8.69995 9.37487 8.86852 8.96791 9.16858 8.66785C9.46864 8.36779 9.8756 8.19922 10.3 8.19922C10.7243 8.19922 11.1313 8.36779 11.4313 8.66785C11.7314 8.96791 11.9 9.37487 11.9 9.79922V16.1992C11.9 16.6236 11.7314 17.0305 11.4313 17.3306C11.1313 17.6306 10.7243 17.7992 10.3 17.7992C9.8756 17.7992 9.46864 17.6306 9.16858 17.3306C8.86852 17.0305 8.69995 16.6236 8.69995 16.1992V9.79922ZM16.7 8.19922C16.2756 8.19922 15.8686 8.36779 15.5686 8.66785C15.2685 8.96791 15.1 9.37487 15.1 9.79922V16.1992C15.1 16.6236 15.2685 17.0305 15.5686 17.3306C15.8686 17.6306 16.2756 17.7992 16.7 17.7992C17.1243 17.7992 17.5313 17.6306 17.8313 17.3306C18.1314 17.0305 18.3 16.6236 18.3 16.1992V9.79922C18.3 9.37487 18.1314 8.96791 17.8313 8.66785C17.5313 8.36779 17.1243 8.19922 16.7 8.19922Z"
      fill={color}
    />
  </svg>
);

export default PauseIcon;
