import moment from 'moment';
import { useState } from 'react';

const renderTime = (seconds: number = 0): string =>
  moment().minute(0).second(seconds).format('mm : ss');

const useStopWatch = (): [VoidFunction, VoidFunction, string] => {
  const [watch, setWatch] = useState(renderTime());
  const [runClock, setRunClock] = useState<number>(0);
  const [, setCounter] = useState<number>(1);

  const displayTime: VoidFunction = () => {
    setCounter(prevState => {
      setWatch(renderTime(prevState));
      return prevState + 1;
    });
  };

  const startWatch: VoidFunction = () => {
    const interval = (setInterval(
      displayTime,
      1000
    ) as unknown) as () => number;
    setRunClock(interval);
  };

  const stopWatch: VoidFunction = () => {
    clearInterval(runClock);
    setWatch(renderTime());
    setCounter(0);
  };

  return [startWatch, stopWatch, watch];
};

export default useStopWatch;
