import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import {
  FontNameEnum,
  HighlightNameEnum,
  LineHeightEnum,
  ScrollTypeEnum,
  TextAlignEnum,
  ThemeNameEnum,
  WordFormatEnum,
} from '@read4speed/models';
import { FixationPointsEnum } from '@read4speed/models/src/FixationPointsEnum';
import { UnderlineHighlightNameEnum } from '@read4speed/models/src/UnderlineHighlightNameEnum';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from 'src/components/Icons/close';
import { updateFixationPointsAction } from 'src/redux/commonActions/updateFixationPointsAction';
import { uiActions } from 'src/redux/ui';

import {
  updateCursorColorAction,
  updateFontAction,
  updateFontSizeAction,
  updateHighlightAction,
  updateLineHeightAction,
  updateTextAlignAction,
  updateThemeAction,
} from '../../redux/commonActions';
import { updateScrollTypeAction } from '../../redux/commonActions/updateScrollTypeAction';
import { updateUnderlineHighlightAction } from '../../redux/commonActions/updateUnderlineHighlightAction';
import { updateWordFormatAction } from '../../redux/commonActions/updateWordFormatAction';
import { useReaderSettingsOpened } from '../../redux/ui/selectors';
import { useAuthorizedUserProfile } from '../../redux/userProfiles/selectors';
import { CustomDrawer } from '../../styles/customDrawer';
import { ReaderSettingsColorPicker } from './ReaderSettingsColorPicker';
import { ReaderSettingsFixationPoints } from './ReaderSettingsFixationPoints';
import { ReaderSettingsFontSelector } from './ReaderSettingsFontSelector';
import { ReaderSettingsFontSizeSelector } from './ReaderSettingsFontSizeSelector';
import { ReaderSettingsHighlight } from './ReaderSettingsHighlight';
import { ReaderSettingsLineHeight } from './ReaderSettingsLineHeight';
import { ReaderSettingsScrollType } from './ReaderSettingsScrollType';
import { ReaderSettingsTextAlign } from './ReaderSettingsTextAlign';
import { ReaderSettingsTheme } from './ReaderSettingsTheme';
import { ReaderSettingsUnderlineHighlight } from './ReaderSettingsUnderline';
import { ReaderSettingsWordFormat } from './ReaderSettingsWordFormat';
import { useStyles } from './styles/readerSettings';

interface ReaderSettingsProps {}

export const ReaderSettings: FC<ReaderSettingsProps> = () => {
  const dispatch = useDispatch();
  const userProfile = useAuthorizedUserProfile();
  const isVisible = useReaderSettingsOpened();
  const classes = useStyles();

  const close = useCallback(() => {
    dispatch(uiActions.closeReaderSettings());
  }, []);

  const onFontSelect = useCallback(
    (font: FontNameEnum) => {
      if (!userProfile?.id) return;
      dispatch(updateFontAction(userProfile.id, font));
    },
    [userProfile?.id]
  );

  const onSizeChange = useCallback(
    (size: number) => {
      if (!userProfile?.id) return;
      dispatch(updateFontSizeAction(userProfile.id, size));
    },
    [userProfile?.id]
  );

  const onThemeChange = useCallback(
    (theme: ThemeNameEnum) => {
      if (!userProfile?.id) return;
      dispatch(updateThemeAction(userProfile.id, theme));
    },
    [userProfile?.id]
  );

  const onTextAlignChange = useCallback(
    (textAlign: TextAlignEnum) => {
      if (!userProfile?.id) return;
      dispatch(updateTextAlignAction(userProfile.id, textAlign));
    },
    [userProfile?.id]
  );

  const onLineHeightChange = useCallback(
    (lineHeight: LineHeightEnum) => {
      if (!userProfile?.id) return;
      dispatch(updateLineHeightAction(userProfile.id, lineHeight));
    },
    [userProfile?.id]
  );

  const onScrollTypeChange = useCallback(
    (scrollType: ScrollTypeEnum) => {
      if (!userProfile?.id) return;
      dispatch(updateScrollTypeAction(userProfile.id, scrollType));
    },
    [userProfile?.id]
  );

  const onWordFormatChange = useCallback(
    (wordFormat: WordFormatEnum) => {
      if (!userProfile?.id) return;
      dispatch(updateWordFormatAction(userProfile.id, wordFormat));
    },
    [userProfile?.id]
  );

  const onHighlightChange = useCallback(
    (highlight: HighlightNameEnum[]) => {
      if (!userProfile?.id) return;
      dispatch(updateHighlightAction(userProfile.id, highlight));
    },
    [userProfile?.id]
  );

  const onUnderlineHighlightChange = useCallback(
    (underlineHighlight: UnderlineHighlightNameEnum[]) => {
      if (!userProfile?.id) return;
      dispatch(
        updateUnderlineHighlightAction(userProfile.id, underlineHighlight)
      );
    },
    [userProfile?.id]
  );

  const onFixationPointsChange = useCallback(
    (fixationPoints: FixationPointsEnum[]) => {
      if (!userProfile?.id) return;
      dispatch(updateFixationPointsAction(userProfile.id, fixationPoints));
    },
    [userProfile?.id]
  );

  const onCursorColorChange = useCallback(
    (color: string) => {
      if (!userProfile?.id) return;
      dispatch(updateCursorColorAction(userProfile.id, color));
    },
    [userProfile?.id]
  );

  if (!userProfile) return null;

  return (
    <CustomDrawer
      container={document.body}
      anchor="bottom"
      open={isVisible}
      onClose={close}
      onOpen={() => ({})}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box className={classes.header}>
        <span>Settings</span>
        <IconButton aria-label="exit" onClick={close}>
          <CloseIcon color="black" />
        </IconButton>
      </Box>
      <Box className={classes.body}>
        <ReaderSettingsColorPicker
          color={userProfile.cursorColor}
          onChange={onCursorColorChange}
        />
        <ReaderSettingsTheme
          value={userProfile.theme}
          onChange={onThemeChange}
        />
        <ReaderSettingsFontSelector
          font={userProfile.fontName}
          onSelect={onFontSelect}
        />
        <ReaderSettingsFontSizeSelector
          size={userProfile.fontSize}
          onChange={onSizeChange}
        />
        <ReaderSettingsHighlight
          value={userProfile.highlight}
          onChange={onHighlightChange}
        />
        <ReaderSettingsUnderlineHighlight
          value={userProfile.underlineHighlight}
          onChange={onUnderlineHighlightChange}
        />
        <ReaderSettingsFixationPoints
          value={userProfile.fixationPoints}
          onChange={onFixationPointsChange}
        />
        <ReaderSettingsTextAlign
          value={userProfile.textAlign}
          onChange={onTextAlignChange}
        />
        <ReaderSettingsLineHeight
          value={userProfile.lineHeight}
          onChange={onLineHeightChange}
        />
        <ReaderSettingsScrollType
          value={userProfile.scrollType}
          onChange={onScrollTypeChange}
        />
        <ReaderSettingsWordFormat
          value={userProfile.wordFormat}
          onChange={onWordFormatChange}
        />
      </Box>
    </CustomDrawer>
  );
};
